@import '~@/templates/fonts-apple-sd-gothic-neo.scss';

@for $i from 1 through 9 {
  $font: SCDream#{$i};

  @font-face {
    font-style: normal;
    font-weight: unset;
    font-family: $font;
    font-display: auto;
    src: local($font), url('./fonts/#{$font}.otf') format('opentype');
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
