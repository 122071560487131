$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .section-2 {
    padding-top: vw(70px);
    padding-bottom: vw(182px);
    font-family: Noto Sans KR;
    text-align: center;
    background: #ffc521;

    @include media-breakpoint-down(md) {
      padding-top: vwMobile(30px);
      padding-right: vwMobile(10px);
      padding-bottom: vwMobile(63px);
      padding-left: vwMobile(10px);
    }
  }

  .frame {
    width: vw(118px);
    margin-bottom: vw(27px);

    @include media-breakpoint-down(md) {
      width: vwMobile(46px);
      margin-bottom: vwMobile(14px);
    }
  }

  .section-title {
    margin-bottom: vw(88px);
    font-size: vw(58px);
    font-family: SCDream8;
    line-height: 1.16;
    color: #3b393a;

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(30px);
      font-size: vwMobile(24px);
    }
  }

  .paper {
    position: relative;
    padding: vw(85px) vw(86px) vw(77px);
    border-radius: vw(36px);
    background: #f8f8f8;
    box-shadow: 0px vw(4px) vw(10px) rgba(0, 0, 0, 0.25);

    @include media-breakpoint-down(md) {
      // padding: vwMobile(33px) vwMobile(12px) vwMobile(51px);
      padding: vwMobile(33px) 0 vwMobile(51px);
      border-radius: vwMobile(12px);
    }
  }

  .img-animal {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: vw(377px);
    transform: translate(50%, vw(70px));

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .paper-title {
    margin-bottom: vw(16px);
    font-size: vw(60px);
    font-family: SCDream8;
    line-height: 1.15;
    color: #006db7;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      font-size: vwMobile(26px);
    }
  }

  .paper-subtitle {
    margin: 0;
    font-size: vw(36px);
    line-height: 1.11;
    color: #000000;

    @include media-breakpoint-down(md) {
      font-weight: 400;
      font-size: vwMobile(18px);
      line-height: 2.22;
    }
  }

  .page-desc {
    margin-top: vw(105px);
    margin-bottom: vw(102px);
    font-size: vw(24px);
    line-height: 1.67;
    color: #3b393a;

    @include media-breakpoint-down(md) {
      padding-right: vwMobile(12px);
      padding-left: vwMobile(12px);
      margin-top: vwMobile(33px);
      margin-bottom: vwMobile(40px);
      font-size: vwMobile(13px);
      line-height: normal;
    }

    p {
      margin: 0;
    }

    .desc-title {
      margin-bottom: vw(29px);
      font-weight: 700;
      font-size: vw(36px);
      line-height: 1.36;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        margin-bottom: vwMobile(17px);
        font-size: vwMobile(18px);
      }
    }

    .tip {
      margin-top: vw(45px);
      font-weight: 700;
      line-height: 1.375;
      color: #006db7;

      @include media-breakpoint-down(md) {
        margin-top: vwMobile(33px);
      }
    }
  }

  .paper-bottom {
    padding-top: vw(81px);
    margin-top: vw(93px);
    border-top: 1px solid #3b393a;

    @include media-breakpoint-down(md) {
      padding-top: vwMobile(33px);
      margin-top: vwMobile(50px);
      margin-right: vwMobile(12px);
      margin-left: vwMobile(12px);
    }

    .img {
      width: vw(360px);
      height: vw(360px);
      margin-top: vw(76px);
      margin-bottom: vw(49px);
      border-radius: 50%;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        width: vwMobile(180px);
        height: vwMobile(180px);
        margin-top: vwMobile(26px);
        margin-bottom: vwMobile(20px);
      }
    }

    .link-button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: vw(735px);
      height: vw(104px);
      margin: 0 auto vw(31px);
      border-radius: vw(52px);
      font-size: vw(36px);
      line-height: 1.46;
      text-decoration: none;
      color: #fff;
      background: #006db7;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        width: vwMobile(268px);
        height: vwMobile(42px);
        margin: 0 auto vwMobile(20px);
        border-radius: vwMobile(21px);
        font-size: vwMobile(14px);
      }

      img {
        position: absolute;
        z-index: 1;
        top: vw(20px);
        right: vw(10px);
        width: vw(111px);

        @include media-breakpoint-down(md) {
          top: vwMobile(10px);
          right: vwMobile(0px);
          width: vwMobile(44px);
        }
      }
    }

    .tip {
      margin: 0;
      font-size: vw(24px);
      line-height: 1.33;
      color: #939598;

      @include media-breakpoint-down(md) {
        font-size: vwMobile(12px);
      }
    }
  }
