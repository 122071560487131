$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import './vars.scss';
  @import './fonts.scss';

  .description {
    font-size: vwMobile(14px);
    line-height: 1.4;
    white-space: pre-wrap;
    color: #717171;

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
    }

    :deep(a) {
      text-decoration: none;
      color: $primary;
    }

    :deep(strong), :deep(b) {
      font-weight: 700;
    }
  }

  .page {
    font-size: vwMobile(13px);
    line-height: 1.4;

    &.lang-ko {
      // 韩国用这个字体，其他国家用global设置的
      font-family: Noto Sans KR;
    }

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
    }
  }

  .container, :deep(.container) {
    padding-right: 0;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      max-width: vw(1320px);
    }
  }

  .page-title, :deep(.page-title) {
    margin-bottom: vwMobile(14px);
    font-weight: 700;
    font-size: vwMobile(20px);
    line-height: 1.35;
    text-align: center;
    color: $primary;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(23px);
      font-size: vw(48px);
    }
  }

  .program-purpose, .program-intro {
    padding-right: vwMobile(20px);
    padding-left: vwMobile(20px);

    @include media-breakpoint-up(md) {
      padding-right: 0;
      padding-left: 0;
    }

    .description {
      text-align: center;
    }
  }

  .box-social-share {
    display: flex;
    justify-content: flex-end;
    padding-right: vwMobile(15px);
    margin-top: vwMobile(15px);

    @include media-breakpoint-up(md) {
      padding-right: vw(40px);
      margin-top: vw(30px);
    }
  }

  .main {
    padding-top: vwMobile(38px);

    @include media-breakpoint-up(md) {
      padding-top: vw(120px);
    }
  }

  .program-purpose {
    margin-bottom: vwMobile(62px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(120px);
    }
  }

  .program-intro {
    margin-bottom: vwMobile(85px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(130px);
    }
  }

  .box-tab {
    display: flex;
    margin-bottom: vwMobile(43px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(96px);
    }

    .tab-item {
      position: relative;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: vwMobile(8px) 0;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        padding: vw(19px) 0;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 2px;
        background: rgba($primary, 0.5);
        transform: translateY(100%);
        content: '';

        @include media-breakpoint-up(md) {
          height: vw(8px);
          border-radius: vw(4px);
        }
      }

      img {
        width: vwMobile(15px);
        margin-right: vwMobile(5px);

        @include media-breakpoint-up(md) {
          width: vw(35px);
          margin-right: vw(17px);
        }
      }

      .name {
        font-size: vwMobile(16px);
        color: rgba($primary, 0.5);

        @include media-breakpoint-up(md) {
          font-size: vw(36px);
        }
      }

      &.active {
        .name {
          color: $primary;
        }

        &:after {
          background: $primary;
        }
      }
    }
  }

  .box-collection-items {
    margin-bottom: vwMobile(30px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(128px);
    }

    .description {
      color: #000;
    }
  }

  .collection-item-text-field {
    padding: 0 vwMobile(15px);
    margin-top: vwMobile(30px);
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-top: vw(67px);
    }
  }

  .recycling-guidelines {
    padding: 0 vwMobile(15px);
    margin-bottom: vwMobile(82px);

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-bottom: vw(207px);
    }

    .box-image {
      margin-top: vwMobile(30px);
      margin-bottom: vwMobile(30px);

      @include media-breakpoint-up(md) {
        margin-top: vw(67px);
        margin-bottom: vw(108px);
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .description {
      font-size: vwMobile(12px);
      line-height: 2;
      text-align: center;
      white-space: pre-wrap;
      color: #000;

      @include media-breakpoint-up(md) {
        font-size: vw(24px);
      }
    }
  }

  .brand-info {
    display: flex;
    flex-direction: column;
    padding-right: vwMobile(17px);
    padding-bottom: vwMobile(42px);
    padding-left: vwMobile(17px);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding-right: 0;
      padding-bottom: vw(80px);
      padding-left: 0;
    }

    .cover {
      width: 100%;
      margin-bottom: vwMobile(21px);
      border-radius: vwMobile(12px);

      @include media-breakpoint-up(md) {
        align-self: flex-start;
        width: vw(716px);
        margin-right: vw(32px);
        margin-bottom: 0;
        border-radius: vw(12px);
      }
    }

    .box-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #000000;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }

      .page-title {
        margin-bottom: 0 0 vwMobile(7px) 0;
        line-height: 1.375;
        color: #000000;

        @include media-breakpoint-up(md) {
          margin-bottom: 0 0 vw(19px) 0;
        }
      }

      .description {
        margin: 0;
        color: #000;
      }

      .button {
        display: flex;
        align-items: center;
        align-self: flex-end;
        height: vwMobile(45px);
        padding: 0 vwMobile(16px);
        margin-top: vwMobile(40px);
        border-radius: vwMobile(23px);
        font-size: vwMobile(14px);
        line-height: normal;
        text-decoration: none;
        color: #fff;
        background: $primary;

        @include media-breakpoint-up(md) {
          height: vw(64px);
          padding: 0 vw(36px);
          margin-top: vw(86px);
          border-radius: vw(33px);
          font-size: vw(24px);
        }

        img {
          width: vwMobile(18px);
          margin-left: vwMobile(9px);

          @include media-breakpoint-up(md) {
            width: vw(31px);
            margin-left: vw(19px);
          }
        }
      }
    }

    .social-share {
      display: flex;
      align-self: flex-end;
      margin-top: vw(40px);

      @include media-breakpoint-down(md) {
        margin-top: vwMobile(30px);
      }

      a {
        &:not(:last-child) {
          margin-right: vw(15px);

          @include media-breakpoint-down(md) {
            margin-right: vwMobile(15px);
          }
        }
      }

      img {
        width: vw(40px);

        @include media-breakpoint-down(md) {
          width: vwMobile(30px);
        }
      }
    }
  }

  .map-area {
    margin-bottom: vw(110px);

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(75px);
    }

    .page-title {
      margin-bottom: vwMobile(32px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(41px);
      }
    }
  }

  .custom-area {
    margin-bottom: vwMobile(50px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(160px);
    }

    :deep(.rich-text) {
      padding: 0 vwMobile(20px);

      @include media-breakpoint-up(md) {
        padding: 0 vw(170px);
      }
    }

    :deep(.rich-text a) {
      text-decoration: none;
      color: $primary;
    }

    :deep(.rich-text b),
    :deep(.rich-text strong) {
      font-weight: 700;
    }

    :deep(.rich-text p),
    :deep(.rich-text h1),
    :deep(.rich-text h2),
    :deep(.rich-text h3),
    :deep(.rich-text h4),
    :deep(.rich-text h5),
    :deep(.rich-text h6) {
      margin: 0;
    }

    :deep(.rich-text h1),
    :deep(.rich-text h2),
    :deep(.rich-text h3),
    :deep(.rich-text h4),
    :deep(.rich-text h5),
    :deep(.rich-text h6),
    :deep(.rich-text b),
    :deep(.rich-text strong) {
      font-weight: 700;
    }

    :deep(.rich-text h1) {
      font-size: vwMobile(22px);

      @include media-breakpoint-up(md) {
        font-size: vw(36px);
      }
    }

    :deep(.rich-text h2) {
      font-size: vwMobile(20px);

      @include media-breakpoint-up(md) {
        font-size: vw(34px);
      }
    }

    :deep(.rich-text h3) {
      font-size: vwMobile(18px);

      @include media-breakpoint-up(md) {
        font-size: vw(32px);
      }
    }

    :deep(.rich-text h4) {
      font-size: vwMobile(16px);

      @include media-breakpoint-up(md) {
        font-size: vw(30px);
      }
    }

    :deep(.rich-text h5) {
      font-size: vwMobile(14px);

      @include media-breakpoint-up(md) {
        font-size: vw(28px);
      }
    }

    :deep(.rich-text h6) {
      font-size: vwMobile(12px);

      @include media-breakpoint-up(md) {
        font-size: vw(26px);
      }
    }
  }
