$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}
@import './css-variable.scss';

html {
  width: 100%;
}



body {
  font-size: 16px;

  &.lang-ko {
    font-family: Noto Sans KR, Verdana, sans-serif;
  }

  &.lang-ja {
    font-family: "Poppins", Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  &.modal-open {
    // 客户弹窗要求能滚动
    overflow: auto !important;
  }
}

body[class*="popup-body-lock-"] {
  overflow: hidden;
}

input,
select,
textarea {
  outline: none;
  font-family: inherit;

  &::placeholder {
    color: #ccc;
  }
}

img {
  vertical-align: top;
}

.img-render-optimize {
  /* stylelint-disable */
  image-rendering: -webkit-optimize-contrast;
  /* stylelint-enable */
}

.font-color-primary {
  color: $primary;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-overflow {
  @include text-overflow;
}

.text-overflow-2 {
  @include text-overflow(2);
}

.relative {
  position: relative;
}

.visibility-hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}


// icons
.icon-heart {
  width: 30px;
}

.icon-arrow-right {
  width: 30px;
}

.icon-search {
  width: 30px;
}

.icon-delete {
  width: 26px;
}

.icon-add {
  width: 30px;
}

.icon-reduce {
  width: 30px;
}

.icon-arrow {
  width: 60px;
}

.icon-arrow-top {
  width: 15px;
  transition: transform .3s;

  &.white {
    filter: brightness(100);
  }
}

.icon-arrow-down {
  width: 15px;
  transform: rotate(180deg);

  &.white {
    filter: brightness(100);
  }
}

.hidden-xs-only {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.dialog-global-alert {
  z-index: z('alert');
}

// 覆盖 boostrap 样式
.btn {
  &-outline-primary {
    &:hover {
      border-color: $primary;
      color: $primary;
      background-color: #f7f7f7;
    }
  }
}

// 移动端整体输入框、选择器高度整体拔高
.form-control,
.form-select {
  @include media-breakpoint-down(md) {
    padding: 14px 20px;
  }

  &::placeholder {
    color: #ccc;
  }
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.text-decoration-none {
  text-decoration: none;
}

.reset-ul {
  list-style: none;
  padding: 0;
}
