$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

   @import '../../vars.scss';

  .popup-notice-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    color: #000000;
    background-color: #fff;
    transform: translate3d(0, 0, 0);
    transition: background .5s;

    @include media-breakpoint-up(md) {
      width: vw(600px);
    }

    .popup-header {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: vw(17px) vw(5px) vw(28px) vw(29px);
      }

      .button-close {
        width: vw(45px);
        padding: vw(10px);
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }

    .popup-footer-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: content-box;
      height: vwMobile(51px);
      font-weight: 500;
      font-size: vwMobile(14px);
      color: rgba(0, 0, 0, 0.6);
      background: #ffffff;

      @include padding-bottom-safe-area(0px);

      .button {
        padding: vwMobile(15px) vwMobile(20px);

        &:last-child {
          color: #000;
        }
      }
    }

    .popup-footer-pc {
      position: relative;
      display: flex;
      justify-content: flex-end;
      height: vw(75px);

      .button {
        display: flex;
        align-items: center;
        padding: 0 vw(27px);
        font-weight: 400;
        font-size: vw(20px);
        color: #000;
        cursor: pointer;
      }
    }
  }
