$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .section-3 {
    padding-top: vwMobile(50px);
    background: #f7f7f7;

    @include media-breakpoint-up(md) {
      padding-top: vw(145px);
    }
  }

  .container {
    position: relative;
  }

  .img-ripple, .img-ripple-2 {
    position: absolute;
    width: vw(309px);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .img-ripple {
    top: vw(60px);
    left: vw(-200px);
  }

  .img-ripple-2 {
    bottom: vw(130px);
    right: 0;
    transform: translateX(50%);
  }

  .section-title {
    font-size: vwMobile(24px);
    font-family: SCDream7;
    line-height: 1.47;
    text-align: center;
    color: #3b393a;

    @include media-breakpoint-up(md) {
      font-size: vw(58px);
      font-family: SCDream8;
    }
  }

  .box-steps {
    position: relative;
    z-index: 2;
    display: flex;
    padding-top: vwMobile(57px);
    padding-bottom: vwMobile(112px);
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      padding-top: vw(105px);
      padding-bottom: vw(270px);
    }
  }

  .step-card {
    flex-shrink: 0;
    width: vwMobile(271px);
    height: vwMobile(358px);
    padding-right: vwMobile(28px);
    padding-left: vwMobile(28px);
    margin-right: vwMobile(15px);
    border-radius: vwMobile(36px);
    background: #fff;
    box-shadow: 3px 4px 22px rgba(145, 145, 145, 0.25);

    @include media-breakpoint-up(md) {
      width: vw(370px);
      height: unset;
      padding-right: vw(39px);
      padding-bottom: vw(65px);
      padding-left: vw(28px);
      margin-right: 0;
      border-radius: vw(36px);

      &:not(:last-child) {
        margin-right: vw(30px);
      }
    }

    @include media-breakpoint-down(md) {
      &:first-child {
        margin-left: vwMobile(56px);
      }

      &:last-child {
        margin-right: vwMobile(56px);
      }
    }


    .step-header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: vwMobile(26px);
      padding-bottom: vwMobile(8px);

      @include media-breakpoint-up(md) {
        padding-top: vw(36px);
        padding-bottom: vw(16px);
      }

      .step-info {
        font-family: Noto Sans KR;
        letter-spacing: -0.02em;

        .step-index {
          font-weight: bold;
          font-size: vwMobile(20px);
          font-family: SCDream7;
          color: #ffc521;

          @include media-breakpoint-up(md) {
            font-size: vw(24px);
            line-height: 1.1;
          }
        }

        .step-name {
          margin-top: vwMobile(5px);
          font-size: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-top: vw(7px);
            font-size: vw(36px);
          }
        }
      }

      img {
        width: vwMobile(135px);
        height: auto;
        margin-bottom: vwMobile(17px);

        @include media-breakpoint-up(md) {
          width: vw(184px);
          margin-bottom: vw(44px);
        }
      }
    }

    .step-content {
      padding-top: vwMobile(14px);
      border-top: 1px solid #3b393a;
      font-size: vwMobile(14px);
      font-family: AppleSDGothicNeoB;
      line-height: 1.71;
      white-space: initial;
      letter-spacing: -0.03em;

      @include media-breakpoint-up(md) {
        padding-top: vw(19px);
        font-size: vw(24px);
        line-height: 1.66;
      }
    }
  }
