$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .popup-recycled-bottle-production-process {
    --modal-min-height: #{vwMobile(700px)};

    @include media-breakpoint-up(md) {
      --modal-min-height: #{vw(660px)};
    }

    :deep(.cocacola-s5-modal-body) {
      font-size: vwMobile(14px);
      text-align: center;
      color: #303030;

      @include media-breakpoint-up(md) {
        font-size: vw(14px);
      }
    }

    :deep(.cocacola-s5-modal-content) {
      .cocacola-s5-modal-title {
        margin-bottom: vwMobile(38px);
        font-size: vwMobile(24px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(45px);
          font-size: vw(24px);
        }
      }
    }

    .box-step {
      margin-bottom: vwMobile(30px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(30px);
      }

      .box-title {
        margin-bottom: vwMobile(10px);
        font-weight: 500;
        font-size: vwMobile(24px);
        line-height: normal;

        @include media-breakpoint-up(md) {
          margin-bottom: vw(10px);
          font-size: vw(24px);
        }
      }

      .box-desc {
        margin-bottom: vwMobile(10px);
        line-height: 1.5;

        @include media-breakpoint-up(md) {
          margin-bottom: vw(10px);
        }
      }

      img {
        width: vwMobile(182px);
        height: vwMobile(121px);

        @include media-breakpoint-up(md) {
          width: vw(200px);
          height: vw(130px);
        }
      }

      &.step-5 {
        img {
          width: vwMobile(208px);
          height: vwMobile(188px);

          @include media-breakpoint-up(md) {
            width: vw(197px);
            height: vw(178px);
          }
        }
      }
    }
  }
