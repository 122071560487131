$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.content-wrapper {
  position: relative;
  padding: vw(70px);
  margin-bottom: vw(66px);
  border-radius: vw(48px);
  background-color: #fff;
  box-shadow: 0px vw(4px) vw(4px) rgba(0, 0, 0, 0.25);

  @include media-breakpoint-down(md) {
    padding: vwMobile(30px) vwMobile(12px);
    margin-bottom: vwMobile(38px);
    border-radius: vwMobile(6px);
    box-shadow: 0px vwMobile(2px) vwMobile(2px) rgba(0, 0, 0, 0.25);
  }

  .item {
    margin-bottom: vw(90px);

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(35px);
    }

    .title {
      margin: vw(33px) 0 vw(40px);
      font-size: vw(36px);
      font-family: SCDream7;
      line-height: vw(43px);
      text-align: center;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        margin: 0 0 vwMobile(10px);
        font-size: vwMobile(16px);
        line-height: vwMobile(19px);
      }
    }

    .video-wrapper {
      position: relative;
      // 560w / 315h
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.next-button-wrapper {
  margin-bottom: vw(147px);
  text-align: right;

  @include media-breakpoint-down(md) {
    margin-bottom: vwMobile(67px);
    text-align: center;
  }
}

// 只在mobile显示
.bg-image {
  position: absolute;
  width: vw(310px);

  &.top {
    top: vw(300px);
    left: vw(90px);
  }

  &.bottom {
    bottom: vw(185px);
    right: vw(117px);
  }
}
