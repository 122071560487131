$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  $state-width: 186px;
  $state-height: 38px;
  $state-right: 74px;

  .more-text {
    font-weight: bold;
    font-size: 8px;
    text-transform: capitalize;
    color: $primary;
  }

  .my-points-wrapper {
    margin-top: 42px;

    .current-points-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 45px 63px 43px 52px;
      font-size: 22px;
      background: #f8f8f8;

      .label {
        font-weight: bold;
        font-size: 22px;
        color: #000000;
      }

      strong {
        margin-right: 8px;
        font-weight: bold;
        font-size: 32px;
        color: $primary;
      }
    }

    .text-wrapper {
      margin-top: 47px;
      font-size: 18px;

      a {
        font-weight: bold;
        text-decoration: none;
        color: #60bf44;
      }
    }

    .sub-title {
      margin-top: 90px;
      font-weight: bold;
      font-size: 26px;
      color: $primary;
    }

    .charity-list-box {
      margin-top: 30px;
    }
  }

  .history-wrapper {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
    margin-top: 52px;

    .item-card {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 640px;
      padding: 23px 29px;
      margin-bottom: 25px;
      border: 1px solid $gray-light;
      border-radius: 20px;
      font-size: 24px;

      .image {
        width: 120px;
        height: 120px;
        margin-right: 47px;
        border-radius: 20px;
      }

      .state {
        position: absolute;
        top: 0;
        right: $state-right;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $state-width;
        height: $state-height;
        border-radius: 0 0 30px 30px;
        font-size: 18px;
        color: #fff;
        background: #ccc;

        &.sent {
          background: $primary;
        }

        @include media-breakpoint-down(md) {
          right: 50%;
          transform: translateX(50%);
        }
      }

      .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120px;

        .title {
          padding-top: 16px;
          font-weight: bold;
          font-size: 18px;
          color: $font-color-primary;
        }

        .sub-text-wrapper {
          font-size: 22px;

          .primary {
            margin-right: 9px;
            font-weight: bold;
            font-size: 32px;
            color: $primary;
          }
        }
      }
    }
  }

  .pagination-box {
    display: flex;
    justify-content: center;
  }
