$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.notice-modal {
  --modal-min-height: #{vwMobile(644px)};
  color: #303030;

  @include media-breakpoint-up(md) {
    --modal-min-height: #{vw(754px)};
  }

  :deep(.cocacola-s5-modal-title) {
    padding-bottom: vwMobile(8px);
    margin-top: vwMobile(-15px);

    @include media-breakpoint-up(md) {
      padding-bottom: vw(5px);
      margin-top: vw(-25px);
    }
  }

  :deep(.cocacola-s5-modal-footer) {
    padding: 0;
  }

  .notice-desc {
    margin-bottom: vwMobile(6px);
    font-size: vwMobile(14px);
    line-height: 1.5;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(12px);
      font-size: vw(17px);
    }
  }

  .box-hint {
    position: relative;
    margin: 0 vwMobile(7px) 0;
    border: vwMobile(4px) solid #b01614;
    border-radius: vwMobile(8px);
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      margin: 0 vw(20px) 0;
      border-width: vw(5px);
      border-radius: vw(8px);
    }

    .box-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: vwMobile(52px);
      font-size: vwMobile(24px);
      color: #fff;

      @include media-breakpoint-up(md) {
        height: vw(52px);
        font-size: vw(24px);
      }
    }

    .box-desc {
      padding: vwMobile(13px) 0 vwMobile(14px);
      border-bottom-right-radius: vwMobile(8px);
      border-bottom-left-radius: vwMobile(8px);
      font-size: vwMobile(14px);
      line-height: 1.5;
      text-align: center;
      background-color: #fff;

      .desc:not(:last-child) {
        margin-bottom: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(20px);
        }
      }

      @include media-breakpoint-up(md) {
        padding: vw(12px) 0 vw(11px);
        border-bottom-right-radius: vw(8px);
        border-bottom-left-radius: vw(8px);
        font-size: vw(17px);
      }
    }
  }

  .image {
    width: vwMobile(361px);
    margin-top: vwMobile(-19px);

    @include media-breakpoint-up(md) {
      width: vw(511px);
      height: vw(270px);
      margin-top: vw(-21px);
      margin-left: vw(4px);
    }
  }

  .box-tip {
    margin-top: vwMobile(19px);
    font-size: vwMobile(11px);
    line-height: 1.5;
    text-align: center;
    color: #b01614;

    @include media-breakpoint-up(md) {
      margin-top: vw(31px);
      font-size: vw(14px);
    }
  }

  .footer {
    margin-top: vwMobile(12px);
    font-weight: 500;
    font-size: vwMobile(14px);
    line-height: 1.5;
    text-align: center;
    color: #000;

    @include media-breakpoint-up(md) {
      margin-top: vw(13px);
      font-size: vw(17px);
      line-height: 1.4;
    }
  }
}
