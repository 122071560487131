$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .swiper-wrapper {
    position: relative;

    .carousel-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: vw(790px);
      margin: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .carousel {
        width: 100%;
        text-align: center;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .image {
          width: vw(570px);

          @include media-breakpoint-down(md) {
            width: vwMobile(220px);
          }
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: vw(104px);
          font-weight: 700;
          font-size: vw(36px);
          text-align: center;

          @include media-breakpoint-down(md) {
            min-height: vwMobile(44px);
            font-size: vwMobile(13px);
          }
        }
      }

      .icon-prev, .icon-next {
        width: vw(31px);
        height: vw(46px);

        @include media-breakpoint-down(md) {
          width: vwMobile(31px / 2);
          height: vwMobile(46px / 2);
        }
      }


      .carousel-control-prev, .carousel-control-next {
        // position: static;
        width: auto;
      }
    }

    .carousel-indicators {
      position: static;
      margin: 0;
      margin-top: vw(17px);
      font-weight: 400;
      font-size: vw(24px);
      font-family: Noto Sans KR;
      color: rgba(59, 57, 58, 0.5);

      @include media-breakpoint-down(md) {
        margin-top: vwMobile(17px);
        font-size: vwMobile(12px);
      }
    }
  }
