$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .steps-box {
    padding: 38px;
    border-radius: 20px;
    background: #f8f8f8;

    .item {
      position: relative;

      &:last-child {
        .item-container {
          .tail {
            display: none;
          }
        }
      }

      &.finished {
        .item-container {
          .icon {
            border-color: $primary;
            background: $primary;
          }
        }
      }

      .item-container {
        $title-line-height: 34px;
        $icon-size: 18px;

        display: flex;
        min-height: 66px;

        .tail {
          position: absolute;
          z-index: 0;
          top: ($title-line-height - $icon-size) / 2;
          left: $icon-size / 2;
          flex-shrink: 0;
          width: 1px;
          height: 100%;
          padding: $title-line-height - $icon-size 0 0;
          transform: translateX(-50%);

          &::after {
            display: inline-block;
            height: 100%;
            border-left: 1px dashed #ccc;
            border-radius: 1px;
            transition: background .3s;
            content: "";
          }
        }

        .icon {
          position: relative; // 遮挡 rem 定位的误差
          box-sizing: border-box;
          width: $icon-size;
          height: $icon-size;
          margin-top: ($title-line-height - $icon-size) / 2;
          border: 1px solid #dddddd;
          border-radius: 99px;
          background: #fff;
        }

        .content {
          display: flex;
          flex: 1;
          margin-left: $icon-size;
          overflow: hidden;

          .title {
            flex-shrink: 0;
            font-weight: bold;
            font-size: 24px;
            line-height: $title-line-height;
            color: $primary;
          }

          .description {
            flex: 1;
            margin-left: 38px;
            overflow: hidden;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }

  .product-list-box {
    margin-top: 13px;
  }

  .statistics-box,
  .order-info-box {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: $gray;

      &:not(:last-child) {
        margin-bottom: 21px;
      }

      .label
      .value {
        display: inline;
        overflow-wrap: break-word;
      }

      .label {
        margin-right: 10px;
      }

      .value {
        color: $font-color-primary;

        &.actual-payment {
          font-weight: bold;
          color: $primary;
        }
      }
    }
  }

  .statistics-box {
    margin-top: 32px;
  }

  .order-info-box {
    margin-top: 63px;

    .title {
      margin-bottom: 35px;
      font-weight: bold;
      font-size: 26px;
      color: $primary;
    }
  }
