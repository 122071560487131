$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}


.title {
  color: $primary;

  &h1 {
    font-size: 32px;
  }

  &h2 {
    font-size: 26px;
  }
}

.current-have-point {
  padding: 45px;
  margin: 40px 0 60px;
  border-radius: 20px;
  font-size: 22px;
  background: #f8f8f8;

  .label {
    font-weight: bold;
    font-size: 22px;
  }

  .number {
    margin: 0 9px;
    font-weight: bold;
    font-size: 32px;
    color: $primary
  }
}

.text-container {
  margin-bottom: 20px;
  font-size: 18px;

  &::before,
  &::after {
    display: table;
    content: '';
  }

  .title,
  .text {
    margin-bottom: 40px;

    .link {
      text-decoration: none;
      color: #60bf44;
    }
  }
}

.dedeem-wrapper {
  margin-top: 30px;

  :deep(.dropdown-toggle) {
    font-weight: both;
    font-size: 22px;
    color: #000;
    background: transparent;

    &::after {
      display: none;
    }
  }

  .filter-wrapper {
    display: flex;
    align-items: center;

    .icon {
      width: 13px;
      margin-left: 30px;
    }
  }
}

.points-wrapper {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 30px;
  margin: 40px 0 80px;

  .item {
    .pointer {
      cursor: pointer;
    }

    .image {
      width: 100%;
      margin-bottom: 34px;
      border-radius: 20px;
    }

    .content-wrapper {
      padding-bottom: 3px;

      .point-name {
        height: 66px;
        margin-bottom: 34px;
        font-weight: 500;
        font-size: 26px;
        line-height: 33px;
      }

      .point-number {
        font-size: 22px;

        .number {
          margin-right: 3px;
          font-weight: bold;
          font-size: 32px;
          color: $primary;
        }
      }

      :deep(.btn) {
        padding: 11px 24px;
        font-size: 18px;
      }
    }
  }
}

.search-wrapper {
  margin-bottom: 40px;

  .result-list {
    border: 1px solid #ddd;
    border-radius: 20px;

    .result-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;

      &:not(:last-child) {
        border-bottom: 1px solid #dddddd;
      }

      .strong {
        margin-right: 5px;
        font-size: 18px;
        color: $primary;
      }
    }

    .icon-right {
      width: 37px;
    }

    .empty {
      padding: 20px;
      text-align: center;
    }
  }
}

.dialog-wrapper {
  width: 100%;
  font-size: 22px;

  .promary {
    margin-right: 8px;
    font-weight: bold;
    font-size: 32px;
    color: $primary;
  }

  .text-content {
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    background: #f8f8f8;

    .label {
      margin-bottom: 45px;
      color: #000;
    }
  }
}

.ja-nenew-charity-btn-wrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    width: 100% !important;

    :deep(.btn) {
      width: auto !important;
    }
  }
}

