$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../vars.scss';

.page-method {
  padding-bottom: vwMobile(55px);
  overflow: hidden;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
}

.section-1 {
  position: relative;
  padding-top: vwMobile(98px);
  padding-bottom: vwMobile(28px);
  text-align: center;
  color: #303030;

  @include media-breakpoint-up(md) {
    --bottle-45deg-top: auto;
    --bottle-45deg-bottom: 0;
    --bottle-135deg-top: auto;
    --bottle-135deg-bottom: #{vw(83px)};
    --bottle-45deg-translate-y: 12%;
    --bottle-135deg-translate-y: 0;

    padding-top: vw(94px);
    padding-bottom: vw(60px);
  }

  .section-title {
    margin-bottom: vwMobile(25px);
    font-weight: 500;
    font-size: vwMobile(24px);
    line-height: normal;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(10px);
      font-size: vw(42px);
    }
  }

  .desc {
    font-size: vwMobile(14px);
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
    }

    &:not(:last-child) {
      margin-bottom: vwMobile(25px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(40px);
      }
    }

    strong {
      font-weight: 500;

      @include media-breakpoint-up(md) {
        font-weight: inherit;
      }
    }
  }

  .box-step {
    margin: vwMobile(48px) vwMobile(10px) 0;
    border: vwMobile(5px) solid #b01614;
    border-radius: vwMobile(8px);
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      width: vw(1198px);
      margin: vw(60px) auto 0;
      border: vw(5px) solid #b01614;
      border-radius: vw(8px);
    }

    .box-step-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: vwMobile(53px);
      font-weight: 500;
      font-size: vwMobile(24px);
      color: #fff;

      @include media-breakpoint-up(md) {
        height: vw(80px);
        font-size: vw(36px);
      }
    }

    .steps {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      list-style: none;
      padding: vwMobile(12px) 0 vwMobile(27px);
      margin: 0;
      border-bottom-right-radius: vwMobile(8px);
      border-bottom-left-radius: vwMobile(8px);
      background-color: #fff;

      @include media-breakpoint-up(md) {
        justify-content: center;
        padding: vw(20px) 0 vw(32px);
        border-bottom-right-radius: vw(8px);
        border-bottom-left-radius: vw(8px);
      }

      .step-item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        font-size: vwMobile(14px);
        line-height: 1.5;

        @include media-breakpoint-up(md) {
          font-weight: 400;
          font-size: vw(18px);

          &:not(:last-child) {
            margin-right: vw(100px);
          }
        }

        .box-img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: vwMobile(160px);
          margin-bottom: vwMobile(11px);

          @include media-breakpoint-up(md) {
            justify-content: flex-end;
            height: vw(230px);
            margin-bottom: vw(18px);
          }
        }

        img {
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        .desc {
          display: flex;
          text-align: left;
          white-space: nowrap;
        }
      }

      .step-item-1 {
        width: 100%;
        margin-bottom: vwMobile(32px);

        @include media-breakpoint-up(md) {
          width: unset;
          margin-bottom: 0;
        }

        img {
          width: vwMobile(99px);

          @include media-breakpoint-up(md) {
            width: vw(157px);
          }
        }
      }

      .step-item-2,
      .step-item-3 {
        width: 50%;

        @include media-breakpoint-up(md) {
          width: unset;
        }
      }

      .step-item-2 {
        padding-left: vwMobile(20px);

        @include media-breakpoint-up(md) {
          padding-left: 0;
        }

        img {
          width: vwMobile(110px);

          @include media-breakpoint-up(md) {
            width: vw(146px);
          }
        }
      }

      .step-item-3 {
        padding-right: vwMobile(20px);

        @include media-breakpoint-up(md) {
          padding-right: 0;
        }

        img {
          width: vwMobile(69px);

          @include media-breakpoint-up(md) {
            width: vw(93px);
          }
        }
      }
    }
  }
}

.section-2 {
  padding: vwMobile(30px) vwMobile(10px) vwMobile(39px);

  @include media-breakpoint-up(md) {
    padding: vw(64px) 0 vw(71px);
  }

  .section-title,
  .section-subtitle {
    line-height: 1.5;
    text-align: center;
    color: #fff;
  }

  .section-title {
    font-weight: 500;
    font-size: vwMobile(24px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(10px);
      font-size: vw(42px);
    }
  }

  .section-subtitle {
    margin-bottom: vwMobile(32px);
    font-size: vwMobile(14px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(48px);
      font-size: vw(24px);
    }
  }

  .tabs {
    @include media-breakpoint-up(md) {
      width: vw(824px);
      margin: 0 auto;
    }

    :deep(.app-tab-content) {
      margin-top: -1px;
    }

    :deep(.box-tab-items) {
      align-items: flex-end;
      height: vwMobile(44px);
      background: #303030;

      @include media-breakpoint-up(md) {
        height: vw(68px);
      }

      .tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-weight: 500;
        font-size: vwMobile(18px);
        line-height: normal;
        color: #fff;

        @include media-breakpoint-up(md) {
          font-size: vw(24px);
          line-height: 1.5;

          &:first-child {
            justify-content: flex-start;
            padding-left: vw(27px);
          }

          &:last-child {
            justify-content: flex-end;
            padding-right: vw(27px);
          }
        }

        &.active {
          flex: unset;
          width: vwMobile(227px);
          height: vwMobile(54px);
          padding-right: vwMobile(28px);
          font-size: vwMobile(24px);
          color: #b01614;
          background-image: v-bind(tab1Bg);
          background-size: 100% 100%;
          background-repeat: no-repeat;

          @include media-breakpoint-up(md) {
            width: vw(472px);
            height: vw(84px);
            padding-top: vw(16px);
            padding-right: 0;
            font-size: vw(24px);
          }
        }
      }

      &.active-1 {
        background: #fff;

        .tab-item {
          color: #b01614;

          &.active {
            width: vwMobile(211px);
            padding-right: 0;
            padding-left: vwMobile(22px);
            color: #fff;
            background-image: v-bind(tab2Bg);

            @include media-breakpoint-up(md) {
              width: vw(443px);
              padding-right: vw(27px);
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .tab-1 {
    padding: vwMobile(18px) vwMobile(9px);
    font-size: vwMobile(14px);
    line-height: 1.5;
    text-align: center;
    color: #303030;
    background-color: #fff;

    @include media-breakpoint-up(md) {
      padding: vw(14px) vw(14px) vw(27px);
      font-size: vw(18px);
    }

    img {
      width: vwMobile(178px);
      aspect-ratio: 27 / 44;

      @include media-breakpoint-up(md) {
        width: vw(290px);
        aspect-ratio: 190 / 311;
      }
    }

    .desc {
      margin-bottom: vwMobile(20px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(31px);
      }
    }

    .qa-container {
      padding-top: vwMobile(18px);
      border-top: 1px solid #303030;

      @include media-breakpoint-up(md) {
        padding-top: vw(18px);
        text-align: left;
      }

      .box-qa {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
          padding-left: vw(42px);
        }

        .q {
          margin-bottom: vwMobile(10px);
          font-weight: 500;
          font-size: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-bottom: vw(10px);
            font-size: vw(24px);
          }
        }

        .a {
          display: flex;
          align-items: center;

          img {
            width: vwMobile(42px);
            margin-right: vwMobile(9px);
            aspect-ratio: 1 / 1;

            @include media-breakpoint-up(md) {
              width: vw(51px);
              margin-right: vw(11px);
            }
          }
        }
      }
    }
  }

  .tab-2 {
    padding: vwMobile(27px) vwMobile(9px) vwMobile(13px);
    text-align: center;
    background-color: #303030;

    @include media-breakpoint-up(md) {
      padding: vw(66px) vw(14px) vw(19px);
    }

    img {
      width: vwMobile(335px);
      margin-bottom: vwMobile(22px);

      @include media-breakpoint-up(md) {
        width: vw(700px);
        margin-bottom: vw(23px);
      }
    }

    .desc {
      padding-top: vwMobile(15px);
      border-top: 1px solid #fff;
      text-align: left;

      @include media-breakpoint-up(md) {
        padding-top: vw(18px);
        padding-left: vw(56px);
      }
    }
  }
}

.section-3 {
  padding: vwMobile(42px) 0 vwMobile(73px);

  @include media-breakpoint-up(md) {
    padding: vw(61px) 0 vw(94px);
  }

  .section-title {
    margin-bottom: vwMobile(35px);
    font-weight: 500;
    font-size: vwMobile(24px);
    line-height: normal;
    text-align: center;
    color: #303030;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(60px);
      font-size: vw(42px);
    }
  }

  .swiper {
    --swiper-pagination-bottom: 0;
    --swiper-pagination-color: #b01614;
    --swiper-pagination-bullet-inactive-color: #303030;
    --swiper-pagination-bullet-horizontal-gap: #{vwMobile(5px)};
    --swiper-pagination-bullet-size: #{vwMobile(10px)};

    padding: 0 vwMobile(4px) vwMobile(27px) vwMobile(14px);

    @include media-breakpoint-up(md) {
      width: vw(1170px);
      padding: 0 0 0 vw(10px);
      margin: 0 auto;

      .swiper-pagination {
        display: none;
      }
    }

    .swiper-slide {
      padding-right: vwMobile(10px);

      @include media-breakpoint-up(md) {
        padding-right: vw(10px);
      }

      .box-slide {
        width: 100%;
        background-color: #303030;

        .box-img {
          position: relative;
          padding-bottom: 80.4687%;
          overflow: hidden;

          @include media-breakpoint-up(md) {
            padding-bottom: 73.5714%;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 108.2524%;
          }
        }

        .desc {
          height: vwMobile(114px);
          padding-top: vwMobile(14px);
          padding-left: vwMobile(18px);
          font-size: vwMobile(14px);
          color: #fff;

          @include media-breakpoint-up(md) {
            height: vw(166px);
            padding-top: vw(14px);
            padding-left: vw(18px);
            font-size: vw(18px);
          }
        }
      }
    }
  }
}

.section-4 {

  --desc-color: #303030;
  padding: vwMobile(26px) 0 vwMobile(35px) vwMobile(22px);
  background-color: #fff;

  @include media-breakpoint-up(md) {
    --desc-row-justify-content: center;
    --desc-title-text-align: center;
  }

  @include media-breakpoint-up(md) {
    padding: vw(0px) 0 vw(67px);
  }
}
