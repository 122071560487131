$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.page-6 {
  position: relative;
}

.box-tab {
  position: absolute;
  z-index: 1;
  bottom: vw(609px);
  left: 50%;
  width: vw(1194px);
  height: vw(799px);
  transform: translateX(-50%);

  .app-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));

    :deep(.box-tab-items) {
      flex-shrink: 0;

      .tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: vw(80px);
        border-top-right-radius: vw(16px);
        border-top-left-radius: vw(16px);
        font-weight: 400;
        font-size: vw(26px);
        font-family: SB AggroOTF;
        color: #fff;
        background-color: $primary-color;

        &:nth-child(1),
        &:nth-child(2) {
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
        }

        &:nth-child(3) {
          &.active {
            box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }

        &.active {
          color: #000;
          background-color: #fff;
        }
      }
    }

    :deep(.app-tab-content) {
      flex: 1;
      border-bottom-right-radius: vw(16px);
      border-bottom-left-radius: vw(16px);
      text-align: center;
      background-color: #fff;

      img {
        width: 100%;
        margin-top: vw(79px);
      }

      .tab-1-content {
        width: vw(676px);
        padding-bottom: vw(83px);
      }

      .tab-2-content {
        width: vw(528px);
      }

      .tab-3-content {
        width: vw(929px);
      }
    }
  }
}
