$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  $selected: #eecc3f;

  .filter-wrapper {
    display: flex;
    width: 100%;

    &.lang-ko {
      font-family: CircularXXBold;
    }

    .arrow {
      width: 48px;
      height: 48px;
    }
  }

  .list-box {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: auto;

    @include media-breakpoint-down(lg) {
      justify-content: start;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      padding: 12px 42px;
      margin-right: 29px;
      font-weight: 400;
      font-size: 24px;
      color: #000;
      cursor: pointer;

      @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;
        height: vwMobile(34px);
        padding: 0 15px;
        margin-right: vwMobile(4px);
        border: 1px solid #c1c1c1;
        border-radius: vwMobile(27px);
        font-size: vwMobile(14px);

        &:first-child {
          margin-left: vwMobile(38px);
        }
      }

      .circle {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        border: 2px solid #000;
        border-radius: 50%;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &.selected {
        color: $selected;

        @include media-breakpoint-down(lg) {
          font-weight: bold;

          &:first-child {
            border-color: #157b4d;
            color: #fff;
          }
        }

        .circle {
          border-color: $selected;
        }
      }
    }
  }
