$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import './vars.scss';

  $border-color: #c1c1c1;
  $primary-color: #157b4d;

  .main {
    padding-bottom: 58px;

    .container {
      max-width: 800PX;
      padding: 0;
    }

    .offcanvas {
      width: 100%;

      .offcanvas-header {
        .arrow {
          width: 48px;
          cursor: pointer;

          @include media-breakpoint-down(md) {
            width: 24px;
          }
        }
      }

      .share-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: -200px;

        .share-title {
          font-size: 48px;
          line-height: 68px;
          text-align: center;
          white-space: pre-line;

          @include media-breakpoint-down(md) {
            font-size: vwMobile(24px);
            line-height: vwMobile(34px);
          }
        }

        .share-icon {
          display: flex;
          justify-content: center;
          margin-top: 80px;

          @include media-breakpoint-down(md) {
            margin-top: 40px;
          }

          .icon {
            width: 110px;
            margin: 0 16px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
              width: vwMobile(55px);
              margin: 0 vwMobile(8px);
            }
          }
        }
      }

      .url-copy-btn {
        position: fixed;
        bottom: vwMobile(34px);
        left: vwMobile(16px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: vwMobile(359px);
        height: vwMobile(47px);
        border-radius: vwMobile(8px);
        font-size: vwMobile(16px);
        color: #fff;
        background-color: rgba(#000, 70%);
      }
    }
  }

  .page-breadcurmb {
    margin: 40px 0;
  }

  .header-wrapper {
    position: relative;
    padding: 320px 0 44px;
    border-bottom: 1px solid rgba($border-color, 0.6);

    @include media-breakpoint-down(md) {
      padding: 47px 0 16px;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
    }

    .container {
      position: relative;

      @include media-breakpoint-down(lg) {
        padding: 0 vwMobile(15px);
      }
    }

    h1 {
      margin-bottom: 35px;
      font-size: 48px;
      line-height: 57px;
    }

    .summary {
      font-size: 24px;
      line-height: 34px;
      color: #4b4b4b;
    }

    .tag-box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 98px;

      .tag {
        padding: 7px 15px;
        margin-bottom: 10px;
        border-radius: 27px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #000;

        &.lang-ko {
          font-family: CircularXXRegular;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        @include media-breakpoint-down(md) {
          padding: vwMobile(3px) vwMobile(10px);
          font-size: vwMobile(14px);
          line-height: vwMobile(20px);
        }
      }
    }
  }

  .body-wrapper {
    padding: 86px 0 27px;
    border-bottom: 1px solid rgba($border-color, 0.6);

    &.lang-ko {
      font-family: CircularXXRegular;
    }

    @include media-breakpoint-down(lg) {
      padding: vwMobile(47px) vwMobile(15px) vwMobile(13px);
    }

    .btn-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 168px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
      }

      .btn {
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 0 20px;
        border: 2px solid #000;
        border-radius: 10px;
        font-size: 24px;
        color: #454545;

        .arrow {
          width: 40px;
          margin-left: 50px;
        }

        &.share-btn {
          .share {
            width: 24px;
            margin-right: 15px;
          }

          @include media-breakpoint-down(lg) {
            justify-content: center;
            width: 60px;
            height: 60px;
            padding: 0;
            margin-bottom: 54px;
            border-radius: 50%;

            .share {
              margin-right: 0;
            }

            .text {
              display: none;
            }
          }

          .hover-bg {
            position: absolute;
            top: 0;
            left: -50%;
            display: none;
            width: 275px;
            height: 275px;
          }

          .share-icon {
            position: absolute;
            bottom: -75px;
            left: 0;
            display: flex;
            visibility: hidden;
            transform: translateX(-25%);

            .icon {
              width: 55px;
              margin: 0 5px;
              cursor: pointer;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              .hover-bg {
                display: block;
              }

              .share-icon {
                visibility: visible;
              }
            }
          }
        }

        &.landing {
          display: flex;
          justify-content: space-between;
          padding: 0 98px;
          margin-left: 10px;
          border: none;
          color: #fff;
          background-color: $primary-color;

          @include media-breakpoint-down(lg) {
            max-width: 90%;
            padding: vwMobile(10px) vwMobile(20px);
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }

    .view-count {
      margin-top: 156px;
      font-size: 18px;
      line-height: 26px;
      color: #838383;
    }
  }

  .footer-wrapper {
    margin-top: 31px;

    @include media-breakpoint-down(lg) {
      padding: 0 vwMobile(15px);
    }

    .author {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-align: right;
    }
  }

  .next-story-wrapper {
    margin-top: 334px;

    @include media-breakpoint-down(lg) {
      margin-top: vwMobile(85px);
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 54px;

      @include media-breakpoint-down(lg) {
        padding: 0 vwMobile(15px);
      }

      .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        color: $primary-color;
      }

      .more {
        font-weight: 400;
        font-size: 18px;
        text-decoration: none;
        color: #000;
      }
    }

    .row {
      flex-wrap: nowrap;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .item-box {
        @include media-breakpoint-down(lg) {
          width: 48%;
          padding-left: 0;

          &:first-child {
            margin-left: vwMobile(15px);
          }
        }
      }
    }
  }

  .share-box {
    display: flex;
    align-items: center;

    .text {
      margin-right: 23px;
      font-size: 18px;
      color: $font-color-primary;
    }

    .item {
      &:not(:last-child) {
        margin-right: 12px;
      }

      .icon {
        width: 36px;
        height: 36px;
        cursor: pointer;
        object-fit: contain;
      }
    }
  }

  .richText {
    font-size: 18px;
    line-height: 1.5;

    :deep(img),
    :deep(video) {
      width: 100% !important;
      height: auto !important;
    }
  }
