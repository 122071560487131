$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  $state-width: 186px;
  $state-height: 38px;
  $state-right: 74px;
  $padding-right: 47px;
  $cover-height: 120px;

  .card-user-request {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 36px $padding-right 23px 29px;
    margin-top: 20px;
    border: 1px solid #dddddd;
    border-radius: 20px;

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    a {
      text-decoration: none;
    }

    .state {
      position: absolute;
      top: 0;
      right: $state-right;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $state-width;
      height: $state-height;
      border-radius: 0 0 30px 30px;
      font-size: 18px;
      color: #fff;
      background: #ccc;

      &.approved {
        background: $primary;
      }

      @include media-breakpoint-down(md) {
        right: 50%;
        transform: translateX(50%);
      }
    }

    .button-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .cover {
      width: 120px;
      height: $cover-height;
      margin-right: 40px;
      border-radius: 20px;
      object-fit: cover;
    }

    .card-content {
      width: 100%;

      .box-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: unset;
      }

      .box-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 14px;
        font-size: 18px;

        .program-title {
          font-weight: 500;
          color: #000;
        }

        a {
          font-weight: bold;
          text-decoration: none;
          color: $primary;
        }

        img {
          width: 30px;
          transform: rotate(180deg);
        }

        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: flex-start;

          .program-title {
            margin-bottom: 14px;
          }
        }
      }

      .people-collection {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        padding: 0 13px;
        margin-bottom: 14px;
        border-radius: 15px;
        font-size: 14px;
        background: #f2f2f2;
      }

      .collection-challenge,
      .time {
        font-size: 16px;
        color: #999999;
      }

      .time {
        margin-top: auto;
      }

      .box-footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        margin-top: 14px;

        .button-request {
          min-width: 220px;
        }

        .link-history {
          display: flex;
          align-items: center;
          margin-left: auto;
          border: none;
          font-weight: 500;
          color: $primary-darker;
          background-color: #fff;

          &:disabled {
            color: $gray;
          }
        }

        .arrow {
          width: 16px;
          margin-left: 10px;

          &.disabled {
            :deep(path) {
              fill: $gray;
            }
          }
        }

        @include media-breakpoint-down(md) {
          .arrow {
            margin-left: 5px;
          }
        }
      }
    }

    &.lang-ja {
      .card-content {
        align-self: center;

        .box-info {
          min-height: unset;

          .box-title {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }

            .program-title {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
