$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.section {
  position: relative;
  padding: vw(92px) vw(20px) vw(154px);
  text-align: center;

  @include media-breakpoint-down(md) {
    padding: vwMobile(21px) vwMobile(13px) vwMobile(56px);
  }

  .lock-mask {
    position: absolute;
    z-index: 90;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.8);

    .icon-lock {
      width: vw(260px);
      vertical-align: middle;

      @include media-breakpoint-down(md) {
        width: vwMobile(51px);
      }
    }
  }

  .bg-net {
    position: absolute;
    bottom: 0;
    right: 0;
    width: vw(496px);
  }

  .bg-wave {
    position: absolute;
    top: vw(118px);
    left: vw(158px);
    width: vw(263px);
  }

  .question-content {
    position: relative;
    z-index: 1;
    display: inline-block;
    border-radius: vw(109px);
    background: #ffffff;
    box-shadow: 0px vw(4px) vw(4px) rgba(0, 0, 0, 0.25);

    @include media-breakpoint-down(md) {
      display: block;
    }

    .title-label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vw(440px);
      height: vw(105px);
      margin: auto;
      margin-bottom: vw(30px);
      border-radius: vw(55px);
      font-size: vw(60px);
      font-family: SCDream8;
      color: #fff;
      background: #006db7;

      @include media-breakpoint-down(md) {
        width: vwMobile(163px);
        height: vwMobile(39px);
        margin-bottom: vwMobile(20px);
        border-radius: vwMobile(20px);
        font-size: vwMobile(18px);
      }
    }

    .title {
      font-size: vw(36px);
      font-family: SCDream8;
      line-height: vw(55px);
      color: #006db7;

      @include media-breakpoint-down(md) {
        font-size: vwMobile(18px);
        line-height: vwMobile(30px);
      }
    }

    .item {
      border: vw(5px) solid transparent;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        border: vwMobile(3px) solid transparent;
      }

      &.active {
        border-color: #006db7;
        background: #fdb515;
      }
    }
  }
}

.step-one {
  padding-top: 0;
  font-family: 'Sandoll Nemony';

  .question-content {
    padding: vw(67px) vw(28px);

    @include media-breakpoint-down(md) {
      padding: vwMobile(30px) vwMobile(10px);
    }

    .title {
      margin-bottom: vw(54px);
    }

    .content-wrapper {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .item {
      padding: vw(82px) vw(28px) vw(106px);
      border-radius: vw(94px);
      font-size: vw(36px);
      font-family: SCDream6;

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        padding: vwMobile(14px) vwMobile(20px);
        border-radius: vwMobile(36px);
        font-size: vwMobile(14px);
      }

      .image {
        width: vw(350px);
        height: vw(350px);
        margin-bottom: vw(30px);

        @include media-breakpoint-down(md) {
          width: vwMobile(130px);
          height: vwMobile(130px);
          margin-right: vwMobile(55px);
          margin-bottom: 0;
        }
      }
    }
  }
}

.step-two {
  background: #fdb515;

  .bg-net {
    bottom: vw(100px);
  }

  .question-content {
    padding: vw(72px) vw(268px) vw(100px);
    margin-top: vw(100px);
    margin-bottom: vw(128px);

    @include media-breakpoint-down(md) {
      padding: vwMobile(36px) vwMobile(14px) vwMobile(50px);
      margin-top: vwMobile(50px);
      margin-bottom: vwMobile(10px);
    }

    .title {
      margin-bottom: vw(67px);

      @include media-breakpoint-down(md) {
        margin-bottom: vwMobile(14px);
      }
    }

    .item {
      display: flex;
      align-items: center;
      padding: vw(6px) vw(32px);
      border-radius: vw(36px);
      font-size: vw(26px);
      font-family: SCDream6;
      text-align: left;

      @include media-breakpoint-down(md) {
        padding: vwMobile(20px) vwMobile(6px);
        border-radius: vwMobile(36px);
        font-size: vwMobile(14px);
      }

      .image {
        width: vw(250px);
        height: vw(250px);
        margin-right: vw(54px);

        @include media-breakpoint-down(md) {
          width: vwMobile(100px);
          height: vwMobile(100px);
          margin-right: vwMobile(20px);
        }
      }
    }
  }
}

