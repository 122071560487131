$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import './fonts.scss';

.page-compaign-huggies {
  @function vw($target) {
    // >= 1920 按设计尺寸显示
    // >= md && < 1920 按设计尺寸缩放
    // <= md 按zoom方法调整
    @if unit($target) == px {
      $vw: $target / 1920px * 100vw;

      @if ($target < 0px) {
        @return max($vw, $target);
      }

      @else {
        @return min($vw, $target);
      }
    }

    @else {
      @return $target;
    }
  }

  @function zoom($target, $ratio: 0.7) {
    @return $target * $ratio;
  }

  overflow-x: hidden;
  font-family: AppleSDGothicNeo;

  .font-AndongKaturiOTF {
    font-family: AndongKaturiOTF;
  }

  .font-HaenamFont {
    font-family: HaenamFont;
  }

  .container {
    @include media-breakpoint-up(md) {
      width: vw(1320px);
      padding: 0;
    }
  }

  .card {
    border: none;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  .screen-top {
    padding-top: vw(87px);
    padding-bottom: vw(61px);
    background: #fcf4e7;

    @include media-breakpoint-down(md) {
      padding-top: zoom(87px);
      padding-bottom: zoom(61px);
    }

    .screen-top-main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .img-union {
      width: vw(785px);
      height: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .screen-name {
      margin-top: vw(28px);
      font-size: vw(25px);
      font-family: AndongKaturiOTF;
      color: #5e9172;

      @include media-breakpoint-down(md) {
        margin-top: zoom(28px);
        font-size: zoom(25px);
      }
    }

    .screen-time {
      margin-top: vw(44px);
      font-size: vw(24px);
      font-family: HaenamFont;
      line-height: vw(24px);
      letter-spacing: -0.03em;
      color: #000;

      @include media-breakpoint-down(md) {
        margin-top: zoom(44px);
        font-size: zoom(24px);
        line-height: zoom(24px);
      }
    }

    .box-compaign-info {
      width: 100%;
      margin-top: vw(68px);

      @include media-breakpoint-down(md) {
        margin-top: zoom(68px);
      }

      .compaign-info {
        position: relative;
        flex-shrink: 0;
        box-sizing: border-box;
        width: vw(1196px);
        padding: vw(41px) vw(390px) vw(65px) vw(64px);
        margin-left: vw(-64px);
        border-radius: vw(83px);
        background: #fbf7f4;

        @include media-breakpoint-down(md) {
          width: 100%;
          padding: zoom(41px);
          margin-top: zoom(747px / 2, 0.36);
          margin-left: 0;
          border-radius: 20px;
        }

        .title {
          margin: 0;
          font-size: vw(46px);
          font-family: HaenamFont;
          line-height: vw(55px);
          color: #498863;

          @include media-breakpoint-down(md) {
            font-size: zoom(46px);
            line-height: zoom(55px);
          }
        }

        .img-frame {
          width: vw(201px);
          height: auto;
          margin-top: vw(43px);

          @include media-breakpoint-down(md) {
            width: zoom(201px);
            margin-top: zoom(43px);
          }
        }

        .group-p {
          margin-top: vw(43px);

          @include media-breakpoint-down(md) {
            margin-top: zoom(43px);
          }
        }

        p {
          margin: 0;
          font-size: vw(22px);
          line-height: vw(40px);
          letter-spacing: -0.03em;
          color: #66420c;

          @include media-breakpoint-down(md) {
            font-size: zoom(22px);
            line-height: zoom(40px);
          }
        }

        .important {
          color: #61b852;
        }

        .box-main-img {
          position: absolute;
          top: 0;
          right: 0;
          width: vw(713px);
          height: vw(747px);
          transform: translateX(vw(478px));

          @include media-breakpoint-down(md) {
            position: unset;
            display: flex;
            justify-content: center;
            width: zoom(713px, 0.36);
            height: zoom(747px, 0.36);
            margin: zoom(-747px / 2, 0.36) auto 30px;
            transform: unset;
          }

          .box-main-img-content {
            position: relative;
            width: 100%;
          }

          .img-leaf {
            position: absolute;
            top: vw(-35px);
            left: vw(-90px);
            width: vw(394px);
            height: auto;

            @include media-breakpoint-down(md) {
              top: zoom(-35px, 0.36);
              left: zoom(-90px, 0.36);
              width: zoom(394px, 0.36);
            }
          }

          .img-main {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .img-logos {
      width: vw(274px);
      height: auto;
      margin-top: vw(44px);

      @include media-breakpoint-down(md) {
        width: zoom(274px);
        margin-top: zoom(44px);
      }
    }
  }

  .screen-bottom {
    padding-top: vw(137px);
    padding-bottom: vw(136px);
    background: linear-gradient(180deg, #498863 33.33%, #5a8f6f 100%);

    @include media-breakpoint-down(md) {
      padding-top: zoom(137px);
      padding-bottom: zoom(136px);
    }

    & > .container {
      position: relative;

      .symbol-group {
        position: absolute;
        bottom: 10%;
        right: -5%;

        @include media-breakpoint-down(md) {
          // 移动端位置不确定
          display: none;
        }
      }
    }

    .symbol-group {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      .symbol-1 {
        width: vw(125px);
        transform: rotate(-81.95deg);

        @include media-breakpoint-down(md) {
          width: zoom(125px);
        }
      }

      .symbol-2 {
        width: vw(64px);
        margin-top: vw(30px);
        margin-left: vw(300px);
        transform: rotate(-19.4deg);

        @include media-breakpoint-down(md) {
          width: zoom(64px);
          margin-top: zoom(30px);
          margin-left: zoom(300px);
        }
      }

      .symbol-3 {
        width: vw(425px);

        @include media-breakpoint-down(md) {
          width: zoom(425px);
        }
      }
    }

    .page-tabs {
      .symbol-tab {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: vw(125px);
        height: auto;
        transform: rotate(38.95deg) translate(0, 90%);

        @include media-breakpoint-down(md) {
          width: zoom(125px);
        }
      }

      > .box-tab-items {
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(md) {
          overflow-x: auto;
        }

        .tab-item {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          height: vw(114px);
          border: 1px solid #fff;
          text-decoration: none;
          color: #fff;
          background: #498863;

          &:not(:last-child) {
            margin-right: vw(11px);
          }

          @include media-breakpoint-down(md) {
            flex: unset;
            flex-shrink: 0;
            width: zoom(255px);
            height: zoom(114px);

            &:not(:last-child) {
              margin-right: zoom(11px);
            }
          }

          .name {
            font-size: vw(30px);
            font-family: HaenamFont;
            line-height: vw(45px);

            @include media-breakpoint-down(md) {
              font-size: zoom(30px);
              line-height: zoom(45px);
            }
          }

          .indicator-container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: vw(32px);
            height: vw(13px);
            margin-top: vw(5px);

            @include media-breakpoint-down(md) {
              width: zoom(32px);
              height: zoom(13px);
              margin-top: zoom(5px);
            }
          }

          img {
            width: 100%;
            height: auto;
          }

          &.active {
            border-color: #fcf4e7;
            color: #498863;
            background: #fcf4e7;
          }
        }
      }

      & > .app-tab-content {
        position: relative;
        z-index: 2;
      }
    }
  }

  .screen-footer {
    position: relative;
    padding-top: vw(94px);
    padding-bottom: vw(110px);
    line-height: vw(46px);
    text-align: center;
    color: #000000;
    background: #fcf4e7;

    @include media-breakpoint-down(md) {
      padding-top: zoom(94px);
      padding-bottom: zoom(110px);
      line-height: zoom(46px);
    }

    .footer-btn {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      align-items: center;
      height: vw(90px);
      padding: 0 vw(35px);
      border-radius: vw(50px);
      font-size: vw(26px);
      font-family: HaenamFont;
      letter-spacing: -0.02em;
      color: #ffffff;
      background: #498863;
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(md) {
        height: zoom(90px);
        padding: 0 zoom(35px);
        border-radius: zoom(50px);
        font-size: zoom(26px);
      }
    }

    p {
      font-weight: 800;
      font-size: vw(24px);

      @include media-breakpoint-down(md) {
        font-size: zoom(24px);
      }
    }

    .border-text {
      display: inline-block;
      box-sizing: border-box;
      padding: vw(10px) vw(42px);
      margin-top: vw(15px);
      border: 1px solid #000;
      font-weight: 500;
      font-size: vw(20px);

      @include media-breakpoint-down(md) {
        padding: zoom(10px) zoom(42px);
        margin-top: zoom(15px);
        font-size: zoom(20px);
      }
    }
  }

  .title-line {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 400;
    font-size: vw(40px);
    font-family: HaenamFont;
    line-height: vw(45px);
    letter-spacing: -0.03em;
    color: #fcf4e7;

    &:before {
      margin-right: vw(60px);
    }

    &:after {
      margin-left: vw(60px);
    }

    &:before, &:after {
      flex: 1;
      height: 1px;
      background-color: #fcf4e7;
      content: '';
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      font-size: zoom(40px);
      line-height: zoom(45px);

      &:before, &:after {
        display: none;
      }
    }
  }

  .tab-1 {
    padding-top: vw(70px);

    @include media-breakpoint-down(md) {
      padding-top: zoom(70px);
    }

    .btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 vw(39px);

      @include media-breakpoint-down(md) {
        display: block;
        padding: 0;
      }

      .btn {
        display: flex;
        flex: unset;
        justify-content: center;
        align-items: center;
        width: vw(594px);
        height: vw(164px);
        font-weight: 400;
        font-size: vw(40px);
        font-family: HaenamFont;
        letter-spacing: -0.03em;
        color: #000;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &.disabled {
          opacity: .3;
          cursor: not-allowed;
          pointer-events: none;
        }

        &:not(:last-child) {
          margin-right: vw(54px);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          height: zoom(164px);
          margin: 0;
          font-size: zoom(40px);

          &:not(:last-child) {
            margin-bottom: zoom(54px);
          }
        }
      }
    }

    .title-line {
      margin-top: vw(136px);
      margin-bottom: vw(54px);

      @include media-breakpoint-down(md) {
        margin-top: zoom(136px);
        margin-bottom: zoom(54px);
      }
    }

    .desc,
    .important {
      font-family: SandollGothicNeo1;
      text-align: center;
      letter-spacing: -0.03em;
    }

    .desc {
      margin-top: 0;
      margin-bottom: vw(18px);
      font-weight: 400;
      font-size: vw(26px);
      line-height: vw(45px);
      color: #ffffff;

      @include media-breakpoint-down(md) {
        margin-bottom: zoom(18px);
        font-size: zoom(26px);
        line-height: zoom(45px);
      }
    }

    .important {
      margin-top: vw(26px);
      font-weight: 500;
      font-size: vw(20px);
      line-height: vw(45px);
      color: #fae845;

      @include media-breakpoint-down(md) {
        margin-top: zoom(26px);
        font-size: zoom(20px);
        line-height: zoom(45px);
      }
    }

    .box-card {
      margin-top: vw(130px);

      @include media-breakpoint-down(md) {
        margin-top: zoom(130px);
      }
    }

    .card {
      height: 100%;
      padding: 0 vw(27px) vw(50px);
      border-radius: vw(40px);
      background: #fbf7f4;

      @include media-breakpoint-down(md) {
        height: unset;
        padding: 0 zoom(27px) zoom(50px);
        margin-bottom: 30px;
        border-radius: zoom(40px);
      }

      .card-title {
        margin: vw(49px) 0 vw(32px);
        font-weight: 400;
        font-size: vw(32px);
        font-family: HaenamFont;
        line-height: vw(45px);
        text-align: center;
        letter-spacing: -0.03em;
        color: #000000;

        @include media-breakpoint-down(md) {
          margin: zoom(49px) 0 zoom(32px);
          font-size: zoom(32px);
          line-height: zoom(45px);
        }
      }

      .card-item {
        display: flex;
        padding: vw(19px);
        border-radius: vw(13px);
        font-weight: 400;
        font-size: vw(26px);
        line-height: vw(45px);
        letter-spacing: -0.03em;
        color: #000000;
        background: #ffffff;

        &:not(:last-child) {
          margin-bottom: vw(10px);
        }

        @include media-breakpoint-down(md) {
          padding: zoom(19px);
          border-radius: zoom(13px);
          font-size: zoom(26px);
          line-height: zoom(45px);

          &:not(:last-child) {
            margin-bottom: zoom(10px);
          }
        }

        .index {
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          width: vw(52px);
          height: vw(52px);
          margin-right: vw(20px);
          border-radius: 50%;
          font-weight: 700;
          font-size: vw(30px);
          letter-spacing: -0.03em;
          color: #fff;
          background: #61b852;

          @include media-breakpoint-down(md) {
            width: zoom(52px);
            height: zoom(52px);
            margin-right: zoom(20px);
            font-size: zoom(30px);
          }
        }
      }
    }

    .box-notice {
      display: flex;
      align-items: center;
      padding: 0 vw(30px);
      margin-top: vw(61px);

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0 zoom(30px);
        margin-top: zoom(61px);
      }

      img {
        flex-shrink: 0;
        width: vw(213px);
        height: auto;

        @include media-breakpoint-down(md) {
          width: zoom(213px);
        }
      }

      .notice-content {
        flex: 1;
        font-weight: 500;
        font-size: vw(26px);
        line-height: vw(40px);
        text-align: left;
        letter-spacing: -0.03em;
        color: #ffffff;

        @include media-breakpoint-down(md) {
          font-size: zoom(26px);
          line-height: zoom(40px);
        }

        p {
          margin: 0;
        }

        .notice-title {
          margin-bottom: vw(19px);
          font-size: vw(40px);
          line-height: vw(35px);
          color: #fae845;

          @include media-breakpoint-down(md) {
            margin-bottom: zoom(19px);
            font-size: zoom(40px);
            line-height: zoom(35px);
          }
        }
      }
    }
  }

  .tab-2 {
    .box-card {
      margin-top: vw(153px);

      @include media-breakpoint-down(md) {
        margin-top: zoom(153px);
      }
    }

    .card {
      height: 100%;
      padding: vw(30px) vw(32px) vw(70px);
      border-radius: vw(36px);
      text-align: left;
      background: #fff;
      box-shadow: vw(3px) vw(4px) vw(22px) rgba(145, 145, 145, 0.25);

      @include media-breakpoint-down(md) {
        height: unset;
        padding: zoom(30px) zoom(32px) zoom(70px);
        margin-bottom: 30px;
        border-radius: zoom(36px);
        box-shadow: zoom(3px) zoom(4px) zoom(22px) rgba(145, 145, 145, 0.25);
      }

      .img {
        width: vw(208px);
        margin-bottom: vw(50px);

        @include media-breakpoint-down(md) {
          width: zoom(208px);
          margin-bottom: zoom(50px);
        }
      }

      .step-index {
        font-weight: 400;
        font-size: vw(24px);
        letter-spacing: -0.02em;
        color: #498863;

        @include media-breakpoint-down(md) {
          font-size: zoom(24px);
        }
      }

      .step-name {
        margin-bottom: vw(10px);
        border-bottom: 1px solid #231f20;
        font-size: vw(36px);
        font-family: HaenamFont;
        line-height: vw(76px);
        letter-spacing: -0.02em;
        color: #231f20;

        @include media-breakpoint-down(md) {
          margin-bottom: zoom(10px);
          font-size: zoom(36px);
          line-height: zoom(76px);
        }
      }

      .card-desc {
        margin: vw(10px) 0 0;
        font-weight: 700;
        font-size: vw(24px);
        line-height: vw(40px);
        color: #231f20;

        @include media-breakpoint-down(md) {
          margin: zoom(10px) 0 0;
          font-size: zoom(24px);
          line-height: zoom(40px);
        }
      }
    }
  }

  .tab-2,
  .tab-3 {
    .title-line {
      margin-top: vw(160px);
      margin-bottom: vw(63px);

      @include media-breakpoint-down(md) {
        margin-top: zoom(160px);
        margin-bottom: zoom(63px);
      }
    }

    .desc {
      font-size: vw(20px);
      line-height: vw(45px);
      text-align: center;
      letter-spacing: -0.03em;
      color: #fff;

      @include media-breakpoint-down(md) {
        font-size: zoom(20px);
        line-height: zoom(45px);
      }
    }
  }

  .tab-3 {
    .important {
      font-weight: 500;
      color: #fae845;
    }

    .box-card {
      position: relative;
      z-index: 2;
      padding: vw(39px) vw(10px) vw(36px);
      margin-top: vw(111px);
      border-radius: vw(36px);
      background: #fcf4e7;

      @include media-breakpoint-down(md) {
        padding: zoom(39px) zoom(10px) zoom(36px);
        margin-top: zoom(111px);
        border-radius: zoom(36px);
      }

      .box-card-title {
        font-weight: 400;
        font-size: vw(36px);
        font-family: HaenamFont;
        line-height: vw(77px);
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;

        @include media-breakpoint-down(md) {
          font-size: zoom(36px);
          line-height: zoom(77px);
        }
      }

      .box-card-subtitle {
        margin-bottom: vw(63px);
        font-weight: 400;
        font-size: vw(40px);
        font-family: HaenamFont;
        line-height: vw(45px);
        text-align: center;
        letter-spacing: -0.03em;
        color: #498863;

        @include media-breakpoint-down(md) {
          margin-bottom: zoom(63px);
          font-size: zoom(40px);
          line-height: zoom(45px);
        }
      }

      .card {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: vw(60px);

        &.border-right {
          border-right: 1px solid #d2d2d2;
        }

        @include media-breakpoint-down(md) {
          height: unset;
          padding-top: zoom(60px);

          &.border-right {
            border-right: none;
          }
        }

        .box-card-content {
          position: relative;
          width: vw(414px);

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .img {
          width: 100%;
          height: vw(250px);
          object-fit: cover;

          @include media-breakpoint-down(md) {
            height: auto;
          }
        }

        .card-name {
          margin: vw(15px) 0 0;
          font-weight: 400;
          font-size: vw(26px);
          font-family: HaenamFont;
          line-height: vw(45px);
          text-align: center;
          letter-spacing: -0.03em;
          color: #66420c;

          &.important {
            margin-top: 0;
            font-size: vw(20px);
            line-height: 1.2;
            color: #498863;
          }

          @include media-breakpoint-down(md) {
            margin: zoom(15px) 0 0;
            font-size: zoom(26px);
            line-height: zoom(45px);

            &.important {
              font-size: zoom(20px);
            }
          }
        }

        .tag {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: vw(101px);
          height: vw(101px);
          border-radius: 50%;
          font-weight: 400;
          font-size: vw(26px);
          font-family: HaenamFont;
          letter-spacing: -0.02em;
          color: #fcf4e7;
          background: #498863;
          transform: translate(25%, -50%);

          @include media-breakpoint-down(md) {
            width: zoom(101px);
            height: zoom(101px);
            font-size: zoom(26px);
            transform: translate(0, -50%);
          }
        }
      }
    }
  }

  .tab-4 {
    .tab-title {
      margin: vw(101px) 0 vw(58px);
      font-weight: 400;
      font-size: vw(40px);
      font-family: HaenamFont;
      line-height: vw(45px);
      text-align: center;
      letter-spacing: -0.03em;
      color: #fcf4e7;

      @include media-breakpoint-down(md) {
        margin: zoom(101px) 0 zoom(58px);
        font-weight: 400;
        font-size: zoom(40px);
        line-height: zoom(45px);
      }
    }

    .faq-tabs {
      position: relative;
      z-index: 2;
      display: flex;
      padding: vw(80px) 0;
      border-radius: vw(36px);
      background: #ffffff;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 10px 0 20px;
        border-radius: zoom(36px);
      }

      .box-tab-items {
        flex-shrink: 0;
        flex-direction: column;
        width: vw(224px);
        padding-bottom: vw(30px);
        border-right: 1px solid #000;

        @include media-breakpoint-down(md) {
          flex-direction: row;
          width: 100%;
          padding-bottom: 0;
          border-right: none;
          overflow-x: auto;
        }

        .tab-item {
          flex: unset;
          margin-top: vw(30px);
          font-weight: 400;
          font-size: vw(20px);
          font-family: SandollGothicNeo1;
          line-height: vw(45px);
          text-align: center;
          letter-spacing: -0.03em;
          color: #727272;

          &.active {
            color: #498863;
          }

          @include media-breakpoint-down(md) {
            padding: zoom(30px);
            margin-top: 0;
            font-size: zoom(20px);
            line-height: zoom(45px);
            white-space: nowrap;
          }
        }
      }

      .app-tab-content {
        flex: 1;
        box-sizing: border-box;
        padding: vw(30px) vw(40px) 0;

        @include media-breakpoint-down(md) {
          padding: 0 10px;
        }
      }

      .faq-collapse {
        margin-bottom: vw(22px);

        @include media-breakpoint-down(md) {
          margin-bottom: zoom(22px);
        }
      }

      .faq-collapse-trigger {
        display: flex;
        align-items: center;
        padding: vw(10px) 0;
        border-bottom: 1px solid #d2d2d2;
        font-weight: 400;
        font-family: SandollGothicNeo1;

        @include media-breakpoint-down(md) {
          padding: zoom(10px) 0;
        }

        .collapse-symbol {
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          width: vw(60px);
          height: vw(60px);
          margin-right: vw(35px);
          margin-left: vw(9px);
          border-radius: 50%;
          font-size: vw(24px);
          color: #fff;
          background: #498863;

          @include media-breakpoint-down(md) {
            width: zoom(60px);
            height: zoom(60px);
            margin-right: zoom(35px);
            margin-left: zoom(9px);
            font-size: zoom(24px);
          }
        }

        .collapse-title {
          flex: 1;
          font-size: vw(24px);
          font-family: SandollGothicNeo1;
          color: #231f20;

          @include media-breakpoint-down(md) {
            font-size: zoom(24px);
          }
        }

        .indicator {
          flex-shrink: 0;
          width: vw(14px);
          height: auto;
          margin-right: vw(9px);
          margin-left: vw(20px);
          transition: transform .3s;

          @include media-breakpoint-down(md) {
            width: zoom(14px);
            margin-right: zoom(9px);
            margin-left: zoom(20px);
          }
        }

        &.active {
          .indicator {
            transform: rotate(180deg);
          }
        }
      }

      .faq-content {
        padding-top: vw(12px);
        padding-right: calc(vw(9px) + vw(60px) + vw(35px));
        padding-bottom: vw(67px);
        // 和Q.对齐
        padding-left: calc(vw(9px) + vw(60px) + vw(35px));
        font-weight: 400;
        font-size: vw(20px);
        line-height: vw(35px);
        letter-spacing: -0.02em;

        @include media-breakpoint-down(md) {
          padding-top: zoom(12px);
          padding-right: 0;
          padding-bottom: zoom(67px);
          padding-left: 0;
          font-size: zoom(20px);
          line-height: zoom(35px);
        }

        .important {
          color: #498863;
        }

        .box-img-card {
          margin-top: vw(52px);

          @include media-breakpoint-down(md) {
            margin-top: zoom(52px);
          }

          .img-card {
            display: flex;
            align-items: center;
            margin-bottom: vw(35px);

            @include media-breakpoint-down(md) {
              margin-bottom: zoom(35px);
            }

            img {
              flex-shrink: 0;
              width: vw(160px);
              height: vw(160px);
              margin-right: vw(31px);
              object-fit: cover;

              @include media-breakpoint-down(md) {
                width: zoom(160px);
                height: zoom(160px);
                margin-right: zoom(31px);
              }
            }

            .img-card-content {
              .card-title {
                margin-bottom: vw(6px);
                font-weight: 700;
                font-size: vw(36px);
                line-height: vw(35px);
                letter-spacing: -0.02em;
                color: #498863;

                @include media-breakpoint-down(md) {
                  margin-bottom: zoom(6px);
                  font-size: zoom(36px);
                  line-height: zoom(35px);
                }
              }

              .card-desc {
                font-weight: 400;
                font-size: vw(20px);
                line-height: vw(35px);
                letter-spacing: -0.02em;
                color: #515151;

                @include media-breakpoint-down(md) {
                  font-size: zoom(20px);
                  line-height: zoom(35px);
                }
              }
            }
          }
        }
      }
    }
  }
}
