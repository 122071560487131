$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .offline-collection-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: vw(777px);
    overflow: hidden;

    @include media-breakpoint-down(md) {
      height: unset;
    }

    .map-wrapper {
      width: 49%;
      height: 100%;

      @include media-breakpoint-down(md) {
        width: 100%;
        height: vwMobile(436px);
        padding: 0 vwMobile(5px);
        margin-top: vwMobile(4px);
      }

      :deep(.map) {
        height: 100%;
        border-radius: vw(12px);
      }
    }

    .search-wrapper {
      display: flex;
      flex-direction: column;
      width: 49%;
      height: 100%;

      @include media-breakpoint-down(md) {
        width: 100%;
        height: unset;
      }

      .search-input {
        position: relative;
        width: 100%;
        height: vw(90px);
        border-radius: vw(12px);
        background-color: #f2f2f2;

        .input {
          width: 100%;
          height: 100%;
          padding: 0 vw(51px) 0 vw(23px);
          border: none;
          font-weight: 500;
          font-size: vw(24px);
          color: #000000;
          background-color: transparent;

          &::placeholder {
            font-size: vw(24px);
            color: #525355;
          }
        }

        .icon {
          position: absolute;
          top: vw(29px);
          right: vw(21px);
          width: vw(35px);
          cursor: pointer;
        }
      }

      .search-list {
        flex: 1;
        width: 100%;
        margin-top: vw(38px);
        overflow-y: auto;

        @include media-breakpoint-down(md) {
          max-height: vwMobile(300px);
          padding: 0 vwMobile(5px) 0 vwMobile(10px);
          overflow: auto;
        }

        .accordion-item {
          padding: vw(34px) vw(35px) vw(34px) vw(26px);
          border: none;
          border-bottom: 1px solid #9b9b9b;

          @include media-breakpoint-down(md) {
            padding: vwMobile(15px) vwMobile(18px) vwMobile(18px) vwMobile(12px);
          }
        }

        .title-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            font-weight: 700;
            font-size: vw(36px);
            line-height: vw(49px);
            color: #000;

            @include media-breakpoint-down(md) {
              font-weight: 700;
              font-size: vwMobile(14px);
              line-height: vwMobile(19px);
            }
          }

          .icon {
            width: vw(31px);
            height: vw(31px);

            @include media-breakpoint-down(md) {
              width: vwMobile(18px);
              height: vwMobile(18px);
            }
          }
        }

        .info-container {
          padding-bottom: vw(56px);
          margin-top: vw(35px);

          @include media-breakpoint-down(md) {
            padding-bottom: vwMobile(42px);
            margin-top: vwMobile(18px);
          }

          .info-item {
            display: flex;
            align-items: flex-start;

            &:not(:first-child) {
              margin-top: vw(12px);

              @include media-breakpoint-down(md) {
                margin-top: vwMobile(12px);
              }
            }
          }

          .icon {
            flex-shrink: 0;
            width: vw(24px);
            margin-top: vw(4px);
            margin-right: vw(15px);

            &.small-icon {
              margin-top: vw(7px);
            }

            @include media-breakpoint-down(md) {
              width: vwMobile(13px);
              margin-top: vwMobile(4px);
              margin-right: vwMobile(6px);

              &.small-icon {
                margin-top: vwMobile(6px);
              }
            }
          }

          .text {
            flex: 1;
            font-size: vw(24px);
            font-family: AppleSDGothicNeo;
            line-height: 1.8;
            color: #525355;

            @include media-breakpoint-down(md) {
              font-size: vwMobile(14px);
            }
          }
        }
      }
    }

    .mobile-search-wrapper {
      width: 100%;
      padding: 0 vwMobile(5px);

      .mobile-search-input {
        position: relative;
        width: 100%;
        height: vwMobile(52px);
        border-radius: vwMobile(12px);
        background-color: #f2f2f2;

        .input {
          width: 100%;
          height: 100%;
          padding: 0 vwMobile(63px) 0 vwMobile(31px);
          border: none;
          font-weight: 500;
          font-size: vwMobile(16px);
          color: #000000;
          background-color: transparent;

          &::placeholder {
            font-size: vwMobile(12px);
            color: #525355;
          }
        }

        .icon {
          position: absolute;
          top: vw(29px);
          right: vw(21px);
          width: vw(35px);

          @include media-breakpoint-down(md) {
            top: vwMobile(10px);
            right: vwMobile(23px);
            width: vwMobile(30px);
          }
        }
      }
    }

    &.ja {
      .info-container {
        .info-item {
          .text {
            font-family: "hiragino-kaku-gothic-pron", sans-serif !important;
          }
        }
      }
    }
  }
