$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-4 {
    @include media-breakpoint-up(md) {
      width: vw(970px);
      margin: 0 auto;
    }
  }

  .title,
  .desc,
  .box-card {
    padding: 0 vwMobile(21px);

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .title {
    margin: vwMobile(28px) 0 vwMobile(15px);
    font-size: vwMobile(18px);
    font-family: ImcreSoojin;
    line-height: 3.2;
    text-align: center;
    color: $black;

    @include media-breakpoint-up(md) {
      margin: vw(133px) 0 vw(47px);
      font-size: vw(46px);
      line-height: 1.26;
    }

    .strong {
      color: $red;
    }
  }

  .desc {
    margin-bottom: vwMobile(106px);
    font-size: vwMobile(14px);
    line-height: 1.71;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(154px);
      font-size: vw(20px);
      line-height: 2.1;
    }

    .strong {
      font-family: AppleSDGothicNeoB;
    }
  }

  .card {
    position: relative;
    padding: vwMobile(57px) vwMobile(50px) vwMobile(28px);
    margin-top: vwMobile(88px);
    border-radius: vwMobile(12px);
    font-size: vwMobile(18px);
    font-family: ImcreSoojin;
    color: #fff;

    @include media-breakpoint-up(md) {
      padding: vw(79px) vw(37px) vw(42px);
      margin-top: 0;
      border-radius: vw(12px);
      font-size: vw(20px);
    }

    .card-title {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      width: vwMobile(124px);
      height: vwMobile(124px);
      margin: 0;
      border-radius: vwMobile(124px);
      font-size: vwMobile(18px);
      text-align: center;
      transform: translate(-50%, -38%);

      @include media-breakpoint-down(md) {
        line-height: 3.5;
      }

      @include media-breakpoint-up(md) {
        width: vw(172px);
        height: vw(172px);
        border-radius: vw(86px);
        font-size: vw(36px);
      }

      span {
        line-height: 2.61;
      }
    }

    .card-image {
      position: relative;
      z-index: 2;
      width: 100%;
      margin-bottom: vwMobile(28px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(77px);
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .card-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: vwMobile(16px);
      line-height: 1.625;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: vw(20px);
        line-height: vw(36px);
      }

      .desc-title {
        font-size: vwMobile(16px);

        @include media-breakpoint-up(md) {
          font-size: vw(22px);
        }
      }

      .line {
        width: vwMobile(230px);
        height: 2px;
        margin: vwMobile(15px);
        background: #fff;

        @include media-breakpoint-up(md) {
          width: vw(317px);
          margin: vw(30px) 0;
        }
      }
    }
  }

  .box-card {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;

      .card {
        flex-shrink: 0;
        width: vw(471px);
      }
    }
  }

  .card-1 {
    background: #cf2526;

    .card-title {
      background: #cf2526;
    }

    .card-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: vwMobile(240px);
      border-radius: 50%;
      background: #fff;

      @include media-breakpoint-up(md) {
        width: vw(330px);
        height: vw(330px);
        margin: 0 auto vw(77px);
      }

      .img-tip {
        position: absolute;
        top: 0;
        left: 0;
        width: vw(298px);
        transform: translate(-60%, -30%);
      }

      img {
        width: vwMobile(120px);

        @include media-breakpoint-up(md) {
          width: vw(164px);
        }
      }
    }

    .card-desc {
      .strong {
        color: #ffb538;
      }

      .desc-title {
        @include media-breakpoint-up(md) {
          font-size: vw(30px);
        }
      }
    }
  }

  .card-2 {
    padding-right: vwMobile(12px);
    padding-left: vwMobile(12px);
    background: #ffb538;

    @include media-breakpoint-up(md) {
      padding-right: vw(18px);
      padding-left: vw(18px);
    }

    .card-image {
      padding-right: vwMobile(28px);
      padding-left: vwMobile(28px);

      @include media-breakpoint-up(md) {
        padding: 0;
        margin: 0 auto vw(77px);
        text-align: center;

        img {
          width: auto;
          height: vw(330px);
        }
      }
    }

    .card-title {
      background: #ffb538;
    }

    .card-desc {
      .strong {
        color: #f62834;
      }
    }
  }

  .page-desc {
    padding: 0 0 vwMobile(35px);
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 0 0 vw(75px);
    }

    .title {
      margin-top: vwMobile(59px);
      margin-bottom: vwMobile(20px);
      font-size: vwMobile(26px);
      font-family: ImcreSoojin;
      line-height: 1.6;
      color: #000;

      @include media-breakpoint-up(md) {
        margin-top: vw(134px);
        margin-bottom: vw(42px);
        font-size: vw(46px);
      }

      .strong {
        color: #f62834;
      }
    }

    img {
      width: vwMobile(134px);
      margin-bottom: vwMobile(20px);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .content {
      font-size: vwMobile(12px);
      line-height: 1.66;

      @include media-breakpoint-up(md) {
        font-size: vw(20px);
        line-height: 1.75;
      }

      .strong {
        font-family: AppleSDGothicNeoB;
      }

      .color {
        display: flex;
        flex-direction: column;
        color: #f62834;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }

  .carousel-wrapper {
    margin-bottom: vwMobile(70px);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(110px);
    }

    .carousel-content {
      position: relative;
      width: vwMobile(335px);
      height: vwMobile(207px);
      margin: auto;

      @include media-breakpoint-up(md) {
        width: vw(770px);
        height: vw(477px);
      }

      :deep(.carousel) {
        border-radius: vwMobile(6px);
        overflow: hidden;

        @include media-breakpoint-up(md) {
          border-radius: vw(12px);
        }
      }

      .control {
        position: absolute;
        z-index: 1;
        top: 50%;
        width: vwMobile(30px);
        height: vwMobile(30px);
        transform: translateY(-50%);
        cursor: pointer;

        @include media-breakpoint-up(md) {
          width: vw(50px);
          height: vw(50px);
        }

        &.left {
          left: vwMobile(-15px);

          @include media-breakpoint-up(md) {
            left: vw(-100px);
          }
        }

        &.right {
          right: vwMobile(-15px);

          @include media-breakpoint-up(md) {
            right: vw(-100px);
          }
        }
      }
    }

    .thumbnail-wrapper {
      width: vwMobile(335px);
      margin: vwMobile(13px) auto 0;
      overflow-x: auto;

      @include media-breakpoint-up(md) {
        width: vw(770px);
        margin: vw(50px) auto 0;
      }

      .item {
        position: relative;
        flex-shrink: 0;
        margin: 0 vwMobile(1px);
        border-radius: vwMobile(3px);
        overflow: hidden;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          margin: 0 vw(3px);
          border-radius: vw(12px);
        }

        &, .thumbnail, .mask {
          width: vwMobile(57px);
          height: vwMobile(38px);

          @include media-breakpoint-up(md) {
            width: vw(153px);
            height: vw(103px);
          }
        }

        .thumbnail {
          object-fit: cover;
        }

        .mask {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          background: rgba($color: #000000, $alpha: 0.5);
        }
      }
    }
  }
