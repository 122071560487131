$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .page-content {
    background-color: #ffc521;

    .box-container {
      position: relative;
      padding: vw(144px) 0 vw(153px);
      background-color: #f6f6f6;

      @include media-breakpoint-down(md) {
        padding: vwMobile(43px) 0 vwMobile(57px);
      }

      .box-icon {
        display: block;
        width: vw(150px);
        margin: 0 auto;

        @include media-breakpoint-down(md) {
          width: vwMobile(53px);
        }
      }

      .title {
        margin-top: vw(82px);
        font-size: vw(58px);
        font-family: SCDream8;
        line-height: 1.19;
        text-align: center;
        color: #006db7;

        @include media-breakpoint-down(md) {
          margin-top: vwMobile(25px);
          font-size: vwMobile(24px);
          line-height: 1.16;
        }
      }

      .wave-icon {
        position: absolute;
        top: vw(110px);
        left: vw(95px);
        width: vw(310px);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .mian-container {
      padding-bottom: vw(220px);

      @include media-breakpoint-down(md) {
        padding-bottom: vwMobile(58px);
      }

      .title {
        margin: vw(105px) auto vw(109px);
        font-size: vw(58px);
        font-family: SCDream8;
        line-height: vw(69px);
        text-align: center;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          margin: vwMobile(55px) auto vwMobile(34px);
          font-size: vwMobile(24px);
          line-height: vwMobile(29px);
        }
      }

      .board {
        width: vw(1320px);
        padding: vw(87px) vw(69px) vw(103px);
        margin: 0 auto;
        border-radius: vw(36px);
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

        @include media-breakpoint-down(md) {
          width: vwMobile(345px);
          padding: vwMobile(29px) 0 vwMobile(58px);
        }

        .board-title {
          font-size: vw(58px);
          font-family: SCDream8;
          line-height: vw(69px);
          text-align: center;
          color: #006db7;

           @include media-breakpoint-down(md) {
            font-size: vwMobile(24px);
            line-height: vwMobile(29px);
          }
        }

        .board-desc {
          margin-top: vw(51px);
          font-size: vw(26px);
          font-family: Noto Sans KR;
          line-height: vw(40px);
          text-align: center;
          color: #3b393a;

          @include media-breakpoint-down(md) {
            font-size: vwMobile(14px);
            line-height: vwMobile(24px);
          }

          &.board-desc-2 {
            margin-top: vw(41px);

            @include media-breakpoint-down(md) {
              margin-top: vwMobile(9px);
            }
          }
        }

        .banner {
          display: block;
          width: vw(867px);
          margin: vw(113px) auto vw(47px);

          @include media-breakpoint-down(md) {
            width: vwMobile(287px);
            margin: vwMobile(49px) auto vwMobile(34px);
          }
        }

        :deep(.emart-btn) {
          margin: 0 auto;
        }

        .emart-btn2 {
          margin-top: vw(110px);

          @include media-breakpoint-down(md) {
            margin-top: vwMobile(39px);
          }
        }

        .line {
          width: 100%;
          height: 1px;
          margin: vw(103px) auto vw(92px);
          background-color: #b1b1b1;

          @include media-breakpoint-down(md) {
            width: vwMobile(309px);
            height: vwMobile(1px);
            margin: vwMobile(96px) auto vwMobile(33px);
          }
        }

        .banner-box {
          display: flex;
          justify-content: space-between;
          width: vw(1150px);
          margin: vw(62px) auto 0;

          @include media-breakpoint-down(md) {
            width: vwMobile(327px);
            margin: vwMobile(16px) auto 0;
          }

          .image {
            width: vw(370px);
            height: vw(370px);
            border-radius: 50%;
            object-fit: cover;

            @include media-breakpoint-down(md) {
              width: vwMobile(105px);
              height: vwMobile(105px);
            }
          }
        }
      }
    }
  }
