$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-faq {
    padding: vwMobile(43px) vwMobile(8px) vwMobile(55px) vwMobile(12px);
    background: #f4f4f4;

    @include media-breakpoint-up(md) {
      padding: vw(110px) 0 vw(156px);
    }

    > .container {
      width: 100%;
      max-width: 100%;
      padding: 0;

      @include media-breakpoint-up(md) {
        max-width: vw(1320px);
      }
    }
  }

  .section-title {
    margin-bottom: vwMobile(33px);
    font-size: vwMobile(24px);
    font-family: SCDream6;
    line-height: 1.46;
    text-align: center;
    letter-spacing: -0.02em;
    color: #006db7;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(43px);
      font-size: vw(58px);
      font-family: SCDream8;
    }
  }

  .faq-tabs {
    :deep(.box-tab-items) {
      display: flex;
      justify-content: center;
      margin-bottom: vwMobile(47px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(105px);
      }

      .tab-item {
        flex: unset;
        justify-content: center;

        &:not(:last-child) {
          margin-right: vwMobile(5px);

          @include media-breakpoint-up(md) {
            margin-right: vw(90px);
          }
        }
      }
    }

    .faq-tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: unset;
      height: vwMobile(34px);
      padding: 0 vwMobile(25px);
      border: 1px solid #fff;
      border-radius: vwMobile(18px);
      font-weight: 500;
      font-size: vwMobile(14px);
      letter-spacing: -0.02em;
      color: #006db7;
      background: #fff;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: vw(200px);
        height: vw(70px);
        padding: 0;
        border-radius: vw(35px);
        font-size: vw(24px);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }

      &.active {
        border-color: #006db7;
        color: white;
        background: #006db7;
        cursor: default;
      }
    }
  }

  .app-tab-content {
    @include media-breakpoint-up(md) {
      padding: vw(73px) vw(134px) vw(133px) vw(106px);
      border-radius: vw(36px);
      background: #fff;
    }
  }

  .faq-collapse-trigger {
    display: flex;
    align-items: center;
    padding: vwMobile(10px) vwMobile(10px) vwMobile(10px) 0;
    border-bottom: 1px solid #d2d2d2;
    font-weight: 700;
    font-size: vwMobile(14px);
    color: #231f20;

    @include media-breakpoint-up(md) {
      padding: vw(11px) vw(15px) vw(11px) vw(9px);
      margin-top: vw(21px);
      font-weight: 500;
      font-size: vw(24px);
    }

    .indicator {
      width: vwMobile(10px);
      margin-left: vwMobile(15px);
      transition: transform .3s;

      @include media-breakpoint-up(md) {
        width: vw(15px);
        margin-left: vw(15px);
      }
    }

    .collapse-title {
      flex: 1;
    }

    .collapse-symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(30px);
      height: vwMobile(30px);
      margin-right: vwMobile(15px);
      border-radius: 50%;
      font-size: vwMobile(16px);
      color: #fff;
      background: #ffc521;

      @include media-breakpoint-up(md) {
        width: vw(65px);
        height: vw(65px);
        margin-right: vw(35px);
        font-size: vw(24px);
      }
    }

    &.active {
      .indicator {
        transform: rotate(180deg);
      }
    }
  }

  .faq-content {
    padding-top: vwMobile(9px);
    padding-bottom: vwMobile(20px);
    font-size: vwMobile(12px);
    font-family: AppleSDGothicNeo;
    line-height: 1.75;
    color: #515151;

    @include media-breakpoint-up(md) {
      padding-top: vw(10px);
      padding-right: vw(15px + 15px + 15px);
      padding-bottom: vw(25px);
      padding-left: vw(9px + 65px + 35px);
      font-size: vw(20px);
      letter-spacing: -0.02em;
    }
  }
