$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.quiz-modal {
  --modal-min-height: #{vwMobile(670px)};

  @include media-breakpoint-up(md) {
    --modal-min-height: #{vw(660px)};
  }
}

.step-1,
.step-error {
  :deep(.cocacola-s5-modal-content) {
    .cocacola-s5-modal-title {
      margin-top: vwMobile(31px);

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .box-hint {
    position: relative;
    margin: vwMobile(55px) vwMobile(46px) 0;
    border: vwMobile(5px) solid #b01614;
    border-radius: vwMobile(8px);
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      margin: vw(32px) vw(85px) 0;
      border-width: vw(5px);
      border-radius: vw(8px);
    }

    .bottle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: vwMobile(96px);
      transform: translate(50%, 45%);

      @include media-breakpoint-up(md) {
        width: vw(96px);
      }
    }

    .box-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: vwMobile(40px);
      font-size: vwMobile(24px);
      color: #fff;

      @include media-breakpoint-up(md) {
        height: vw(52px);
        font-size: vw(24px);
      }
    }

    .box-desc {
      padding: vwMobile(29px) 0 vwMobile(37px);
      border-bottom-right-radius: vwMobile(8px);
      border-bottom-left-radius: vwMobile(8px);
      font-size: vwMobile(18px);
      line-height: 1.5;
      text-align: center;
      background-color: #fff;

      @include media-breakpoint-up(md) {
        padding: vw(32px) 0 vw(40px);
        border-bottom-right-radius: vw(8px);
        border-bottom-left-radius: vw(8px);
        font-size: vw(24px);
      }
    }
  }

  .bottle-bottom {
    position: absolute;
    bottom: 0;
    left: vwMobile(46px);
    width: vwMobile(92px);
    transform: translate(0, -30%);

    @include media-breakpoint-up(md) {
      left: vw(120px);
      width: vw(92px);
      transform: translate(0, -50%);
    }
  }

  .footer-btn {
    position: relative;
    z-index: 2;
  }
}

.step-2 {
  ul {
    list-style: none;
    padding: 0 vwMobile(30px);
    margin: vwMobile(15px) 0 0;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: vw(15px);
      padding: 0 vw(55px);
      margin: vw(32px) 0 0;
    }

    li {
      display: flex;
      align-items: center;
      padding: vwMobile(10px) vwMobile(37px) vwMobile(10px) vwMobile(27px);
      margin-bottom: vwMobile(5px);
      border-radius: vwMobile(8px);
      color: #303030;
      background-color: #fafafa;
      transition: background-color .3s;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
        justify-content: center;
        padding: vw(21px) 0 vw(23px);
        margin-bottom: 0;
        border-radius: vw(8px);
      }

      img {
        width: vwMobile(95px);
        height: vwMobile(95px);
        margin-left: auto;

        @include media-breakpoint-up(md) {
          width: vw(95px);
          height: vw(95px);
          margin-bottom: vw(32px);
          margin-left: unset;
        }
      }

      &.active {
        color: #fff;
        background-color: #b01614;
      }
    }
  }
}

.step-error {
  .box-hint {
    position: relative;
    margin: vwMobile(24px) vwMobile(38px) 0;
    border: vwMobile(5px) solid #b01614;
    border-radius: vwMobile(8px);
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      margin: vw(13px) vw(45px) 0;
      border-width: vw(5px);
      border-radius: vw(8px);
    }

    .box-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: vwMobile(40px);
      font-size: vwMobile(24px);
      color: #fff;

      @include media-breakpoint-up(md) {
        height: vw(40px);
        font-size: vw(24px);
      }
    }

    .box-desc {
      padding: vwMobile(15px) 0 vwMobile(24px);
      border-bottom-right-radius: vwMobile(8px);
      border-bottom-left-radius: vwMobile(8px);
      font-weight: 400;
      font-size: vwMobile(14px);
      line-height: 1.5;
      text-align: center;
      background-color: #fff;

      .desc {
        &:not(:last-child) {
          margin-bottom: vwMobile(15px);

          @include media-breakpoint-up(md) {
            margin-bottom: vw(15px);
          }
        }
      }

      @include media-breakpoint-up(md) {
        padding: vw(14px) 0 vw(9px);
        border-bottom-right-radius: vw(8px);
        border-bottom-left-radius: vw(8px);
        font-size: vw(17px);
      }
    }
  }
}
