$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-6 {
    padding-top: vwMobile(65px);

    @include media-breakpoint-up(md) {
      padding-top: vw(105px);
    }
  }

  .section-title {
    margin-bottom: vwMobile(30px);
    font-size: vwMobile(18px);
    font-family: ImcreSoojin;
    line-height: 1.67;
    text-align: center;
    letter-spacing: -0.02em;
    color: $red;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(50px);
      font-size: vw(46px);
    }
  }

  .section-desc {
    padding: 0 vwMobile(12px);
    margin: 0;
    font-size: vwMobile(12px);
    line-height: 2;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
      line-height: 1.91;
    }
  }

  .container-steps {
    background: #fdb515;
  }

  .box-steps {
    display: flex;
    padding-top: vwMobile(50px);
    padding-bottom: vwMobile(46px);
    margin-top: vwMobile(29px);
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      padding-top: vw(143px);
      padding-bottom: vw(166px);
      margin-top: vw(109px);
    }
  }

  .step-card {
    flex-shrink: 0;
    width: vwMobile(330px);
    height: vwMobile(250px);
    padding-right: vwMobile(30px);
    padding-left: vwMobile(30px);
    margin-right: vwMobile(11px);
    border-radius: vwMobile(36px);
    background: #fff;
    box-shadow: vwMobile(3px) vwMobile(4px) vwMobile(22px) rgba(145, 145, 145, 0.25);

    @include media-breakpoint-up(md) {
      flex: 1;
      height: unset;
      padding-right: vw(39px);
      padding-bottom: vw(25px);
      padding-left: vw(39px);
      margin-right: vw(11px);
      border-radius: vw(36px);
      box-shadow: vw(3px) vw(4px) vw(22px) rgba(145, 145, 145, 0.25);

      &:not(:last-child) {
        margin-right: vw(30px);
      }
    }

    @include media-breakpoint-down(md) {
      &:first-child {
        margin-left: vwMobile(16px);
      }

      &:last-child {
        margin-right: vwMobile(16px);
      }
    }


    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: vwMobile(22px);
      padding-bottom: vwMobile(22px);

      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: vw(33px);
        padding-bottom: vw(16px);
      }

      .step-info {
        font-family: ImcreSoojin;
        letter-spacing: -0.02em;

        .step-index {
          font-size: vwMobile(14px);
          color: $red;

          @include media-breakpoint-up(md) {
            font-size: vw(24px);
            line-height: 1.1;
          }
        }

        .step-name {
          margin-top: vwMobile(7px);
          font-size: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-top: vw(7px);
            font-size: vw(36px);
          }
        }
      }

      img {
        width: vwMobile(125px);
        height: auto;

        @include media-breakpoint-up(md) {
          width: vw(184px);
          margin-bottom: vw(44px);
        }
      }
    }

    .step-content {
      padding-top: vwMobile(15px);
      border-top: 1px solid #231f20;
      font-size: vwMobile(14px);
      line-height: 1.4;

      @include media-breakpoint-up(md) {
        padding-top: vw(19px);
        font-size: vw(24px);
        line-height: 1.66;
      }
    }
  }
