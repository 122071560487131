$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .page-subtitle {
    margin: 0;
    text-align: center;

    .strong {
      color: $primary-darker;
    }
  }

  .box-title {
    text-align: center;

    &:not(:first-child) {
      margin-top: ocZoom(54px);
    }

    h2 {
      margin-bottom: ocZoom(10px);
      font-weight: bold;
      font-size: ocZoom(16px);
    }

    p {
      line-height: 2.14;

      .primary {
        color: $primary;
      }
    }
  }

  .box-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: ocZoom(12px);

    &.flex-start {
      align-items: flex-start;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      box-sizing: content-box;
      width: ocZoom(16px);
      height: auto;
      padding: ocZoom(13px);
      transform: translateY(-50%);

      &.icon-tips {
        position: static;
        top: 0;
        padding: 0;
        transform: translateY(0);
      }

      path {
        fill: #ccc;
      }
    }

    .form-control {
      height: ocZoom(40px);
      padding-right: ocZoom(42px);
    }

    .btn {
      flex: 1;
    }

    .index {
      min-width: ocZoom(22px);
    }
  }

  .btn-submit {
    margin-top: ocZoom(54px);
  }

  .btn-block {
    display: block;
    width: 100%;
    margin-top: ocZoom(32px);
  }

