$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import './vars.scss';
@import './fonts.scss';

.page {
  font-family: Noto Sans KR;

  @include media-breakpoint-down(md) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

:deep(strong), :deep(.strong) {
  font-weight: 700;
}

.banner {
  padding-top: vw(114px);
  padding-bottom: vw(100px);
  background: no-repeat bottom url('~./images/banner-bg.png');
  background-size: cover;

  img {
    vertical-align: middle;
  }

  .icon {
    width: vw(200px);

    @include media-breakpoint-down(md) {
      width: vwMobile(78px);
    }
  }

  .text {
    width: vw(647px);
    margin-top: vw(100px);
    margin-bottom: vw(111px);

    @include media-breakpoint-down(md) {
      width: vwMobile(237px);
      margin-top: vwMobile(52px);
      margin-bottom: vwMobile(64px);
    }
  }
}

.nav-wrapper-scroll {
  text-align: center;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin: auto;
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }
}

.nav-wrapper {
  display: inline-flex;
  justify-content: center;


  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: vw(225px);
    height: vw(110px);
    padding: 0 vw(10px);
    margin: 0 vw(4px);
    border-radius: vw(12px);
    font-weight: 500;
    font-size: vw(24px);
    text-decoration: none;
    color: #006db7;
    background: #fff;
    box-shadow: vw(0px) vw(4px) vw(4px) rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      flex-shrink: 0;
      width: vwMobile(130px);
      height: vwMobile(50px);
      padding: 0 vwMobile(4px);
      margin: 0 vwMobile(4px);
      border-radius: vwMobile(5px);
      font-size: vwMobile(14px);
    }


    &.active {
      color: #fff;
      background: #006db7;
      cursor: default;

      &::after {
        display: inline-block;
        width: vw(30px);
        height: vw(4px);
        vertical-align: middle;
        background: #fff;
        content: '';

        @include media-breakpoint-down(md) {
          width: vwMobile(20px);
          height: vwMobile(2px);
        }
      }

      .sub-icon {
        display: none;
      }
    }

    .text-wrappper {
      margin-bottom: vw(14px);
      font-weight: 700;
    }

    .sub-icon {
      width: vw(25px);

      @include media-breakpoint-down(md) {
        width: vwMobile(16px);
      }
    }
  }
}
