$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-faq {
    padding: vwMobile(40px) vwMobile(16px) 0;

    @include media-breakpoint-up(md) {
      padding: vw(148px) 0 0;
    }
  }

  .section-title {
    margin-bottom: vwMobile(27px);
    font-size: vwMobile(18px);
    font-family: ImcreSoojin;
    line-height: 1.6;
    text-align: center;
    letter-spacing: -0.02em;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(102px);
      font-size: vw(46px);
    }

    .strong {
      color: $red;
    }
  }

  .faq-tabs {
    :deep(.box-tab-items) {
      display: flex;
      justify-content: center;
      margin-bottom: vwMobile(33px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(113px);
      }

      .tab-item {
        flex: unset;

        &:not(:last-child) {
          margin-right: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-right: vw(68px);
          }
        }
      }
    }

    .faq-tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(100px);
      height: vwMobile(36px);
      border: 1px solid $red;
      border-radius: vwMobile(18px);
      font-size: vwMobile(14px);
      font-family: ImcreSoojin;
      letter-spacing: -0.02em;
      color: $red;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: vw(178px);
        height: vw(66px);
        border-radius: vw(50px);
        font-size: vw(24px);
      }

      &.active {
        color: white;
        background-color: $red;
        cursor: default;
      }
    }
  }

  .faq-collapse-trigger {
    display: flex;
    align-items: center;
    padding: vwMobile(11px) 0;
    margin-top: vwMobile(15px);
    border-bottom: 1px solid #d2d2d2;
    font-size: vwMobile(14px);
    font-family: ImcreSoojin;
    color: $black;

    @include media-breakpoint-up(md) {
      padding: vw(11px) 0;
      margin-top: vw(16px);
      font-size: vw(24px);
    }

    .indicator {
      width: vwMobile(30px);
      margin-left: vwMobile(15px);
      transition: transform .3s;

      @include media-breakpoint-up(md) {
        width: vw(48px);
        margin-right: vw(22px);
        margin-left: vw(15px);
        border-radius: 50%;
        background: #f5f5f5;
      }
    }

    .collapse-title {
      flex: 1;
    }

    .collapse-symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(30px);
      height: vwMobile(30px);
      margin-right: vwMobile(15px);
      border-radius: 50%;
      font-size: vwMobile(16px);
      color: #fff;
      background: #fdb515;

      @include media-breakpoint-up(md) {
        width: vw(62px);
        height: vw(62px);
        margin-right: vw(33px);
        font-size: vw(24px);
      }
    }

    &.active {
      .indicator {
        transform: rotate(180deg);
      }
    }
  }

  .faq-content {
    padding-top: vwMobile(9px);
    padding-bottom: vwMobile(20px);
    font-size: vwMobile(12px);
    line-height: 1.66;
    letter-spacing: -0.02em;
    color: #515151;

    @include media-breakpoint-up(md) {
      padding-top: vw(18px);
      padding-right: vw(48px + 22px + 15px);
      padding-bottom: vw(40px);
      font-size: vw(24px);
    }

   :deep(.text-important) {
      color: $red;
    }

    :deep(.text-bold) {
      font-family: AppleSDGothicNeoB;
    }
  }
