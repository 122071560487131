$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-content {
    padding-bottom: vw(225px);
    background-color: #f7f7f7;

    @include media-breakpoint-down(md) {
      padding-bottom: vwMobile(49px);
    }

    .box-container {
      position: relative;
      padding: vw(146px) 0 vw(128px);

      @include media-breakpoint-down(md) {
        padding: vwMobile(43px) 0 vwMobile(37px);
      }

      .title {
        font-size: vw(58px);
        font-family: SCDream8;
        line-height: vw(60px);
        text-align: center;
        color: #006db7;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(24px);
          font-family: SCDream6;
          line-height: vwMobile(29px);
        }
      }

      .desc {
        margin-top: vw(27px);
        font-weight: 500;
        font-size: vw(36px);
        line-height: vw(49px);
        text-align: center;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          margin-top: vwMobile(11px);
          font-size: vwMobile(18px);
          line-height: vwMobile(24px);
        }
      }

      .wave-icon {
        position: absolute;
        top: vw(110px);
        left: vw(95px);
        width: vw(310px);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .box-banner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: vw(1320px);
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        width: vwMobile(301px);
      }

      .item {
        width: vw(645px);
        margin-bottom: vw(22px);
        background-color: #fff;

        @include media-breakpoint-down(md) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: vwMobile(27px);
          }
        }

        .banner {
          width: 100%;
          height: vw(505px);
          object-fit: cover;

          @include media-breakpoint-down(md) {
            height: vwMobile(235px);
          }
        }

        .title-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: vw(147px);
          font-weight: 700;
          font-size: vw(36px);
          line-height: vw(49px);
          text-align: center;
          white-space: pre-wrap;
          color: #3b393a;

          @include media-breakpoint-down(md) {
            height: vwMobile(53px);
            font-size: vwMobile(16px);
            line-height: vwMobile(20px);
          }
        }

        .time {
          width: 100%;
          padding: vw(8px) vw(20px);
          font-size: vw(20px);
          text-align: right;
          color: #656565;
          background-color: #f7f7f7;

          @include media-breakpoint-down(md) {
            padding: vwMobile(4px) vwMobile(10px);
            font-size: vwMobile(12px);
          }
        }
      }
    }
  }

  .modal-dialog {
    position: relative;
    width: vw(1320px);
    max-width: none;
    height: vw(650px);
    margin-top: 10%;
    background-color: #fff;


    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      width: vwMobile(360px);
      height: auto;
      min-height: vwMobile(535px);
      margin-top: 25%;
    }

    .modal-content {
      border: none;

      .modal-body {
        display: flex;
        box-sizing: content-box;
        height: vw(546px);
        padding: vw(52px) vw(61px);
        overflow: hidden;

        @include media-breakpoint-down(md) {
          display: block;
          height: unset;
          padding: vwMobile(56px) vwMobile(16px);
        }
      }
    }

    .close-icon {
      position: absolute;
      top: vw(48px);
      right: vw(44px);
      width: vw(35px);
      cursor: pointer;

      @include media-breakpoint-down(md) {
        top: vwMobile(13px);
        right: vwMobile(15px);
        width: vwMobile(18px);
      }
    }

    .banner {
      width: vw(546px);
      height: 100%;
      margin-right: vw(48px);
      object-fit: cover;

      @include media-breakpoint-down(md) {
        width: vwMobile(328px);
        height: vwMobile(328px);
      }
    }

    .text-wrapper {
      width: vw(560px);
      height: 100%;
      padding-top: vw(82px);
      padding-bottom: vw(21px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 6px;
        box-shadow: 0 0 0 gray inset;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #b7b7b7;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a1a1a1;
      }

      &::-webkit-scrollbar-thumb:active {
        background-color: #a1a1a1;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        height: unset;
        padding-top: vwMobile(15px);
        padding-bottom: 0;
        overflow: hidden;
      }

      .time {
        font-size: vw(20px);
        color: #656565;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(12px);
        }
      }

      .title {
        padding-bottom: vw(29px);
        border-bottom: 1px solid #3b393a;
        font-weight: 700;
        font-size: vw(36px);
        line-height: vw(49px);
        white-space: pre-line;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          border-bottom: none;
          font-size: vwMobile(24px);
          line-height: vwMobile(33px);
        }
      }

      .desc {
        margin-top: vw(32px);
        font-size: vw(26px);
        font-family: Noto Sans KR;
        line-height: vw(35px);
        white-space: pre-line;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          margin-top: vwMobile(10px);
          font-size: vwMobile(16px);
          line-height: vwMobile(22px);
        }
      }
    }
  }
