$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .page {
    padding: vw(97px) 0 vw(219px);

    // 移动端没有给过来设计，自己根据移动端样式效果调整的，后续有设计了可能需要更改
    @include media-breakpoint-down(md) {
      padding: vwMobile(45px) 0 vwMobile(80px);
    }
  }

  .logo {
    width: vw(201px);
    margin-bottom: vw(158px);

    @include media-breakpoint-down(md) {
      width: vwMobile(100px);
      margin-bottom: vwMobile(80px);
    }
  }

  .title {
    width: vw(407px);
    margin-bottom: vw(30px);

    @include media-breakpoint-down(md) {
      width: vwMobile(200px);
      margin-bottom: vwMobile(20px);
    }
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    width: vw(520px);
    height: vw(50px);
    padding: 0 vw(20px) 0 vw(30px);
    margin: auto;
    margin-bottom: vw(90px);
    border-radius: vw(36px);
    background: #f2f2f2;

    @include media-breakpoint-down(md) {
      width: vwMobile(300px);
      height: vwMobile(40px);
      padding: 0 vwMobile(20px) 0 vwMobile(30px);
      margin-bottom: vwMobile(45px);
      border-radius: vwMobile(20px);
    }

    .input {
      flex: 1;
      height: 100%;
      border: none;
      font-size: vw(26px);
      background: transparent;

      @include media-breakpoint-down(md) {
        font-size: vwMobile(13px);
      }
    }

    .icon-search {
      width: vw(30px);
      margin-left: vw(10px);
      cursor: pointer;

      @include media-breakpoint-down(md) {
        width: vwMobile(30px);
        margin-left: vwMobile(10px);
      }
    }
  }

  .shop-list {
    --bs-gutter-x: #{vw(11px)};
    --bs-gutter-y: #{vw(3px)};

    @include media-breakpoint-down(md) {
      --bs-gutter-x: #{vwMobile(11px)};
      --bs-gutter-y: #{vwMobile(3px)};
    }

    .item {
      display: flex;

      .name,
      .address {
        display: flex;
        align-items: center;
        padding: vw(18px) vw(11px) vw(15px);
        line-height: vw(27px);

        @include media-breakpoint-down(md) {
          padding: vwMobile(9px) vwMobile(6px) vwMobile(8px);
        }
      }

      .name {
        justify-content: center;
        width: vw(135px);
        font-weight: 700;
        font-size: vw(22px);
        background: #e1e1e1;

        @include media-breakpoint-down(md) {
          width: vwMobile(75px);
          font-size: vwMobile(12px);
        }
      }

      .address {
        flex: 1;
        padding: vw(18px) vw(11px) vw(15px);
        font-weight: 500;
        font-size: vw(22px);
        background: #f2f2f2;

        @include media-breakpoint-down(md) {
          padding: 0 vwMobile(11px);
          font-size: vwMobile(12px);
        }
      }
    }
  }
