$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../vars.scss';

.page-date {
  overflow: hidden;
}

@include media-breakpoint-up(md) {
  .bg-section-1 {
    background-image: none;
    background-color: #fff;
  }
}

.section-1 {
  padding-top: vwMobile(96px);
  padding-bottom: vwMobile(41px);
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: vw(94px);
    padding-bottom: vw(73px);
  }


  .section-title {
    margin-bottom: vwMobile(27px);
    font-weight: 500;
    font-size: vwMobile(24px);
    line-height: normal;
    color: #fff;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(52px);
      font-size: vw(42px);
      color: #303030;
    }
  }

  .swiper {
    --swiper-pagination-bottom: 0;
    --swiper-pagination-color: #fff;
    --swiper-pagination-bullet-inactive-color: #303030;
    --swiper-pagination-bullet-horizontal-gap: #{vwMobile(5px)};
    --swiper-pagination-bullet-size: #{vwMobile(10px)};
    padding-right: vwMobile(12px);
    padding-bottom: vwMobile(33px);
    padding-left: vwMobile(22px);
    margin-bottom: vwMobile(51px);
    overflow: visible;

    @include media-breakpoint-up(md) {
      width: vw(1230px);
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-bottom: vw(66px);
      margin-left: vw(366px);
      overflow: visible;
    }

    .swiper-pagination {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .swiper-wrapper {
      align-items: flex-end;
    }

    .swiper-slide {
      padding-right: vwMobile(42px);

      @include media-breakpoint-up(md) {
        padding-right: vw(53px);
      }

      &:nth-child(3n + 2) {
        .box-round {
          .bottle {
            top: vwMobile(31px);
            width: vwMobile(96px);
            transform: rotate(-5deg) translate(38%, 5%);

            @include media-breakpoint-up(md) {
              top: vw(64px);
              width: vw(120px);
            }
          }
        }
      }

      .box-round {
        position: relative;
        text-align: left;

        .bottle {
          position: absolute;
          top: 0;
          right: 0;
          width: vwMobile(92px);
          transform: rotate(75deg) translate(-5%, -15%);
          transform-origin: center center;

          @include media-breakpoint-up(md) {
            width: vw(116px);
          }
        }

        .round-title {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: vwMobile(62px);
          height: vwMobile(36px);
          border-top-right-radius: vwMobile(4px);
          border-top-left-radius: vwMobile(4px);
          font-weight: 500;
          font-size: vwMobile(18px);
          color: #fff;
          background-color: #303030;

          @include media-breakpoint-up(md) {
            width: vw(84px);
            height: vw(46px);
            border-top-right-radius: vw(4px);
            border-top-left-radius: vw(4px);
            font-size: vw(24px);
          }

          .text {
            margin-bottom: vwMobile(-3px);

            @include media-breakpoint-up(md) {
              margin-bottom: vw(-3px);
            }
          }
        }

        .container-content,
        .box-content {
          clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
        }

        .container-content {
          display: flex;
          width: vwMobile(290px);
          height: vwMobile(200px);
          padding: vwMobile(3px);
          background-color: #303030;

          @include media-breakpoint-up(md) {
            width: vw(364px);
            height: vw(252px);
            padding: vw(3px);
          }
        }

        .box-content {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding: vwMobile(25px) 0 vwMobile(25px) vwMobile(12px);
          background-color: #fff;

          &.closed {
            &:after {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: vwMobile(27px);
              color: #fff;
              background-color: rgba(#303030, 80%);
              content: '종료';

              @include media-breakpoint-up(md) {
                font-size: vw(36px);
              }
            }
          }

          @include media-breakpoint-up(md) {
            padding: vw(30px) 0 vw(30px) vw(15px);
          }

          .round-row {
            display: flex;
            font-size: vwMobile(14px);
            line-height: normal;

            @include media-breakpoint-up(md) {
              font-size: vw(18px);
            }

            .label {
              min-width: vwMobile(112px);
              font-weight: 500;

              @include media-breakpoint-up(md) {
                min-width: vw(129px);
              }
            }
          }
        }
      }
    }
  }

  .btn-submit {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: vwMobile(10px) vwMobile(22px);
    border-radius: vwMobile(35px);
    font-weight: 500;
    font-size: vwMobile(18px);
    line-height: 150%;
    color: #303030;
    background: #fff;
    box-shadow: 0px vwMobile(4px) vwMobile(4px) 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding: vw(10px) vw(53px);
      border-radius: vw(35px);
      font-size: vw(24px);
      color: #fff;
      background: #b01614;
      box-shadow: none;
    }
  }
}

.section-2 {
  position: relative;
  padding-top: vwMobile(17px);
  padding-bottom: vwMobile(38px);
  padding-left: vwMobile(22px);
  font-size: vwMobile(14px);
  line-height: 150%;
  color: #fff;
  background-color: #303030;

  @include media-breakpoint-up(md) {
    padding-top: vw(50px);
    padding-bottom: vw(64px);
    padding-left: vw(22px);
    font-size: vw(18px);
    text-align: center;
  }

  :deep(strong) {
    color: #f4df23;
  }

  .desc {
    @include media-breakpoint-up(md) {
      --desc-title-text-align: center;
      --desc-row-justify-content: center;
    }

    &:not(:last-child) {
      margin-bottom: vwMobile(22px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(27px);
      }
    }
  }
}
