$primary-bg-color: #21cf3c;
$secondary-bg-color: #3586fc;
$btn-bg: #1b8158;
$btn-bg-secondary: #3586fc;

@function vwMobile($target) {
  @if unit($target) == px {
    @return $target / 390px * 100vw;
  }
}

@function vw($target) {
  @if unit($target) == px {
    @return $target / 1920px * 100vw;
  }

  @else {
    @return $target;
  }
}
