$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.collect-modal {
  --modal-min-height: #{vwMobile(574px)};

  @include media-breakpoint-up(md) {
    --modal-min-height: #{vw(660px)};
  }

  :deep(.cocacola-s5-modal-content) {
    .cocacola-s5-modal-title {
      margin-top: vwMobile(12px);

      @include media-breakpoint-up(md) {
        margin-top: vw(12px);
      }
    }
  }
}

.step-1 {
  .container-challenge {
    display: flex;
    justify-content: center;
  }

  .box-challenge {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin-top: vwMobile(107px);

    @include media-breakpoint-up(md) {
      margin-top: vw(131px);
    }

    .bottle-1-line {
      position: absolute;
      top: 0;
      left: 0;
      width: vwMobile(92px);
      transform: translate(-35%, -60%) rotate(20deg);

      @include media-breakpoint-up(md) {
        width: vw(92px);
      }
    }

    .bottle-45deg {
      position: absolute;
      bottom: vwMobile(-10px);
      left: vwMobile(168px);
      width: vwMobile(96px);
      transform: translate(-10%, 100%);

      @include media-breakpoint-up(md) {
        bottom: vw(-10px);
        left: vw(168px);
        width: vw(96px);
      }
    }

    .item {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(190px);
      height: vwMobile(54px);
      border: 2px solid #030303;
      border-radius: vwMobile(8px);
      font-weight: 500;
      font-size: vwMobile(24px);
      background-color: #fff;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: vw(190px);
        height: vw(54px);
        border-radius: vw(8px);
        font-size: vw(24px);
      }

      &.active {
        border-color: #b01614;
        color: #b01614;
      }

      &:not(:last-child) {
        margin-bottom: vwMobile(15px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(15px);
        }
      }
    }
  }
}

.step-2 {
  .tip {
    margin-top: vwMobile(15px);
    text-align: center;
    color: #b01614;

    @include media-breakpoint-up(md) {
      margin-top: vw(23px);
    }
  }

  .box-options {
    margin-top: vwMobile(26px);

    @include media-breakpoint-up(md) {
      margin-top: vw(34px);
    }

    :deep(.item) {
      padding-left: vwMobile(44px);
      margin-bottom: vwMobile(20px);

      @include media-breakpoint-up(md) {
        padding-left: vw(103px);
        margin-bottom: vw(24px);
      }

      strong {
        font-weight: 500;
      }
    }
  }
}

.step-3 {
  text-align: center;

  .box-desc {
    margin-top: vwMobile(17px);
    margin-bottom: vwMobile(106px);

    @include media-breakpoint-up(md) {
      margin-top: vw(23px);
      margin-bottom: vw(117px);
    }

    .desc {
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(24px);
        }
      }
    }
  }

  .box-input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: vwMobile(24px);

    @include media-breakpoint-up(md) {
      font-size: vw(36px);
    }

    .value {
      width: vwMobile(75px);
      line-height: normal;
      text-align: center;
      transform: translateY(10%);

      @include media-breakpoint-up(md) {
        width: vw(75px);
        transform: translateY(25%);
      }
    }

    .button {
      width: vwMobile(30px);
      height: vwMobile(30px);

      @include media-breakpoint-up(md) {
        width: vw(30px);
        height: vw(30px);
      }

      svg {
        width: 100%;
      }
    }
  }
}

.step-3,
.step-success {
  .bottle-1-line {
    position: absolute;
    top: vwMobile(218px);
    right: 0;
    width: vwMobile(92px);
    transform: translate(30%, 0);

    @include media-breakpoint-up(md) {
      top: vw(232px);
      width: vw(92px);
      transform: translate(25%, 0);
    }
  }

  .bottle-45deg {
    position: absolute;
    bottom: vwMobile(33px);
    left: 0;
    width: vwMobile(96px);
    transform: translate(-20%, 0);

    @include media-breakpoint-up(md) {
      bottom: vw(75px);
      width: vw(96px);
    }
  }
}

.step-success {
  .box-desc {
    margin-top: vwMobile(117px);
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: vw(137px);
    }

    .desc {
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(24px);
        }
      }
    }
  }
}
