$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .section-4 {
    background: #fff;
  }

  .section-title {
    padding: 43px 0 36px;
    background: #f8f8f8;
  }

  .section-title-inner {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .name {
      font-weight: bold;
      font-size: 32px;
      color: $primary-darker;
    }

    .box-score {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: row;
        justify-content: space-between;
      }

      .comment-create-btn {
        padding: 12px 42px;
        border: 1px solid $primary;
        border-radius: 24px;
        font-size: 18px;
        color: $primary;
        background: #fff;
      }
    }

    .score-text {
      display: flex;
      align-items: baseline;
      margin-top: 26px;
      font-size: 22px;
      color: #000;

      .score {
        margin-left: 13px;
        font-weight: bold;
        font-size: 32px;
        color: $primary-darker;
       }
    }
  }

  .box-comment {
    padding: 38px 0 32px;
    margin-bottom: 59px;
    border-bottom: 1px solid #dddddd;

    .comment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .user-name {
        font-size: 22px;
        color: #999999;
      }
    }

    .comment-body {
      color: #000000;

      .comment-title {
        margin-bottom: 26px;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
      }

      .comment-content {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .comment-pagination {
    display: flex;
    justify-content: center;
  }

  .dialog-box {
    max-width: 100%;

    :deep(.form-group-item-wrapper .form-item) {
      width: 500px;
    }

    .variant-box {
      display: flex;
      margin-bottom: 49px;

      .cover {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        margin-right: 24px;
        object-fit: cover;
      }

      .right {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
        overflow: hidden;

        .title {
          font-size: 18px;
          overflow-wrap: break-word;
          color: $font-color-primary;
        }

        .option-value {
          margin-top: 10px;
          font-size: 16px;
          overflow-wrap: break-word;
          color: $gray;
        }
      }
    }
  }
