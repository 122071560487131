$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-content {
    padding-bottom: vw(159px);
    background-color: #f7f7f7;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    .box-container {
      position: relative;
      padding: vw(124px) 0 vw(199px);

      @include media-breakpoint-down(md) {
        padding: vwMobile(60px) 0 vwMobile(53px);
      }

      .title {
        font-weight: 700;
        font-size: vw(58px);
        line-height: vw(60px);
        text-align: center;
        color: #006db7;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(24px);
          line-height: vwMobile(28px);
        }
      }

      .wave-icon {
        position: absolute;
        top: vw(110px);
        left: vw(95px);
        width: vw(310px);

        &.wave-icon-2 {
          top: vw(630px);
          left: vw(1490px);
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .wave-spot {
        position: absolute;
        bottom: vw(103px);
        right: vw(108px);
        width: vw(192px);
      }

      .list {
        width: vw(1200px);
        margin: vw(100px) auto 0;

        @include media-breakpoint-down(md) {
          width: vwMobile(353px);
          margin: vwMobile(22px) auto 0;
        }
      }

      .item {
        width: vw(388px);
        height: vw(456px);
        padding: vw(54px) vw(36px);
        border-radius: vw(36px);
        background-color: #006db7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        @include media-breakpoint-down(md) {
          width: vwMobile(113px);
          height: vwMobile(142px);
          padding: vwMobile(23px) vwMobile(9px);
          border-radius: vwMobile(6px);
        }

        .title {
          padding-bottom: vw(36px);
          border-bottom: vw(2px) solid #fff;
          font-weight: 700;
          font-size: vw(36px);
          line-height: vw(49px);
          text-align: center;
          color: #fff;

          @include media-breakpoint-down(md) {
            padding-bottom: vwMobile(20px);
            border-bottom: vwMobile(2px) solid #fff;
            font-weight: 500;
            font-size: vwMobile(15px);
            font-family: 'Noto Sans KR';
            line-height: vwMobile(20px);
          }
        }

        .content {
          margin-top: vw(60px);
          font-weight: 300;
          font-size: vw(36px);
          font-family: Roboto;
          line-height: vw(49px);
          text-align: center;
          color: #fff;

          @include media-breakpoint-down(md) {
            margin-top: vwMobile(13px);
            font-size: vwMobile(18px);
            line-height: vwMobile(20px);
          }

          .num {
            margin-right: vw(2px);
            font-weight: 700;
            font-size: vw(100px);
            line-height: vw(136px);

            @include media-breakpoint-down(md) {
              font-size: vwMobile(26px);
              line-height: vwMobile(41px);
            }
          }
        }
      }
    }

    .location-text-wrapper {
      position: absolute;
      z-index: 200;
      top: vw(100px);
      left: vw(-80px);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: vw(205px);
      height: vw(60px);
      padding-right: vw(30px);
      border-radius: vw(12px);
      background-color: #fff;

      &.location-text-wrapper-3 {
        left: vw(-90px);
        width: vw(238px);
      }

      @include media-breakpoint-down(md) {
        top: vwMobile(42px);
        left: vwMobile(-40px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: vwMobile(98px);
        height: vwMobile(31px);
        padding: 0;
        border-radius: vwMobile(6px);

        &.location-text-wrapper-3 {
          left: vwMobile(-90px);
          width: vwMobile(122px);
        }
      }

      .location-text {
        font-weight: 700;
        font-size: vw(36px);
        line-height: vw(36px);
        text-align: center;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(18px);
          line-height: vwMobile(36px);
        }
      }

      .location-text-icon {
        width: vw(48px);
        // 微调
        padding-top: vw(5px);
        margin-left: vw(10px);
        cursor: pointer;

        @include media-breakpoint-down(md) {
          width: vwMobile(24px);
          margin-left: vwMobile(14px);
        }
      }
    }

    .map-container {
      position: relative;
      padding: vw(153px) 0 vw(164px);
      background-color: #ffc521;

      @include media-breakpoint-down(md) {
        padding: vwMobile(37px) 0 vwMobile(11px);
      }

      .wave-icon {
        position: absolute;
        top: vw(574px);
        left: vw(81px);
        width: vw(197px);

        &.wave-icon-2 {
          top: vw(1371px);
          left: vw(1646px);
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .title {
        font-size: vw(58px);
        font-family: SCDream8;
        line-height: vw(67px);
        text-align: center;
        color: #3b393a;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(24px);
          line-height: vwMobile(28px);
        }
      }

      .map {
        position: relative;
        width: vw(1386px);
        height: vw(1481px);
        margin: vw(92px) auto 0;

        @include media-breakpoint-down(md) {
          width: vwMobile(475px);
          height: vwMobile(510px);
          margin: vwMobile(26px) 0 0 vwMobile(-51px);
        }

        .bg {
          width: 100%;
        }

        .location {
          position: absolute;
          width: vw(51px);
          height: vw(71px);

          @include media-breakpoint-down(md) {
            width: vwMobile(25px);
            height: vwMobile(39px);
          }

          .icon {
            width: 100%;
            height: 100%;
          }

          &.show {
            z-index: 5000;
          }
        }

        .hover-bg {
          position: absolute;
          top: vw(0);
          left: vw(-317px);
          width: vw(693px);
          height: vw(275px);
        }

        .board {
          position: absolute;
          top: vw(72px);
          left: vw(-317px);
          width: vw(693px);
          height: vw(275px);
          padding: vw(115px) vw(17px) vw(21px);
          border-radius: vw(12px);
          background: #006db7;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          @include media-breakpoint-down(md) {
            position: fixed;
            top: 30%;
            left: vwMobile(17px);
            width: vwMobile(340px);
            height: vwMobile(165px);
            padding: 0;
            border-radius: vwMobile(12px);
          }

          .location-text-wrapper {
            top: vw(-88px);
            left: 50%;
            transform: translateX(-50%);

            @include media-breakpoint-down(md) {
              top: vwMobile(16px);
            }
          }

          .relative {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
              padding: vwMobile(71px) vwMobile(9px) vwMobile(22px);
            }
          }

          .mobile-board-close {
            position: absolute;
            bottom: vwMobile(-52px);
            left: 50%;
            width: vwMobile(36px);
            transform: translateX(-50%);
          }

          .board-item {
            width: vw(200px);

            @include media-breakpoint-down(md) {
              width: vwMobile(98px);
            }

            .title {
              padding-bottom: vw(15px);
              border-bottom: vw(2px) solid #fff;
              font-weight: 500;
              font-size: vw(26px);
              font-family: Roboto;
              line-height: vw(35px);
              text-align: center;
              color: #fff;

              @include media-breakpoint-down(md) {
                padding-bottom: vwMobile(11px);
                border-bottom: vwMobile(2px) solid #fff;
                font-size: vwMobile(14px);
                line-height: vwMobile(19px);
              }
            }

            .content {
              margin-top: vw(8px);
              font-weight: 300;
              font-size: vw(26px);
              font-family: 'Noto Sans KR';
              line-height: vw(35px);
              text-align: center;
              color: #fff;

              @include media-breakpoint-down(md) {
                margin-top: vwMobile(8px);
                font-size: vwMobile(14px);
                line-height: vwMobile(19px);
              }

              .num {
                margin-right: vw(2px);
                font-weight: 500;
                font-size: vw(58px);
                line-height: vw(79px);

                @include media-breakpoint-down(md) {
                  font-size: vwMobile(24px);
                  line-height: vwMobile(33px);
                }
              }
            }
          }
        }
      }
    }

    // pc端
    .board-container {
      position: relative;
      width: vw(1320px);
      margin: vw(145px) auto vw(246px);

      .wave-icon {
        position: absolute;
        top: vw(638px);
        left: vw(-110px);
        width: vw(197px);
      }

      .mascot {
        position: absolute;
        bottom: vw(-90px);
        right: vw(-182px);
        width: vw(364px);
      }

      .board {
        position: relative;
        width: 100%;
        padding: vw(150px) vw(86px) vw(116px);
        border-radius: vw(36px);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

        .title {
          margin-bottom: vw(82px);
        }
      }

      .title {
        margin-bottom: vw(90px);
        font-size: vw(58px);
        font-family: SCDream8;
        line-height: vw(67px);
        text-align: center;
        color: #3b393a;
      }

      .header-wrapper {
        position: relative;
        padding: 0 0 vw(145px);
        margin: 0 auto vw(113px);
        border-bottom: vw(1px) solid #3b393a;

        img {
          width: 100%;
          height: auto;
        }
      }

      :deep(.emart-btn) {
        margin: vw(144px) auto 0;
      }
    }

    // 移动端
    .message-container {
      width: 100%;
      padding-top: vwMobile(48px);
      padding-bottom: vwMobile(42px);

      .title {
        font-size: vwMobile(24px);
        font-family: SCDream8;
        line-height: vwMobile(30px);
        text-align: center;
        color: #3b393a;
      }

      .header-wrapper {
        width: vwMobile(350px);
        margin: vwMobile(33px) auto 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .mobile-line {
      width: vwMobile(350px);
      height: vwMobile(1px);
      margin: 0 vwMobile(12px);
      background-color: #b1b1b1;
    }

    .mobile-board-container {
      position: relative;
      padding: vwMobile(23px) 0 vwMobile(54px);

      .mascot {
        position: absolute;
        top: vwMobile(-57px);
        left: 50%;
        width: vwMobile(117px);
        transform: translateX(-50%);
      }

      .title {
        font-size: vwMobile(24px);
        font-family: SCDream8;
        line-height: vwMobile(90px);
        text-align: center;
        color: #3b393a;
      }

      .banners {
        width: 100%;
        overflow-x: scroll;

        .banner {
          flex-shrink: 0;
          width: vwMobile(216px);
          height: vwMobile(216px);
          margin-right: vwMobile(9px);
          border-radius: 50%;
          object-fit: cover;

          &:first-child {
            margin-left: vwMobile(81px);
          }

          &:last-child {
            margin-right: vwMobile(81px);
          }
        }
      }

      :deep(.emart-btn) {
        margin: vwMobile(40px) auto 0;
      }
    }
  }
