$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-1 {
    padding-bottom: vwMobile(26px);
    background-image: url('./images/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #fdb515;

    @include media-breakpoint-up(md) {
      height: vw(1060px);
      padding-bottom: vw(79px);
      background-image: url('./images/bg-pc.png');
      background-position: top 0 right 42%;
      background-size: vw(2818px);
    }

    > .container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .page-title {
      padding: vwMobile(35px) vwMobile(17px) 0;
      margin-bottom: vwMobile(17px);
      font-size: vwMobile(36px);
      font-family: ImcreSoojin;
      line-height: 1.38;
      color: #fff;

      @include media-breakpoint-up(md) {
        padding: vw(105px) 0 0;
        margin-bottom: vw(45px);
        margin-left: vw(-20px);
        font-size: vw(63px);
        line-height: 1.5;
      }
    }

    .container-logo-desc {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
      }
    }

    .desc {
      padding-left: vwMobile(25px);
      font-weight: 700;
      font-size: vwMobile(16px);
      line-height: 1.625;
      color: #fff;

      @include media-breakpoint-up(md) {
        padding-left: 0;
        margin-bottom: vw(35px);
        font-size: vw(24px);
        line-height: 1.4;
      }

      @include media-breakpoint-up(lg) {
        line-height: 1.66;
      }

      > div {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;

          span:not(:last-child) {
            margin-right: vw(7px);
          }
        }
      }
    }

    .box-logos {
      display: flex;
      justify-content: space-between;
      height: vwMobile(30px);
      padding: 0 vwMobile(10px);
      margin: vwMobile(88px) vwMobile(13px) vwMobile(27px);
      border-radius: vwMobile(50px);
      background: #fff;

      @include media-breakpoint-up(md) {
        width: vw(686px);
        height: vw(64px);
        padding: 0 vw(25px);
        margin: 0;
        border-radius: vw(16px);
      }

      a {
        display: flex;
        align-items: center;
        padding: 0 vwMobile(5px);

        @include media-breakpoint-up(md) {
          padding: 0 vw(5px);
        }
      }

      img {
        height: auto;
      }

      .logo-cocacola {
        width: vwMobile(32px);

        @include media-breakpoint-up(md) {
          width: vw(59px);
        }
      }

      .logo-blackyak {
        width: vwMobile(56px);

        @include media-breakpoint-up(md) {
          width: vw(106px);
        }
      }

      .logo-emart {
        width: vwMobile(26px);

        @include media-breakpoint-up(md) {
          width: vw(48px);
        }
      }

      .logo-ssg {
        width: vwMobile(40px);

        @include media-breakpoint-up(md) {
          width: vw(75px);
        }
      }

      .logo-gmark {
        width: vwMobile(35px);

        @include media-breakpoint-up(md) {
          width: vw(66px);
        }
      }

      .logo-auction {
        width: vwMobile(45px);

        @include media-breakpoint-up(md) {
          width: vw(66px);
        }
      }

      .logo-wwf {
        width: vwMobile(13px);

        @include media-breakpoint-up(md) {
          width: vw(24px);
        }
      }

      .logo-terracycle {
        width: vwMobile(14px);

        @include media-breakpoint-up(md) {
          width: vw(26px);
        }
      }
    }

    .section-title, .section-desc {
      padding: 0 vwMobile(10px);
      text-align: center;
      color: $black;

      @include media-breakpoint-up(md) {
        padding: 0;
        text-align: left;
      }
    }

    .section-title {
      margin-bottom: vwMobile(20px);
      font-size: vwMobile(18px);
      font-family: ImcreSoojin;

      @include media-breakpoint-up(md) {
        margin-bottom: vw(20px);
        font-size: vw(18px);
      }

      .strong {
        color: $red;
      }
    }

    .section-desc {
      font-size: vwMobile(14px);
      line-height: 1.7;
      letter-spacing: -0.05em;

      @include media-breakpoint-up(md) {
        font-size: vw(16px);
        line-height: 1.8;
      }

      @include media-breakpoint-up(xl) {
        line-height: 2.5;
      }

      p {
        margin: 0 vwMobile(5px);

        @include media-breakpoint-up(md) {
          margin: 0;
        }

        &:nth-last-child(1), :nth-last-child(2) {
          margin: 0;
        }
      }

      .strong {
        font-family: ImcreSoojin;
      }
    }
  }
