$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .section-3 {
    padding: 59px 0 91px;
    background: #fff;
  }

  .section-tab {
    :deep(.box-tab-items) {
      .tab-item {
        flex: unset;
        padding: 18px 31px;
        font-weight: bold;
        font-size: 24px;
        color: #999;

        &:not(:last-child) {
          margin-right: 51px;
        }

        &.active {
          position: relative;
          font-size: 32px;
          color: $primary-darker;

          &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 75px;
            height: 6px;
            border-radius: 3px;
            background: $primary;
            transform: translateX(-50%);
            content: '';
          }
        }

        @include media-breakpoint-down(md) {
          flex: 1;
          padding-right: 0;
          padding-left: 0;
          text-align: center;

          &:not(:last-child) {
            margin-right: 0;
          }

          &.active {
            font-size: 24px;
          }
        }
      }
    }

    :deep(.app-tab-content) {
      margin-top: 55px;
    }
  }

  .box-steps {
    display: flex;

     @include media-breakpoint-down(md) {
       flex-direction: column;
     }
  }

  .step-card-item {
    flex: 1;

    &:not(:last-child) {
      margin-right: 30px;

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 55px;
      }
    }
  }

  .description {
    font-size: 18px;
    line-height: 30px;
    white-space: pre-line;
    color: #000;
  }
