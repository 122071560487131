$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .shipment-progress {
    display: flex;
    margin: 0 auto;

    .status {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 140px;
      font-size: 18px;

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        padding: 0 29px;
        border: 1px solid #ddd;
        border-radius: 50%;
        text-align: center;
        background-color: #fff;

        &.active {
          color: #fff;
          background-color: $primary;
        }

        &.disabled {
          color: #fff;
          background-color: #ccc;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        min-height: 48px;
        padding: 6px 10px;
        margin-top: 30px;
        border: 1px solid $primary;
        border-radius: 24px;
        font-weight: 500;
        color: $primary;
        background-color: #fff;
        cursor: pointer;
      }
    }

    .arrow-wrapper {
      padding-top: 58px;
      margin: 0 30px;
    }

    .arrow {
      width: 24px;
    }
  }
