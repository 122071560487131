$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

section {
  position: relative;
  padding: vw(130px) 0;

  .bg-image {
    position: absolute;
  }

  &.blue {
    background-color: #006db7;
  }

  &.yellow {
    background-color: #fdb515;
  }

  @include media-breakpoint-down(md) {
    padding: vwMobile(34px) 0;
  }
}

.content-wrapper {
  position: relative;
  padding: vw(70px);
  border-radius: vw(48px);
  background-color: #fff;
  box-shadow: 0px vw(4px) vw(4px) rgba(0, 0, 0, 0.25);

  @include media-breakpoint-down(md) {
    padding: vwMobile(20px);
    border-radius: vwMobile(12px);
    box-shadow: 0px vwMobile(2px) vwMobile(2px) rgba(0, 0, 0, 0.25);
  }

  .title-wrapper {
    margin-bottom: vw(30px);
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(15px);
    }

    .image-wrapper {
      display: inline-flex;

      .icon {
        width: vw(63px);
        margin: 0 vw(10px);
        margin-bottom: vw(50px);

        @include media-breakpoint-down(md) {
          width: vwMobile(28px);
          margin: 0 vwMobile(5px);
          margin-bottom: vwMobile(25px);
        }
      }
    }

    .strong {
      font-size: vw(48px);
      font-family: SCDream8;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        font-size: vwMobile(24px);
      }
    }
  }
}

.section-1 {
  .bg-image {
    top: vw(300px);
    left: vw(90px);
    width: vw(310px);
  }

  .content-wrapper {
    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-left: 0;
      background: transparent;
      box-shadow: none;
    }
  }

  .text-wrapper {
    font-weight: 400;
    font-size: vw(36px);
    font-family: 'Noto Sans KR';
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: vwMobile(16px);
    }
  }

  .swiper-wrapper {
    margin-top: vw(95px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(34px);
    }
  }

  // 只在mobile显示
  .scroll-x {
    width: 100%;
    margin-top: vwMobile(34px);
    overflow-x: auto;

    .item {
      margin: 0 vwMobile(10px);
      font-weight: 700;
      font-size: vwMobile(16px);
      font-family: 'Noto Sans KR';
      color: #3b393a;

      .image {
        width: vwMobile(277px);
        margin-bottom: vwMobile(19px);
      }
    }
  }
}

.section-2 {
  .bg-image {
    &.bg-wave {
      top: vw(310px);
      left: vw(88px);
      width: vw(263px);
    }

    &.bg-net {
      bottom: 0;
      right: 0;
      width: vw(496px);
    }
  }


  .title-wrapper {
    margin-bottom: vw(90px);
  }

  .section-image {
    width: 100%;
    margin-top: vw(84px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(32px);
    }
  }
}

.section-3 {
  .bg-image {
    top: vw(105px);
    left: vw(88px);
    width: vw(263px);
  }

  .text-wrapper {
    margin-top: vw(30px);
    margin-bottom: vw(60px);
    font-weight: 500;
    font-size: vw(24px);
    font-family: 'Noto Sans KR';
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(10px);
      margin-bottom: vwMobile(30px);
      font-size: vwMobile(16px);
    }
  }
}

.section-4 {
  .bg-image {
    bottom: vw(216px);
    left: vw(87px);
    width: vw(310px);
  }

  .section-image {
    display: block;
    width: vw(1086px);
    margin: auto;
    margin-bottom: vw(138px);

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: vw(40px);
    }
  }

  .next-button-wrapper {
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}
