$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .age-restricition-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: vw(30px);

    @include media-breakpoint-down(md) {
      padding: vwMobile(30px);
    }
  }

  .age-restricition {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding: 0 vwMobile(15px);
    }
  }

  .hint-icon {
    width: vw(80px);

    @include media-breakpoint-down(md) {
      width: vwMobile(80px);
    }
  }

  .form-wrapper {
    width: 100%;
    margin-top: vw(43px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(43px);
    }
  }

  .hint-message {
    font-weight: 500;
    font-size: vw(26px);
    line-height: vw(30px);
    text-align: center;
    white-space: pre-wrap;

    @include media-breakpoint-down(md) {
      padding: 0 vwMobile(12px);
      font-size: vwMobile(22px);
      line-height: vwMobile(30px);
    }
  }

  .input-box {
    display: flex;
    justify-content: center;
    margin-top: vw(43px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(43px);
    }

    .item {
      &:not(:last-child) {
        margin-right: vw(26px);

        @include media-breakpoint-down(md) {
          margin-right: vwMobile(26px);
        }
      }
    }

    .label {
      margin-bottom: vw(12px);
      font-size: vw(18px);
      line-height: vw(28px);
      color: #333;

      @include media-breakpoint-down(md) {
        margin-bottom: vwMobile(12px);
        font-size: vwMobile(18px);
        line-height: vwMobile(28px);
      }
    }

    .select-container {
      width: vw(138px);
      height: vw(60px);
      border: 1px solid #ddd;
      border-radius: vw(3px);
      font-size: vw(18px);

      @include media-breakpoint-down(md) {
        width: vwMobile(80px);
        height: vwMobile(40px);
        border-radius: vwMobile(3px);
        font-size: vw(18px);
      }

      :deep(.form-select) {
        height: 100%;
        border: none;
        border-radius: 0;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: vw(320px);
    height: vw(60px);
    margin: vw(56px) auto;
    border-radius: vw(30px);
    font-size: vw(22px);
    color: #fff;
    background-color: $primary;

    @include media-breakpoint-down(md) {
      width: vwMobile(320px);
      height: vwMobile(40px);
      margin: vwMobile(56px) auto;
      border-radius: vwMobile(20px);
      font-size: vwMobile(18px);
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: vw(59px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(59px);
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vw(180px);
      height: vw(60px);
      border: 2px solid $primary;
      border-radius: vw(30px);
      font-size: vw(22px);
      color: $primary;
      background-color: #fff;

      @include media-breakpoint-down(md) {
        width: vwMobile(150px);
        height: vwMobile(40px);
        border-radius: vwMobile(20px);
        font-size: vwMobile(18px);
      }

      &:last-child {
        margin-left: vw(22px);
        color: #fff;
        background-color: $primary;

        @include media-breakpoint-down(md) {
          margin-left: vwMobile(22px);
        }
      }
    }
  }
