$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../vars.scss';

.page-faq {
  padding-top: vwMobile(98px);
  padding-bottom: vwMobile(92px);
  color: #fff;
  background-color: #303030;

  @include media-breakpoint-up(md) {
    padding-top: vw(94px);
    padding-bottom: vw(159px);
  }
}

.page-title {
  margin-bottom: vwMobile(36px);
  font-weight: 500;
  font-size: vwMobile(24px);
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: vw(79px);
    font-size: vw(42px);
  }
}

.box-content {
  margin: 0 vwMobile(17px) 0 vwMobile(16px);
  overflow: hidden;
  font-size: vwMobile(14px);
  color: #303030;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    width: vw(1194px);
    margin: 0 auto;
    font-size: vw(24px);
  }
}

:deep(.app-tab-content) {
  position: relative;
  z-index: 4;
  padding: vwMobile(28px) vwMobile(10px) vwMobile(47px);
  margin-top: -1px;
  background: #fff;

  @include media-breakpoint-up(md) {
    padding: vw(65px) vw(47px) vw(68px);
  }
}

:deep(.box-tab-items) {
  .tab-item {
    height: vwMobile(38px);
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      height: vw(78px);
    }

    &:last-child {
      .faq-tab-item {
        box-shadow: none;
      }
    }

    &.active {
      background-color: #fff;

      .faq-tab-item {
        box-shadow: none;
      }
    }
  }
}

.faq-tab-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: vwMobile(14px);
  color: #fff;
  background-color: #b01614;
  box-shadow: 4px 0 8px -4px rgba(0, 0, 0, 0.25);
  transform: skew(20deg);
  transform-origin: top;

  @include media-breakpoint-up(md) {
    font-size: vw(24px);
  }

  &.active {
    color: #303030;
    background-color: #fff;
  }

  .tab-label-text {
    transform: skew(-20deg);
    transform-origin: top;
  }
}

.faq-collapse-trigger {
  display: flex;
  align-items: center;
  padding: vwMobile(10px) 0;
  border-bottom: 1px solid #303030;

  @include media-breakpoint-up(md) {
    padding: vw(10px) 0;
  }

  .collapse-symbol,
  .indicator {
    flex-shrink: 0;
  }

  .collapse-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: vwMobile(30px);
    height: vwMobile(30px);
    border-radius: 50%;
    font-weight: 500;
    font-size: vwMobile(14px);
    line-height: 1.5;
    color: #fff;
    background-color: #b01614;

    @include media-breakpoint-up(md) {
      width: vw(62px);
      height: vw(62px);
      font-size: vw(24px);
    }
  }

  .collapse-title {
    flex: 1;
    padding: 0 vwMobile(15px);
    font-weight: 500;
    line-height: 150%;

    @include media-breakpoint-up(md) {
      padding: 0 vw(33px);
    }
  }

  .indicator {
    cursor: pointer;

    img {
      width: vwMobile(30px);
      height: vwMobile(30px);
      transition: transform .3s;

      @include media-breakpoint-up(md) {
        width: vw(48px);
        height: vw(48px);
      }
    }
  }

  &.active {
    .indicator {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.faq-content {
  padding: vwMobile(9px) 0 vwMobile(25px);
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    padding: vw(17px) 0 vw(40px);
  }
}
