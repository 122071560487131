$primary-color: #d22d2c;

@function vwMobile($target) {
  @if unit($target) == px {
    @return $target / 390px * 100vw;
  }
}

@function vw($target) {
  @if unit($target) == px {
    @return $target / 1920px * 100vw;
  }

  @else {
    @return $target;
  }
}
