$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .swiper {
    position: relative;
    display: flex;
    align-items: center;
    height: vw(532px);

    @include media-breakpoint-down(md) {
      height: vwMobile(573px);
    }
  }

  .btn-control {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    box-sizing: content-box;
    width: vw(32px);
    padding: vw(10px);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      position: absolute;
      z-index: 2;
      top: vwMobile(155px);
      width: vwMobile(17px);
      padding: vwMobile(10px);
    }

    img {
      width: 100%;
      height: auto;

      @include media-breakpoint-down(md) {
        // ios 100%会拿到padding尺寸
        width: vwMobile(17px);
      }
    }

    &.left {
      margin-left: vw(-10px);

      @include media-breakpoint-down(md) {
        left: vwMobile(13px);
        margin: 0;
      }
    }

    &.right {
      margin-right: vw(-10px);

      @include media-breakpoint-down(md) {
        right: vwMobile(13px);
        margin: 0;
      }
    }
  }

  .carousel {
    flex: 1;
    margin: 0 vw(45px);
    overflow: hidden;

    @include media-breakpoint-down(md) {
      margin: 0;
    }

    .box-carousel-inner {
      height: 100%;

      .carousel-item, img {
        width: 100%;
        height: 100%;
      }

      .carousel-item {
        position: relative;

        .link-btn {
          position: absolute;
          z-index: 1;
          top: vw(430px);
          left: vw(137px);
          width: vw(263px);
          height: vw(52px);
          cursor: pointer;

          @include media-breakpoint-down(md) {
            top: vwMobile(247px);
            left: vwMobile(80px);
            width: vwMobile(195px);
            height: vwMobile(40px);
          }
        }
      }
    }
  }

  .box-indicator {
    display: flex;
    justify-content: center;
    margin-top: vw(54px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(17px);
    }

    .indicator {
      width: vw(116px);
      cursor: pointer;

      @include media-breakpoint-down(md) {
        width: vwMobile(65px);
      }

      &.active {
        cursor: default;
      }

      &:not(:last-child) {
        margin-right: vw(24px);

        @include media-breakpoint-down(md) {
          margin-right: vwMobile(13px);
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
