$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}
@function zoomMobile($target) {
  @if unit($target) == px {
    @return $target * 0.7;
  }

  @else {
    @return $target;
  }
}

.dialog-campaign-ssg {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 594px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 0;
    font-weight: 400;
    font-size: zoomMobile(24px);
    font-family: 'Noto Sans KR';
    line-height: 1.4583;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    .modal-header {
      display: none;

      .btn-close {
        font-size: zoomMobile(21px);

        @include media-breakpoint-up(md) {
          font-size: 21px;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: zoomMobile(80px);
      padding-top: zoomMobile(56px);
      padding-right: zoomMobile(20px);
      padding-bottom: zoomMobile(35px);
      padding-left: zoomMobile(20px);

      @include media-breakpoint-up(md) {
        min-height: 80px;
        padding-top: 56px;
        padding-right: 20px;
        padding-bottom: 35px;
        padding-left: 20px;
      }
    }

    .modal-footer {
      padding-top: 0;
      padding-right: zoomMobile(40px);
      padding-bottom: zoomMobile(56px);
      padding-left: zoomMobile(40px);

      @include media-breakpoint-up(md) {
        padding-right: 40px;
        padding-bottom: 56px;
        padding-left: 40px;
      }

      .btn-dark {
        border-color: #666;
        background-color: #666;
      }

      .dialog-btn-group {
        padding: 0;
        margin: 0;

        .btn {
          box-sizing: border-box;
          width: zoomMobile(252px);
          min-height: zoomMobile(45px);
          padding: zoomMobile(5px);
          font-size: zoomMobile(20px);
          font-family: inherit;

          @include media-breakpoint-up(md) {
            width: 252px;
            min-height: 45px;
            padding: 5px;
            font-size: 20px;
          }
        }
      }
    }

    .dialog-container {
      flex-direction: column;
      min-height: unset;
      padding: 0;
    }

    .dialog-content {
      margin: 0;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }

  &.dialog-campaign-participate {
    .modal-content {
      .modal-header {
        display: flex;
      }

      .modal-body {
        padding-top: zoomMobile(20px);

        @include media-breakpoint-up(md) {
          padding-top: 20px;
        }
      }
    }

    .title {
      margin-bottom: zoomMobile(39px);

      @include media-breakpoint-up(md) {
        margin-bottom: 39px;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .radio-item {
        position: relative;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: zoomMobile(30px);

          @include media-breakpoint-up(md) {
            margin-right: 30px;
          }
        }

        input {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;

          &[data-checked="true"] {
            & + .radio {
              border-width: zoomMobile(4px);
              border-color: #64c149;

              @include media-breakpoint-up(md) {
                border-width: 4px;
              }
            }
          }
        }

        .radio {
          box-sizing: border-box;
          width: zoomMobile(16px);
          height: zoomMobile(16px);
          margin-right: zoomMobile(10px);
          border: 1px solid rgba(193, 193, 193, 0.6);
          border-radius: 50%;

          @include media-breakpoint-up(md) {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }

        label {
          font-weight: 500;
          font-size: zoomMobile(22px);
          line-height: 1.2;

          @include media-breakpoint-up(md) {
            font-size: 22px;
          }
        }
      }
    }
  }
}
