$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .footer-wrapper {
    margin-top: 60px;

    .router-link {
      font-size: 18px;
      text-decoration: none;
      color: #333;
    }

    .footer-content {
      padding-top: 44px;
      padding-bottom: 86px;
      border-top: 1px solid #ddd;

      @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    .logo {
      width: 130px;

      @include media-breakpoint-down(md) {
        display: block;
        width: 85px;
        margin: 0 auto 30px;
      }
    }

    .sns-box {
      margin-top: 24px;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      .sns-jump {
        &:hover {
          .sns-logo {
            display: none;

            &.hover {
              display: block;
            }
          }
        }
      }

      .sns-logo {
        width: 24px;
        height: 24px;
        margin-right: 24px;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          width: 32px;
          height: 32px;
        }

        &.no-margin {
          margin-right: 0;
        }

        &.hover {
          display: none;
        }
      }
    }

    .jump-item {
      width: 50%;
      height: 36px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }

    .xs-only-jump-data {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }

      .item {
        text-align: center;
      }

      .border-text {
        margin: 0 5px;
      }
    }

    .desc {
      margin-top: 37px;
      color: #ccc;

      @include media-breakpoint-down(md) {
        padding: 0 50px;
        text-align: center;
      }
    }
  }

  .line-popover {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 200px;
      height: 200px;
    }

    p {
      font-weight: bold;
      font-size: 18px;

      &.small {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
