$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .banner-container {
    padding-top: 152px;
    padding-bottom: 71px;
    background: url('./images/banner.png') no-repeat;
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;

      .title {
        margin: 0 0 40px;
        font-weight: bold;
        font-size: 48px;
      }

      .subtitle {
        margin: 0 0 37px;
        font-size: 18px;
        line-height: 1.9;
      }
    }

    .box-search {
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 0 200px;
      }


      :deep(.search) {
        background: #fff;
      }
    }
  }

  .main {
    padding-top: 56px;
    padding-bottom: 58px;
  }

  .box-taxons {
    margin-bottom: 77px;
  }

  .card-taxon {
    position: relative;
    height: 380px;
    margin-top: 56px;
    border-radius: 20px;
    overflow: hidden;

    .card-taxon-content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 25px 22px;
      color: #fff;
      background: rgba(0, 0, 0, .3);

      .name {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        text-align: center;
      }

      .learn-more {
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        img {
          width: 30px;
        }
      }
    }
  }

  .how-it-works {
    margin-bottom: 79px;

    .section-title {
      margin-bottom: 47px;
    }

    .description {
      font-size: 18px;
      line-height: 30px;
      color: #000000;
    }

    .step-card-item {
      margin-top: 47px;

      @include media-breakpoint-up(md) {
        height: 100%;
      }
    }
  }

  .custom-area {
    margin-bottom: 79px;
    font-size: 18px;
    line-height: 1.66;
    color: #000000;
  }

  h3, :deep(h3) {
    margin-bottom: 47px;
    font-weight: bold;
    font-size: 32px;
    color: $primary-darker;
  }

  .section-title {
    margin-bottom: 27px;
  }

  .product-item {
    margin-top: 50px;
  }

  .product-pagination {
    margin-top: 32px;
  }
