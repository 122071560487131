$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../vars.scss';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-main {
  --base-animation-delay: 0.5s;

  padding-top: vwMobile(26px);
  padding-bottom: vwMobile(55px);

  @include media-breakpoint-up(md) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

$pc-circle-margin: 280px;

.container-circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: vwMobile(25px);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: vw(715px + $pc-circle-margin);
    margin-top: vw($pc-circle-margin * -1);
    margin-bottom: 0;
  }
}

.box-circle {
  position: relative;
  flex-shrink: 0;
  width: vwMobile(540px);
  height: vwMobile(540px);
  padding: vwMobile(11px);
  border-radius: 50%;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    width: vw(1184px);
    height: vw(1184px);
    padding: vw(35px);
  }
}

.bottle {
  position: absolute;
  z-index: 2;

  :deep(svg) {
    width: 100%;
    height: auto;
  }
}

.bottle-1 {
  top: vw(430px + $pc-circle-margin);
  left: vw(97px);
  width: vw(119px);
  height: vw(102px);
}

.bottle-1-enter-from {
  top: vw(-360px);
}

.bottle-1-enter-active {
  transition: top 0.6s;
  transition-delay: calc(var(--base-animation-delay) + 0.2s);
}

.bottle-2 {
  top: vw(380px);
  left: vw(30px);
  width: vw(133px);
  height: vw(137px);
}

.bottle-2-enter-from {
  top: 0;
}

.bottle-2-enter-active {
  transition: top 0.4s;
  transition-delay: calc(var(--base-animation-delay) + 0.6s);
}

.bottle-3 {
  top: vw(780px);
  left: vw(93px);
  width: vw(191px);
  height: vw(267px);
  transform: translate(-18%, 0);
}

.bottle-3-enter-from {
  top: 0;
}

.bottle-3-enter-active {
  transition: top 0.8s;
  transition-delay: calc(var(--base-animation-delay) + 0.1s);
}

.bottle-4 {
  top: vw(640px);
  right: vw(245px);
  width: vw(110px);
  height: vw(107px);
  transform: translateY(-50%);
}

.bottle-4-enter-from {
  top: 0;
}

.bottle-4-enter-active {
  transition: top 0.55s;
  transition-delay: calc(var(--base-animation-delay) + 0.8s);
}

.bottle-5 {
  top: vw(336px);
  right: 0;
  width: vw(196px);
  height: vw(232px);
  transform: translateX(25%);
}

.bottle-5-enter-from {
  top: 0;
}

.bottle-5-enter-active {
  transition: top 0.4s;
  transition-delay: calc(var(--base-animation-delay) + 0.5s);
}

.bottle-6 {
  top: vw(250px + $pc-circle-margin);
  right: vw(50px);
  width: vw(163px);
  height: vw(160px);
}

.bottle-6-enter-from {
  top: vw(-279px);
}

.bottle-6-enter-active {
  transition: top 0.5s;
  transition-delay: calc(var(--base-animation-delay) + 0s);
}

.m-bottle {
  position: absolute;
  z-index: 2;
}

.m-bottle-1 {
  top: vwMobile(28px);
  left: 0;
  width: vwMobile(121px);
  height: vwMobile(71px);
  transform: translate(35%, -20%);
}

.m-bottle-1-enter-from {
  top: calc(vwMobile(71px) * -1);
}

.m-bottle-1-enter-active {
  transition: top 0.3s;
  transition-delay: calc(var(--base-animation-delay) + 0s);
}

.m-bottle-2 {
  top: vwMobile(356px);
  right: 0;
  width: vwMobile(92px);
  height: vwMobile(95px);
  transform: translate(-30%, -15%);
}

.m-bottle-2-enter-from {
  top: calc(vwMobile(95px) * -1);
}

.m-bottle-2-enter-active {
  transition: top 0.8s;
  transition-delay: calc(var(--base-animation-delay) + 0s);
}

.box-ring {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .img-ring {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: rotate 4s linear infinite;
  }

  .img {
    width: vwMobile(297px);
    height: vwMobile(384.5px);
    margin-top: vwMobile(83px);

    @include media-breakpoint-up(md) {
      width: vw(651px);
      height: vw(633px);
      margin-top: vw(302px);
    }
  }

  .img-logos {
    position: absolute;
    bottom: vwMobile(28px);
    left: 50%;
    width: vwMobile(258px);
    transform: translateX(-50%);

    @include media-breakpoint-up(md) {
      width: vw(561px);
    }
  }
}

.title {
  margin-bottom: vwMobile(10px);
  font-size: vwMobile(18px);
  line-height: 150%;
  text-align: center;
  color: #fff;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

:deep(.s5-navbar) {
  .menu {
    @include media-breakpoint-down(md) {
      // 非sticky状态移动端不显示第一个
      &.menu-0 {
        display: none;
      }

      &.menu-5,
      &.menu-6 {
        background-color: #f4df23;

        &:disabled,
        &.disabled {
          color: #afafaf;
          background-color: #303030;
        }
      }
    }
  }
}

.box-desc {
  position: relative;
  margin-top: vwMobile(36px);
  font-size: vwMobile(14px);
  line-height: 1.5;
  text-align: center;
  white-space: pre-wrap;
  color: #fff;

  @include media-breakpoint-up(md) {
    padding-top: vw(92px);
    padding-bottom: vw(61px);
    margin-top: 0;
    font-size: vw(24px);
    background-color: #303030;
  }

  .desc {
    &:not(:last-child) {
      margin-bottom: vwMobile(20px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(40px);
      }
    }

    &.m-0-margin {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }

  .bottle-3 {
    position: absolute;
    top: vwMobile(25px);
    left: 0;
    width: vwMobile(89px);
    height: auto;
    transform: translateX(-37%);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
