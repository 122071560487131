$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.page-4 {
  position: relative;
}

.step {
  position: absolute;
  z-index: 1;
  bottom: vwMobile(225px);
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding-right: vwMobile(40px);
  overflow-x: auto;

  .responsive-image {
    width: vwMobile(829px);
  }
}

.button-group {
  position: absolute;
  z-index: 1;
  bottom: vwMobile(90px);
  left: 50%;
  display: flex;
  flex-direction: column;
  width: vwMobile(331px);
  transform: translateX(-50%);

  @include media-breakpoint-up(md) {
    bottom: vw(118px);
    width: vw(331px);
  }

  .button {
    width: 100%;
    height: vwMobile(46px);
    cursor: pointer;

    @include media-breakpoint-up(md) {
      height: vw(61px);
    }

    &:not(:last-child) {
      margin-bottom: vwMobile(10px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(13px);
      }
    }
  }
}

.modal {
  background: rgba(0, 0, 0, .5);

  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: vw(1200px);
    }
  }

  .modal-content {
    position: relative;
    border-radius: vwMobile(12px);

    @include media-breakpoint-up(md) {
      width: vw(1200px);
    }

    .content {
      position: relative;
      max-height: 70vh;
      overflow-y: auto;

      @include media-breakpoint-up(md) {
        max-height: unset;
      }
    }

    .button-close {
      position: absolute;
      z-index: 1;
      top: vwMobile(16px);
      right: vwMobile(22px);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        top: vw(22px);
        right: vw(22px);
        padding: vw(10px);
      }

      img {
        width: vwMobile(24px);
        height: vwMobile(24px);

        @include media-breakpoint-up(md) {
          width: vw(30px);
          height: vw(30px);
        }
      }
    }
  }
}

.modal-2-1,
.modal-4-1 {
  .modal-content {
    padding: vwMobile(55px) vwMobile(5px) vwMobile(29px) vwMobile(4px);

    @include media-breakpoint-up(md) {
      padding: vw(93px) vw(242px) vw(78px);
    }

    .button-next {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 50%;
      width: vwMobile(142px);
      height: vwMobile(46px);
      transform: translateX(-50%);

      @include media-breakpoint-up(md) {
        width: vw(180px);
        height: vw(61px);
      }
    }
  }
}

.modal-2-2,
.modal-4-2 {
  .modal-content {
    padding: vwMobile(55px) vwMobile(18px) vwMobile(50px);
  }
}

.modal-2-2 {
  @include media-breakpoint-up(md) {
    .modal-content {
      padding: vw(93px) vw(140px) vw(58px) vw(133px);
    }
  }
}

.modal-4-2 {
  .modal-content {
    @include media-breakpoint-up(md) {
      padding: vw(93px) vw(137px) vw(44px) vw(67px);
    }
  }
}
