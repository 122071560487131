$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .dialog-coca-cola {
    .color-primary {
      color: #ed2a2b;
    }

    .btn {
      min-height: vw(60px);
      border-radius: vw(30px);
      font-size: vw(26px);
      font-family: AppleSDGothicNeoB;
      letter-spacing: -0.02em;

      @include media-breakpoint-down(md) {
        min-height: 42px;
        border-radius: 21px;
        font-size: 18px;
      }
    }

    .btn-dark {
      border-color: #4b4b4b;
      background: #4b4b4b;
    }

    .btn-primary {
      border-color: #ed2a2b;
      background: #ed2a2b;
    }

    .btn-secondary {
      border-color: #fda30f;
      background: #fda30f;
    }

    .btn-info {
      border-color: #f2f2f2;
      background: #f2f2f2;

      &:hover {
        border-color: #ffd1d1;
        background: #ffd1d1;
      }
    }

    .modal-dialog {
      max-width: vw(800px);

      @include media-breakpoint-down(md) {
        max-width: unset;
      }
    }

    .modal-body {
      position: unset;
      padding: 0;
    }

    .modal-content {
      position: relative;
      padding: vw(90px) vw(70px);
      border-radius: vw(20px);

      .dialog-close-btn {
        position: absolute;
        z-index: 1;
        top: vw(35px);
        right: vw(35px);
        padding: vw(10px);
        cursor: pointer;

        img {
          width: vw(27px);
          height: auto;
        }

        @include media-breakpoint-down(md) {
          top: vwMobile(10px);
          right: vwMobile(10px);
          padding: vwMobile(5px);

          img {
            width: vwMobile(20px);
          }
        }
      }


      @include media-breakpoint-down(md) {
        padding: vwMobile(30px) vwMobile(20px);
        border-radius: vwMobile(14px);
      }
    }

    .modal-header {
      display: none;
    }

    .modal-footer {
      padding: vw(55px) 0 0;

      @include media-breakpoint-down(md) {
        padding: vwMobile(38px) 0 0;
      }

      .dialog-btn-group {
        &.len-2 {
          padding: 0 vw(53px);

          @include media-breakpoint-down(md) {
            padding: 0 vwMobile(15px);
          }

          .btn {
            flex: 1;
            min-height: vw(60px);
            border-radius: vw(30px);

            @include media-breakpoint-down(md) {
              min-height: vwMobile(42px);
              border-radius: vwMobile(21px);
            }

            &:not(:last-child) {
              margin-right: vw(34px);

              @include media-breakpoint-down(md) {
                margin-right: vwMobile(24px);
              }
            }
          }
        }
      }
    }

    .dialog-container {
      min-height: 0;
      padding: 0;

      .dialog-title {
        font-weight: 400;
        font-size: vw(36px);
        font-family: ImcreSoojin;
        line-height: 1.388;

        @include media-breakpoint-down(md) {
          font-size: vwMobile(25px);
        }
      }

      .dialog-content {
        margin-top: vw(37px);
        font-size: vw(26px);
        font-family: AppleSDGothicNeo;
        line-height: 1.53;
        text-align: center;

        @include media-breakpoint-down(md) {
          margin-top: vwMobile(26px);
          font-size: vwMobile(16px);
        }

        .tip {
          margin-top: vw(20px);

          @include media-breakpoint-down(md) {
            font-size: vwMobile(14px);
          }
        }
      }

      .input {
        height: vw(60px);
        margin-top: vw(20px);
        border: none;
        border-radius: 0;
        font-size: vw(26px);
        font-family: inherit;
        background: #eaeaea;
        box-shadow: none;

        @include media-breakpoint-down(md) {
          height: vwMobile(42px);
          margin-top: vwMobile(14px);
          font-size: vwMobile(18px);
        }

        &:focus {
          outline: none;
        }

        &::placeholder {
          text-align: center;
          color: #aeaeae;
        }
      }

      .participate-content {
        .people-collection-type {
          display: flex;

          .btn {
            flex: 1;
            height: vw(180px);
            border-radius: vw(36px);
            font-size: vw(26px);

            @include media-breakpoint-down(md) {
              height: vwMobile(126px);
              border-radius: vwMobile(26px);
              font-size: vwMobile(18px);
            }

            &:not(:last-child) {
              margin-right: vw(17px);

              @include media-breakpoint-down(md) {
                margin-right: vwMobile(12px);
              }
            }

            .big {
              font-size: vw(36px);
              font-family: ImcreSoojin;

              @include media-breakpoint-down(md) {
                font-size: vwMobile(26px);
              }
            }
          }
        }
      }

      .quiz-content {
        margin-top: vw(85px);
        margin-bottom: vw(30px);

        @include media-breakpoint-down(md) {
          margin-top: vwMobile(60px);
          margin-bottom: vwMobile(21px);
        }

        .choice-btn {
          width: 100%;
          min-height: vw(80px);
          padding: vw(18px) vw(90px);
          margin-bottom: vw(15px);
          line-height: 1.35;
          text-align: center;
          color: #000000;

          &:last-child {
            margin-bottom: 0;
          }

          @include media-breakpoint-down(md) {
            min-height: vwMobile(56px);
            padding: vwMobile(10px) vwMobile(20px);
            margin-bottom: vwMobile(10px);
          }
        }
      }
    }
  }

  .dialog-coca-cola-completed {
    .modal-footer {
      .dialog-btn-group.len-2 {
        display: flex;
        flex-direction: column;

        .btn {
          flex: unset;
          width: 100%;
          margin: 0;

          &:not(:last-child) {
            margin: 0 0 vw(17px);

            @include media-breakpoint-down(md) {
              margin: 0 0 vwMobile(12px);
            }
          }
        }
      }
    }
  }
