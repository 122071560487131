$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 vwMobile(15px) vwMobile(45px);
    background-image: url('./images/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #fdb515;

    @include media-breakpoint-up(xl) {
      > .container {
        max-width: vw(1208px);
      }
    }

    @include media-breakpoint-up(md) {
      padding: 0 0 vw(144px);
      background-image: url('./images/bg-pc.png');
      background-size: cover;
    }

    .section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(240px);
      height: vwMobile(36px);
      margin: 0 auto;
      border-radius: vwMobile(18px);
      font-size: vwMobile(18px);
      font-family: ImcreSoojin;
      text-align: center;
      color: #fff;
      background: #fda30f;

      @include media-breakpoint-up(md) {
        width: vw(500px);
        height: vw(89px);
        border-radius: vw(50px);
        font-size: vw(46px);
      }

      span:last-child {
        color: $black;
      }
    }

    .section-3-1 {
      .section-title {
        margin-top: vwMobile(53px);
        margin-bottom: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-top: vw(102px);
          margin-bottom: vw(135px);
        }
      }

      .box-cycle {
        @include media-breakpoint-up(md) {
          display: flex;
        }

        .cycle-card {
          position: relative;
          margin-top: vwMobile(40px);

          @include media-breakpoint-up(md) {
            flex: 1;
            flex-shrink: 0;
            padding: vw(33px) vw(16px) vw(82px) vw(32px);
            margin-top: 0;
            border-radius: vw(36px);
            background: #fafafa;
            box-shadow: vw(8px) vw(10px) vw(20px) rgba(253, 181, 21, 0.44);

            &:not(:last-child) {
              margin-right: vw(30px);
            }
          }

          .card-title {
            margin-bottom: vwMobile(10px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            text-align: center;
            color: $black;

            @include media-breakpoint-up(md) {
              margin-bottom: vw(19px);
              font-size: vw(30px);
              line-height: 1.93;
              text-align: left;
            }
          }

          .card-content {
            padding: vwMobile(23px) vwMobile(20px) vwMobile(23px) vwMobile(45px);
            border-radius: vwMobile(15px);
            background: #fafafa;
            box-shadow: vwMobile(8px) vwMobile(10px) vwMobile(20px) rgba(253, 181, 21, 0.44);

            @include media-breakpoint-up(md) {
              padding: 0;
              border-radius: 0;
              background: transparent;
              box-shadow: none;
            }

            .item {
              display: flex;
              align-items: center;
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoB;
              letter-spacing: -0.03em;
              color: #515151;

              @include media-breakpoint-up(md) {
                font-size: vw(20px);
                line-height: 1.85;
              }

              &:not(:last-child) {
                margin-bottom: vwMobile(20px);

                @include media-breakpoint-up(md) {
                  margin-bottom: vw(42px);
                }
              }

              span {
                &:first-child {
                  min-width: vwMobile(80px);
                  margin-right: vwMobile(22px);
                  font-family: ImcreSoojin;
                  color: $red;

                  @include media-breakpoint-up(md) {
                    min-width: vw(96px);
                    margin-right: vw(10px);
                  }
                }
              }
            }
          }
        }
      }

      .notice {
        margin-top: vwMobile(41px);

        @include media-breakpoint-up(md) {
          margin-top: vw(50px);
        }

        .notice-title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: vwMobile(118px);
          height: vwMobile(36px);
          margin: 0 auto;
          margin-bottom: vwMobile(9px);
          border-radius: vwMobile(18px);
          font-size: vwMobile(16px);
          font-family: ImcreSoojin;
          color: #fff;
          background: $red;

          @include media-breakpoint-up(md) {
            width: vw(119px);
            height: vw(40px);
            margin-bottom: vw(40px);
            border-radius: vw(22px);
            font-size: vw(23px);
          }
        }

        .notice-content {
          font-size: vwMobile(14px);
          line-height: 2;
          text-align: center;
          letter-spacing: -0.03em;
          color: $black;

          @include media-breakpoint-up(md) {
            font-size: vw(18px);
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .section-3-2 {
      margin-top: vwMobile(110px);

      @include media-breakpoint-up(md) {
        margin-top: vw(186px);
      }

      .box-step {
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 vw(22px);
        }

        .step-card-container {
          margin-top: vwMobile(85px);

          @include media-breakpoint-up(md) {
            position: relative;
            width: 48.7%;
            margin-top: vw(160px);
          }
        }

        .step-card-container-5 {
          @include media-breakpoint-up(md) {
            width: 100%;

            .step-card.step-5 {
              padding-top: vw(142px);
              padding-bottom: vw(65px);

              .card-name {
                margin-bottom: vw(80px);
              }

              .card-content {
                &:not(:first-child) {
                  padding-top: vw(38px);
                  padding-right: vw(66px);
                  padding-bottom: vw(36px);
                  padding-left: vw(66px);
                  margin-top: vw(30px);
                }

                > .p .box-strong {
                  .strong {
                    line-height: 2;
                  }
                }
              }
            }
          }
        }

        .tip {
          padding: 0;
          margin-top: vwMobile(20px);
          font-size: vwMobile(12px);
          line-height: 1.66;
          text-align: center;
          letter-spacing: -0.03em;

          @include media-breakpoint-up(md) {
            position: absolute;
            bottom: vw(-22px);
            left: 0;
            padding: 0 vw(26px);
            margin-top: 0;
            font-size: vw(16px);
            line-height: 1.56;
            text-align: left;
            transform: translateY(100%);
          }
        }

        .step-card {
          position: relative;
          padding: vwMobile(78px) vwMobile(17px) vwMobile(31px);
          border-radius: vwMobile(36px);
          white-space: nowrap;
          background: #fafafa;
          box-shadow: vwMobile(8px) vwMobile(10px) vwMobile(20px) rgba(253, 181, 21, 0.44);

          @include media-breakpoint-up(md) {
            height: 100%;
            padding: vw(111px) vw(26px) vw(43px);
            border-radius: vw(36px);
            box-shadow: vw(8px) vw(10px) vw(20px) rgba(253, 181, 21, 0.44);
          }

          .step-name {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: vwMobile(90px);
            height: vwMobile(90px);
            border-radius: 50%;
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            color: #fff;
            background-color: $red;
            transform: translate(-50%, -50%);

            @include media-breakpoint-up(md) {
              width: vw(144px);
              height: vw(144px);
              font-size: vw(26px);
            }
          }

          .card-name {
            display: flex;
            align-items: center;
            margin-bottom: vwMobile(35px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            letter-spacing: -0.02em;

            @include media-breakpoint-up(md) {
              margin-bottom: vw(44px);
              font-size: vw(30px);
            }

            .strong {
              color: $red;
            }

            &:before,
            &:after {
              flex: 1;
              height: 1px;
              background-color: #231f20;
              content: '';
            }

            &:before {
              margin-right: vwMobile(14px);

              @include media-breakpoint-up(md) {
                margin-right: vw(13px);
              }
            }

            &:after {
              margin-left: vwMobile(14px);

              @include media-breakpoint-up(md) {
                margin-left: vw(13px);
              }
            }
          }

          .box-index {
            display: flex;
            align-items: center;
            margin-top: vwMobile(27px);
            margin-bottom: vwMobile(10px);
            font-size: vwMobile(16px);
            font-family: ImcreSoojin;
            color: $red;

            @include media-breakpoint-up(md) {
              margin-top: vw(60px);
              margin-bottom: vw(36px);
              font-size: vw(26px);
            }

            .index-name {
              display: flex;
              justify-content: center;
              align-items: center;
              width: vwMobile(22px);
              height: vwMobile(22px);
              margin-right: vwMobile(5px);
              border-radius: 50%;
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoB;
              line-height: normal;
              color: #fff;
              background-color: $red;

              @include media-breakpoint-up(md) {
                width: vw(36px);
                height: vw(36px);
                margin-right: vw(8px);
                font-size: vw(26px);
              }
            }
          }

          .card-content {
            padding: vwMobile(16px) 0 vwMobile(14px) vwMobile(16px);
            border-radius: vwMobile(16px);
            font-size: vwMobile(16px);
            letter-spacing: -0.01em;
            background-color: #fff;

            @include media-breakpoint-up(md) {
              padding: vw(30px) 0 vw(30px) vw(40px);
              border-radius: vw(16px);
              font-size: vw(26px);
            }

            > .p:not(.small) {
              font-family: AppleSDGothicNeoM;
            }

            .p {
              display: flex;
              margin-bottom: vwMobile(3px);
              line-height: 1.625;

              @include media-breakpoint-up(md) {
                margin-bottom: vw(5px);
                line-height: 1.84;
              }

              .symbol {
                flex-shrink: 0;
                margin-right: vwMobile(3px);

                @include media-breakpoint-up(md) {
                  margin-right: vw(5px);
                }
              }

              .content {
                flex: 1;
              }

              &.small {
                padding-left: vwMobile(5px);
                font-size: vwMobile(12px);
                line-height: 1.66;
                letter-spacing: -0.05em;
                color: #515151;

                @include media-breakpoint-up(md) {
                  padding-left: vw(5px);
                  font-size: vw(16px);
                  line-height: 1.56;
                }
              }
            }
          }

          .card-tip {
            margin-top: vwMobile(21px);
            font-size: vwMobile(12px);
            line-height: 1.66;
            text-align: center;
            letter-spacing: -0.03em;
            color: $red;

            @include media-breakpoint-up(md) {
              margin-top: vw(20px);
              font-size: vw(16px);
              line-height: 1.625;
              text-align: left;
            }
          }
        }

        .step-2 {
          .card-content.has-img {
            position: relative;

            .img-product {
              position: absolute;
              bottom: 0;
              right: vwMobile(-10px);
              width: vwMobile(83px);
              height: auto;
              transform: translateY(40%);

              @include media-breakpoint-up(md) {
                right: vw(-10px);
                width: vw(126px);
                transform: translateY(0%);
              }
            }
          }
        }

        .step-3,
        .step-4,
        .step-5 {
          padding-bottom: vwMobile(21px);

          @include media-breakpoint-up(md) {
            padding-bottom: vw(56px);
          }

          .card-name {
            margin-bottom: vwMobile(16px);

            @include media-breakpoint-up(md) {
              margin-bottom: vw(40px);
            }
          }

          .card-content {
            padding: vwMobile(12px) 0 vwMobile(11px) vwMobile(8px);
            line-height: 1.62;

            @include media-breakpoint-up(md) {
              padding: vw(13px) 0 vw(16px) vw(14px);
              line-height: 1.5;
            }

            &:not(:first-child) {
              margin-top: vwMobile(5px);

              @include media-breakpoint-up(md) {
                margin-top: vw(17px);
              }
            }

            > .p {
              font-size: vwMobile(16px);
              font-family: AppleSDGothicNeoM;
              line-height: vwMobile(26px);

              @include media-breakpoint-up(md) {
                font-size: vw(26px);
                line-height: 1.53;
              }

              > .symbol {
                display: flex;
                justify-content: center;
                align-items: center;
                width: vwMobile(22px);
                height: vwMobile(22px);
                margin-top: vwMobile(3px);
                margin-right: vwMobile(8px);
                border-radius: 50%;
                font-family: AppleSDGothicNeoB;
                line-height: normal;
                color: #fff;
                background-color: #000000;

                @include media-breakpoint-up(md) {
                  width: vw(36px);
                  height: vw(36px);
                  margin-top: vw(2px);
                  margin-right: vw(15px);
                }
              }

              .box-strong {
                margin-top: vwMobile(8px);

                @include media-breakpoint-up(md) {
                  margin-top: vw(10px);
                }

                .strong {
                  display: flex;
                  font-size: vwMobile(12px);
                  font-family: AppleSDGothicNeoR;
                  line-height: 1.83;
                  letter-spacing: -0.06em;
                  color: $red;

                  @include media-breakpoint-up(md) {
                    font-size: vw(16px);
                    line-height: 1.62;
                  }

                  .symbol {
                    flex-shrink: 0;
                    margin-right: vwMobile(2px);

                    @include media-breakpoint-up(md) {
                      margin-right: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
