$primary: #006225;

@function vwMobile($target) {
  @if unit($target) == px {
    @return $target / 375px * 100vw;
  }
}

// 移动端设计稿尺寸宽1920px
@function vw($target) {
  @if unit($target) == px {
    $vw: $target / 1920px * 100vw;

    @if ($target < 0px) {
      @return max($vw, $target);
    }

    @else {
      @return min($vw, $target);
    }
  }

  @else {
    @return $target;
  }
}
