$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.5s;
}

.modal-fade-enter-from, .modal-fade-leave-to {
  opacity: 0;
}

.cocacola-s5-modal {
  position: fixed;
  // 比 float 高
  z-index: calc(z('float', 'base') + 1);
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: vwMobile(10px) 0;
  overflow: auto;
  background: rgba(0, 0, 0, 40%);

  @include media-breakpoint-up(md) {
    padding: vw(30px) 0;
  }
}

.cocacola-s5-modal-body {
  display: flex;
  flex-direction: column;
  width: vwMobile(370px);
  max-width: 100%;
  // 这里变量名叫modal-min-height是因为后来样式改了，避免影响其他使用此popup先不改名字了。
  height: var(--modal-min-height, unset);
  max-height: 100%;
  margin: auto;
  border-radius: vwMobile(16px);
  overflow: hidden;
  overflow-y: auto;
  font-size: vwMobile(14px);
  font-family: "SB AggroOTF";
  color: #303030;
  background-color: #fff;

  @include media-breakpoint-up(md) {

    --scrollbar-w: 6px;
    width: vw(530px);
    border-radius: vw(16px);
    font-size: vw(18px);

    &::-webkit-scrollbar {
      width: var(--scrollbar-w);
      height: var(--scrollbar-w);
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--scrollbar-w);
      box-shadow: 0 0 0 gray inset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: calc(var(--scrollbar-w) * 0.5);
      background-color: #c1c1c1;
      box-shadow: inset 0 0 var(--scrollbar-w) #c1c1c1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a1a1a1;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #a1a1a1;
    }
  }
}

.cocacola-s5-modal-header {
  display: flex;
  justify-content: flex-end;
  padding: vwMobile(8px) vwMobile(5px);

  @include media-breakpoint-up(md) {
    padding: vw(10px);
  }

  .button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: vwMobile(40px);
    height: vwMobile(40px);
    line-height: 1;

    @include media-breakpoint-up(md) {
      width: vw(50px);
      height: vw(50px);
    }

    svg {
      width: vwMobile(20px);
      height: auto;

      @include media-breakpoint-up(md) {
        width: vw(25px);
      }
    }
  }
}

.cocacola-s5-modal-content {
  position: relative;

  &.title-only {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .cocacola-s5-modal-title {
    font-weight: 500;
    font-size: vwMobile(18px);
    line-height: 1.5;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: vw(24px);
    }
  }
}

.cocacola-s5-modal-footer {
  position: relative;
  padding-bottom: vwMobile(21px);
  margin-top: auto;

  @include media-breakpoint-up(md) {
    padding-bottom: vw(42px);
  }

  :deep(.footer-btn) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: vwMobile(47px);
    padding: 0 vwMobile(22px);
    border-radius: vwMobile(35px);
    font-size: vwMobile(18px);
    line-height: normal;
    color: #fff;
    background-color: #303030;
    transition: background-color .3s;

    @include media-breakpoint-up(md) {
      height: vw(46px);
      padding: 0 vw(22px);
      border-radius: vw(35px);
      font-size: vw(18px);
    }

    &.primary {
      background-color: #b01614;
    }

    &.disabled,
    &:disabled {
      background-color: #a4a4a4;
      opacity: 1;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  :deep(.footer-btn-group) {
    display: flex;
    justify-content: center;
    gap: vwMobile(10px);

    @include media-breakpoint-up(md) {
      gap: vw(10px);
    }

    &.column {
      flex-direction: column;
      align-items: center;
      gap: vwMobile(12px);

      @include media-breakpoint-up(md) {
        gap: vw(12px);
      }
    }
  }
}
