$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  $page-padding-left: vwMobile(20px);
  $page-padding-right: vwMobile(20px);

  .page-5 {
    padding-top: vwMobile(46px);
    padding-bottom: vwMobile(55px);

    .container {
      @include media-breakpoint-up(xl) {
        max-width: vw(1170px);
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: vw(118px);
      padding-bottom: 0;
      background: #fafafa;
    }

    > .container {
      > .title {
        margin-bottom: vwMobile(17px);
        font-size: vwMobile(18px);
        font-family: ImcreSoojin;
        line-height: 1;
        text-align: center;
        color: #010101;

        .strong {
          color: #f62834;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: vw(47px);
          font-size: vw(46px);
        }
      }

      > .subtitle {
        margin-bottom: vwMobile(43px);
        font-size: vwMobile(12px);
        line-height: 1;
        text-align: center;

        @include media-breakpoint-up(md) {
          margin-bottom: vw(80px);
          font-size: vw(24px);
        }
      }
    }
  }

  .section-paper {
    padding-right: $page-padding-right;
    padding-left: $page-padding-left;

    @include media-breakpoint-up(md) {
      padding: vw(78px) vw(65px) 0;
      margin-bottom: vw(132px);
      border-radius: vw(36px);
      background: #ffffff;
      box-shadow: vw(3px) vw(4px) vw(22px) rgba(145, 145, 145, 0.25);
    }
  }

  .section-1 {
    padding-bottom: vwMobile(34px);
    text-align: center;

    @include media-breakpoint-up(md) {
      padding-bottom: vw(96px);
      border-bottom: 1px solid #d2d2d2;
    }

    .img-bottles {
      width: vwMobile(229px);
      margin-bottom: vwMobile(11px);

      @include media-breakpoint-up(md) {
        width: vw(573px);
        margin-bottom: vw(32px);
      }
    }

    .img-title {
      margin-bottom: vwMobile(6px);
      font-size: vwMobile(16px);
      font-family: ImcreSoojin;
      line-height: 1;
      color: $black;

      @include media-breakpoint-up(md) {
        margin-bottom: vw(22px);
        font-size: vw(32px);
        line-height: 1.4;
      }
    }

    .img-desc {
      font-size: vwMobile(14px);
      line-height: 1.71;

      @include media-breakpoint-up(md) {
        font-size: vw(26px);
      }

      .strong {
        color: $red;
      }
    }
  }

  .section-2 {
    padding-top: vwMobile(55px);
    padding-bottom: vwMobile(44px);
    text-align: center;

    @include media-breakpoint-up(md) {
      padding-top: vw(164px);
      padding-bottom: vw(32px);
    }

    .box-notice {
      position: relative;
      padding: vwMobile(18px) vwMobile(20px);
      border-radius: vwMobile(10px);
      font-size: vwMobile(14px);
      line-height: 1.42;
      letter-spacing: -0.03em;
      color: #fff;
      background: $red;

      @include media-breakpoint-down(md) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      @include media-breakpoint-up(md) {
        padding: vw(28px) vw(20px);
        margin: 0 vw(27px);
        border-radius: vw(16px);
        font-size: vw(26px);
        line-height: 1.76;
      }

      .notice-title {
        position: absolute;
        top: 0;
        left: 50%;
        width: vwMobile(65px);
        height: vwMobile(31px);
        border-radius: vwMobile(57px);
        font-size: vwMobile(14px);
        font-family: ImcreSoojin;
        line-height: 1.71;
        text-align: center;
        color: #fff;
        background: $red;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(md) {
          top: vw(-22px);
          width: vw(126px);
          height: vw(61px);
          border-radius: vw(30px);
          font-size: vw(37px);
          transform: translate(-50%, -100%);
        }
      }

      p {
        margin: 0;
      }

      .color {
        color: #ffb538;
      }
    }

    .box-steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: vwMobile(35px + 30px);
      padding-right: vwMobile(14px);
      padding-bottom: vwMobile(30px);
      padding-left: vwMobile(14px);
      margin-top: vwMobile(-35px);
      border-radius: vwMobile(10px);
      background: #fafafa;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
        padding-top: vw(94px + 49px);
        padding-right: vw(30px);
        padding-bottom: vw(28px);
        padding-left: vw(30px);
        margin-top: vw(-94px);
        border-radius: vw(16px);
      }

      .step-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: vwMobile(26px);
        padding: 0 vwMobile(6px);
        margin-bottom: vwMobile(24px);
        border-radius: vwMobile(13px);
        font-size: vwMobile(12px);
        font-family: ImcreSoojin;
        color: #fff;
        background: #fdb515;

        @include media-breakpoint-up(md) {
          height: vw(47px);
          padding: 0 vw(10px);
          margin-bottom: vw(22px);
          border-radius: vw(24px);
          font-size: vw(20px);
          background: $red;
        }
      }

      .step-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
          flex-wrap: nowrap;
          width: 100%;
        }

        .item-step {
          margin-bottom: vwMobile(25px);

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          @include media-breakpoint-down(md) {
            &:nth-child(1) {
              margin-left: vwMobile(52px);
            }

            &:nth-child(2) {
              margin-right: vwMobile(52px);
            }
          }
        }
      }

      .item-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: vwMobile(12px);
        line-height: 1.25;
        color: $red;

        @include media-breakpoint-up(md) {
          flex: 1;
          justify-content: space-between;
          font-size: vw(18px);
          line-height: 1.5;
        }

        .box-name {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        img {
          width: vwMobile(55px);
          margin-bottom: vwMobile(6px);

          @include media-breakpoint-up(md) {
            width: vw(170px);
            margin-bottom: vw(22px);
          }
        }
      }
    }

    .box-desc {
      padding-right: vwMobile(18px);
      padding-left: vwMobile(18px);
      margin-top: vwMobile(19px);
      font-size: vwMobile(12px);
      line-height: 2;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 0;
        margin-top: vw(27px);
        font-size: vw(18px);
        line-height: 1.66;
        text-align: left;
      }

      p {
        margin: 0;

        &:not(:first-child) {
          &:before {
            content: '•';
          }
        }
      }
    }
  }

  .section-3,
  .section-4 {
    @include media-breakpoint-up(md) {
      background: #fff;

      > .container {
        max-width: vw(1057px);
      }
    }
  }

  .section-3 {
    padding-top: vwMobile(29px);
    padding-bottom: vwMobile(63px);
    text-align: center;
    box-shadow: vwMobile(3px) vwMobile(4px) vwMobile(16px) rgba(145, 145, 145, 0.25);

    @include media-breakpoint-up(md) {
      padding-top: vw(106px);
      padding-bottom: vw(150px);
      box-shadow: none;
    }

    .section-icon {
      width: vwMobile(52px);
      margin-bottom: vwMobile(32px);

      @include media-breakpoint-up(md) {
        width: vw(62px);
        margin-bottom: vw(44px);
      }
    }

    .section-title,
    .section-subtitle {
      font-size: vwMobile(18px);
      font-family: ImcreSoojin;
      line-height: 1.55;
      letter-spacing: -0.02em;

      @include media-breakpoint-up(md) {
        font-size: vw(46px);
        line-height: 1.67;
      }
    }

    .section-title {
      color: $red;
    }

    .box-process {
      display: flex;
      margin-top: vwMobile(40px);
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-up(md) {
        justify-content: center;
        margin-top: vw(90px);
        overflow: visible;
      }

      .item-card {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-right: vwMobile(20px);
        font-size: vwMobile(16px);
        line-height: 1.25;
        color: #515151;

        @include media-breakpoint-up(md) {
          align-items: center;
          width: vw(286px);
          margin-right: 0;
          font-size: vw(23px);
          line-height: 1.6;

          .hidden-up-md {
            display: none;
          }

          &:not(:last-child) {
            margin-right: vw(70px);
          }
        }

        @include media-breakpoint-down(md) {
          &:first-child {
            margin-left: vwMobile(30px);
          }

          &:last-child {
            margin-right: vwMobile(30px);
          }
        }

        img {
          width: vwMobile(286px);
          margin-bottom: vwMobile(17px);

          @include media-breakpoint-up(md) {
            width: 100%;
            margin-bottom: vw(28px);
          }
        }

        .name {
          letter-spacing: -0.02em;

          @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            font-family: AppleSDGothicNeoB;
          }
        }

        .index {
          position: absolute;
          top: vwMobile(20px);
          left: vwMobile(10px);
          display: flex;
          justify-content: center;
          align-items: center;
          width: vwMobile(50px);
          height: vwMobile(50px);
          border-radius: 50%;
          font-size: vwMobile(16px);
          font-family: ImcreSoojin;
          letter-spacing: -0.02em;
          color: #fff;
          background: $red;

          @include media-breakpoint-up(md) {
            top: vw(-10px);
            left: vw(-20px);
            width: vw(109px);
            height: vw(109px);
            font-size: vw(52px);
          }
        }
      }
    }
  }

  .section-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: vwMobile(55px) vwMobile(30px);

    @include media-breakpoint-up(md) {
      padding: 0;

      > .container {
        padding-top: vw(115px);
        padding-bottom: vw(95px);
        border-top: 1px solid #d2d2d2;
      }
    }

    .icon-tip {
      display: block;
      width: vwMobile(100px);
      height: auto;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        width: vw(155px);
      }
    }

    .img {
      width: 100%;
      margin-top: vwMobile(29px);
      margin-bottom: vwMobile(21px);

      @include media-breakpoint-up(md) {
        margin-top: vw(59px);
        margin-bottom: vw(51px);
      }
    }

    .desc {
      margin: 0;
      font-size: vwMobile(16px);
      font-family: ImcreSoojin;
      line-height: 1.6;
      text-align: center;
      letter-spacing: -0.02em;
      color: #fdb515;

      @include media-breakpoint-up(md) {
        font-size: vw(24px);
      }
    }
  }
