$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}
@import './vars.scss';

.dialog-campaign-cocacola-s4 {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 594px;
    }
  }

  .modal-header {
    display: none;
  }

  .modal-content {
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .modal-footer {
    padding-top: 0;
    padding-bottom: 56px;

    @include media-breakpoint-down(md) {
      padding-bottom: 36px;
    }
  }

  .dialog-container {
    min-height: 80px;
    margin-top: 28px;

    .dialog-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
      font-size: 24px;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
  }

  .dialog-btn-group {
    .btn {
      min-height: 45px;
      font-size: 20px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }
}

// 步骤弹窗
.dialog-campaign-participate-step-1,
.dialog-campaign-participate-step-2,
.dialog-campaign-participate-step-3,
.dialog-campaign-participate-success {
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: vw(1200px);
    }
  }

  .modal-content {
    width: vwMobile(350px);
    border: none;
    border-radius: vwMobile(12px);
    font-weight: 400;
    font-size: vwMobile(12px);
    font-family: Noto Sans KR;
    line-height: 1.2307;
    color: #030303;

    @include media-breakpoint-up(md) {
      width: vw(1200px);
      border-radius: vw(30px);
      font-size: vw(24px);
      line-height: 1.4545;
    }

    .modal-header {
      min-height: vwMobile(38px);
      padding: vwMobile(18px) vwMobile(18px) vwMobile(13px);

      @include media-breakpoint-up(md) {
        min-height: vw(136px);
        padding: vw(32px) vw(32px) vw(28px);
      }

      .btn-close {
        display: none;
        width: vwMobile(24px);
        height: vwMobile(24px);
        padding: 0;
        margin: 0 0 0 auto;
        border-radius: 0;
        background-image: url('./components/icon-close.png');
        background-size: 100%;
        opacity: 1;

        @include media-breakpoint-up(md) {
          width: vw(30px);
          height: vw(30px);
        }
      }
    }

    .modal-body {
      min-height: vwMobile(100px);
      padding: 0 vwMobile(16px);
      text-align: center;

      @include media-breakpoint-up(md) {
        min-height: vw(100px);
        padding: 0 vw(20px);
      }
    }

    .modal-footer {
      padding-top: 0;
      padding-right: vwMobile(21px);
      padding-bottom: vwMobile(43px);
      padding-left: vwMobile(21px);

      @include media-breakpoint-up(md) {
        padding-right: vw(40px);
        padding-bottom: vw(94px);
        padding-left: vw(40px);
      }

      .btn-danger {
        border-color: $primary-color;
        background-color: $primary-color;
      }

      .dialog-btn-group {
        padding: 0;
        margin: 0;

        .btn {
          box-sizing: border-box;
          width: vwMobile(260px);
          min-height: vwMobile(45px);
          padding: vwMobile(5px);
          margin: 0;
          font-weight: 700;
          font-size: vwMobile(16px);
          font-family: inherit;
          color: #fff;

          &:not(:last-child) {
            margin-right: vwMobile(10px);

            @include media-breakpoint-up(md) {
              margin-right: vw(10px);
            }
          }

          @include media-breakpoint-up(md) {
            width: vw(220px);
            min-height: vw(61px);
            padding: vw(5px);
            font-size: vw(24px);
          }
        }
      }
    }

    .dialog-container {
      flex-direction: column;
      width: 100%;
      min-height: 100%;
      padding: 0;

      .dialog-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: vwMobile(15px) 0;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
          padding: vw(30px) 0;
        }
      }

      .dialog-title {
        font-weight: 400;
        font-size: vwMobile(27px);
        font-family: SB AggroOTF;
        line-height: 1.2;
        text-align: left;

        @include media-breakpoint-up(md) {
          font-size: vw(40px);
          text-align: center;
        }
      }
    }

    .dialog-content {
      margin: 0;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }

  &.dialog-campaign-participate-step-1 {
    .modal-header {
      min-height: unset;

      .btn-close {
        display: block;
      }
    }

    .modal-content {
      @include media-breakpoint-down(md) {
        width: vwMobile(360px);

        .dialog-title {
          // 移动端要强制显示一行，不能让字掉下来
          white-space: nowrap;
        }
      }
    }

    .modal-footer {
      padding-bottom: vwMobile(33px);

      @include media-breakpoint-up(md) {
        padding-bottom: vw(49px);
      }
    }

    .desc {
      margin-bottom: vwMobile(46px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(53px);
      }

      p {
        margin: 0;
        font-size: vwMobile(12px);
        line-height: 1.4444;
        text-align: left;

        @include media-breakpoint-up(md) {
          font-size: vw(18px);
          text-align: center;

          &:before {
            white-space: pre;
            content: ' • ';
          }
        }
      }
    }

    .choose-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: vwMobile(20px);

      &:not(.has-desc) {
        margin-top: vwMobile(20px);

        @include media-breakpoint-up(md) {
          margin-top: vw(20px);
        }
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: 0 vw(226px);
        margin-bottom: vw(38px);
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: vwMobile(299px);
        height: vwMobile(79px);
        border: 2px solid #030303;
        border-radius: vwMobile(16px);
        font-weight: 400;
        font-size: 20px;
        font-family: 'SB AggroOTF';
        line-height: 1.2;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

        @include media-breakpoint-up(md) {
          position: relative;
          flex: 1;
          flex-shrink: 0;
          width: unset;
          height: unset;
          border-radius: vw(16px);
          font-size: vw(40px);
        }

        .text {
          @include media-breakpoint-up(md) {
            position: absolute;
            top: 13.98%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
          }
        }

        &:not(:last-child) {
          margin-bottom: vwMobile(13px);

          @include media-breakpoint-up(md) {
            margin-right: vw(20px);
            margin-bottom: 0;
          }
        }

        &:hover {
          background-color: rgba(210, 45, 44, 0.2);
        }

        &.active {
          background-color: $primary-color;

          .text {
            color: #fff;
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &.dialog-campaign-participate-step-2 {
    .modal-body {
      @include media-breakpoint-down(md) {
        padding-left: vwMobile(16px);
      }
    }

    .modal-content {
      min-height: unset;

      @include media-breakpoint-up(md) {
        height: vw(600px);

        .desc {
          font-size: vw(22px);
        }
      }

      @include media-breakpoint-down(md) {
        .dialog-container {
          .dialog-content {
            margin-left: vwMobile(-5px);
          }
        }
      }
    }

    .desc {
      padding-top: vwMobile(6px);
      padding-bottom: vwMobile(31px);
      text-align: left;

      @include media-breakpoint-up(md) {
        padding-top: vw(30px);
        padding-bottom: vw(30px);
        text-align: center;
      }

      p {
        display: flex;
        margin: 0;

        @include media-breakpoint-up(md) {
          display: block;
          line-height: 1.4444;
        }

        &:before {
          white-space: pre;
          content: ' •  ';
        }
      }
    }
  }

  &.dialog-campaign-participate-step-3 {
    .modal-footer {
      padding-bottom: vwMobile(33px);

      @include media-breakpoint-up(md) {
        padding-bottom: vw(87px);
      }
    }

    .dialog-container {
      .dialog-content {
        padding-top: vwMobile(20px);
        padding-bottom: vwMobile(26px);

        @include media-breakpoint-up(md) {
          padding-top: vw(93px);
          padding-bottom: vw(74px);
        }

        fieldset {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }

          legend {
            width: unset;
            padding: 0;
            margin-bottom: vwMobile(10px);
            font-weight: 400;
            font-size: vwMobile(16px);
            font-family: SB AggroOTF;
            text-align: left;

            @include media-breakpoint-up(md) {
              min-width: 10em;
              margin-right: vw(10px);
              margin-bottom: 0;
              font-size: vw(26px);
              text-align: right;
            }
          }

          .form-control,
          .form-select {
            height: vwMobile(50px);
            padding-top: 0;
            padding-bottom: 0;
            border-radius: vwMobile(10px);
            font-size: inherit;
            box-shadow: none;

            @include media-breakpoint-up(md) {
              height: vw(50px);
              border-radius: vw(10px);
            }
          }

          > div {
            flex: 1;

            @include media-breakpoint-up(md) {
              max-width: vw(400px);
            }
          }
        }
      }
    }
  }

  &.dialog-campaign-participate-success {
    .modal-content .dialog-container {
      .dialog-title {
        text-align: center;
      }

      .dialog-content {
        @include media-breakpoint-down(md) {
          padding: vw(23px) 0 vw(37px);
        }

        @include media-breakpoint-up(md) {
          font-size: vw(27px);
        }
      }
    }
  }
}
