$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;
  }

  .page-title {
    font-weight: bold;
    font-size: 24px;
    color: $primary-darker;
  }

  .new-button {
    flex-shrink: 0;
    padding: 12px 21px 11px;
    border: 1px solid $primary;
    border-radius: 24px;
    font-size: 18px;
    text-decoration: none;
    color: $primary;
  }

  .item-card {
    max-width: 640px;
    padding: 23px 29px;
    margin-bottom: 25px;
    border: 1px solid $gray-light;
    border-radius: 20px;
    font-size: 18px;

    .cell {
      display: flex;
      margin-bottom: 22px;

      &.wrap {
        flex-wrap: wrap;
      }
    }

    .label {
      width: 120px;
      margin-right: 20px;
      color: #999;
    }

    .value {
      flex: 1;
    }

    .qrcode {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 35px;
      border-top: 1px solid #ddd;

      .image {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .down-btn {
        padding: 16px 33px;
        margin-top: 30px;
      }
    }
  }
