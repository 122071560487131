$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .page {
    padding: vw(50px) 0;
    font-family: Noto Sans KR;
    text-align: center;
    color: #000000;
    background: #f4f4f4;

    @include media-breakpoint-down(md) {
      padding: vwMobile(50px) vwMobile(18px);
    }

    > .container {
      width: 100%;
      max-width: 100%;
      padding: 0;

      @include media-breakpoint-up(md) {
        max-width: vw(1320px);
      }
    }
  }

  .logo {
    width: vw(208px);
    height: auto;
    margin-bottom: vw(125px);

    @include media-breakpoint-down(md) {
      width: vwMobile(117px);
      margin-bottom: vwMobile(84px);
    }
  }

  .title {
    margin-bottom: vw(25px);
    font-size: vw(48px);
    font-family: SCDream8;
    line-height: 1.15;

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(25px);
      font-size: vwMobile(20px);
      font-family: SCDream6;
    }
  }

  .sub-title {
    margin-bottom: vw(78px);
    font-weight: 500;
    font-size: vw(26px);
    line-height: 1.35;

    @include media-breakpoint-down(md) {
      margin-bottom: vwMobile(65px);
      font-weight: 400;
      font-size: vwMobile(16px);
    }
  }

  .program-img {
    width: vw(486px);

    @include media-breakpoint-down(md) {
      width: vwMobile(260px);
    }
  }

  .text-card {
    position: relative;
    padding: vw(93px) vw(150px);
    margin-top: vw(127px);
    border: none;
    border-radius: vw(36px);
    font-size: vw(26px);
    line-height: 1.73;
    background: #ffffff;
    box-shadow: none;

    .card-content {
      font-weight: 500;
    }

    @include media-breakpoint-down(md) {
      position: unset;
      padding: 0;
      margin-top: vwMobile(73px);
      margin-right: 0;
      margin-left: 0;
      border-radius: 0;
      font-size: vwMobile(14px);
      background: none;

      .card-content {
        position: relative;
        padding: vwMobile(50px) vwMobile(10px) vwMobile(40px);
        border-radius: vwMobile(12px);
        font-weight: 400;
        background: #fff;
      }
    }

    p {
      margin: 0;
    }

    :deep(.strong), :deep(strong) {
      font-weight: 700;
    }

    .card-name {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: vw(66px);
      padding: 0 vw(60px);
      border-radius: vw(103px);
      font-weight: 700;
      font-size: vw(36px);
      white-space: nowrap;
      color: #fff;
      background: #006db7;
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(md) {
        height: vwMobile(42px);
        padding: 0 vwMobile(30px);
        border-radius: vwMobile(106px);
        font-size: vwMobile(16px);
      }
    }

    .tips {
      margin-top: vw(36px);
      font-size: vw(24px);
      line-height: 1.46;
      color: #939598;

      @include media-breakpoint-down(md) {
        margin-top: vwMobile(15px);
        font-size: vwMobile(12px);
        line-height: 1.66;
      }
    }

    &.isLast {
      .card-name {
        color: #3b393a;
        background: #ffc521;
      }
    }
  }

  .card-descriptions {
    margin-top: vwMobile(14px);
    font-weight: 500;
    font-size: vwMobile(12px);
    line-height: 1.42;
    color: #1779bb;

    @include media-breakpoint-up(md) {
      margin-top: vw(41px);
      font-size: vw(26px);
      line-height: 1.73;
    }
  }

  .box-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: vw(72px);

    @include media-breakpoint-down(md) {
      margin-top: vwMobile(50px);
    }

    .btn-apply {
      width: vw(278px);

      @include media-breakpoint-down(md) {
        width: vwMobile(146px);
      }
    }
  }

