$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-2 {
    position: relative;

    .bottle-1,
    .bottle-2,
    .bottle-3,
    .bottle-4,
    .bottle-5,
    .bottle-6,
    .bottle-7,
    .bottle-8,
    .bottle-9 {
      position: absolute;
      z-index: 1;
    }

    .bottle-1 {
      top: vwMobile(21px);
      left: vwMobile(21px);
      width: vwMobile(41px);

      @include media-breakpoint-up(md) {
        top: vw(103px);
        left: vw(353px);
        width: vw(27px);
      }
    }

    .bottle-2 {
      top: vwMobile(160px);
      right: vwMobile(23px);
      width: vwMobile(41px * 0.62);

      @include media-breakpoint-up(md) {
        top: vw(412px);
        left: vw(268px);
        width: vw(44px);
      }
    }

    .bottle-2,
    .bottle-6 {
      transform: rotate(-40.19deg);
    }

    .bottle-3,
    .bottle-7 {
      transform: rotate(-64deg);
    }

    .bottle-4,
    .bottle-8 {
      transform: rotate(-36deg);
    }

    .bottle-3 {
      top: vw(435px);
      right: vw(278px);
      width: vw(28px);
    }

    .bottle-4 {
      top: vw(945px);
      left: vw(471px);
      width: vw(24px);
    }

    .bottle-5 {
      top: vw(905px);
      right: vw(314px);
      width: vw(36px);
    }

    .bottle-6 {
      bottom: vw(545px);
      left: vw(268px);
      width: vw(44px);
    }

    .bottle-7 {
      bottom: vw(575px);
      right: vw(278px);
      width: vw(28px);
    }

    .bottle-8 {
      bottom: vw(70px);
      left: vw(471px);
      width: vw(24px);
    }

    .bottle-9 {
      bottom: vw(80px);
      right: vw(313px);
      width: vw(36px);
    }
  }

  .section-btns {
    padding: vwMobile(45px) vwMobile(44px) vwMobile(49px);

    @include media-breakpoint-up(md) {
      padding: vw(103px) 0 vw(163px);
    }

    .button-group {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
      }
    }

    .button {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: vwMobile(58px);
      border-radius: vwMobile(12px);
      font-size: vwMobile(24px);
      font-family: ImcreSoojin;
      color: white;
      background: $red;
      cursor: pointer;

      &.disabled {
        opacity: .4;
        cursor: default;
      }

      &:not(:last-child) {
        margin-bottom: vwMobile(17px);
      }

      @include media-breakpoint-up(md) {
        width: vw(385px);
        height: vw(100px);
        border-radius: vw(12px);
        font-size: vw(36px);

        &:not(:last-child) {
          margin-right: vw(20px);
          margin-bottom: 0;
        }
      }
    }
  }

  .section-video {
    $video-ratio: 560 / 315;

    width: vwMobile(345px);
    height: vwMobile(345px / $video-ratio);
    margin: 0 auto vwMobile(37px);

    @include media-breakpoint-up(md) {
      width: vw(948px);
      height: vw(948px / $video-ratio);
      margin: 0 auto vw(121px );
    }
  }

  .section-2 {
    padding: 0 vwMobile(15px) vwMobile(20px);
    text-align: center;
    background-image: url('./images/bg.png');
    background-position: bottom right;
    background-size: vwMobile(107px);
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      padding: 0 0 vw(86px);
      background-image: none;
    }

    .icon {
      width: vwMobile(42px);
      height: auto;
      margin-bottom: vwMobile(20px);

      @include media-breakpoint-up(md) {
        width: vw(86px);
        margin-bottom: vw(73px);
      }
    }

    .section-title {
      margin-bottom: vwMobile(48px);
      font-size: vwMobile(15px);
      font-family: ImcreSoojin;
      line-height: 1.73;
      color: $black;

      @include media-breakpoint-up(md) {
        margin-bottom: vw(84px);
        font-size: vw(36px);
        line-height: 1.77;
      }

      .strong {
        color: $red;
      }
    }

    .section-desc {
      display: flex;
      flex-direction: column;
      font-size: vwMobile(14px);
      line-height: 2.14;
      white-space: pre-line;
      letter-spacing: -0.05em;
      color: #6d6d6d;

      @include media-breakpoint-up(md) {
        flex-direction: column-reverse;
        font-size: vw(18px);
        line-height: 2.38;
      }

      .desc-main {
        padding: 0 vwMobile(6px);

        @include media-breakpoint-up(md) {
          padding: 0;
        }
      }

      .strong {
        font-family: AppleSDGothicNeoB;
      }

      .desc-important {
        margin-top: vwMobile(38px);
        font-size: vwMobile(14px);
        font-family: AppleSDGothicNeoB;
        line-height: 1.71;
        letter-spacing: -0.05em;
        color: $black;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-bottom: vw(61px);
          font-size: vw(24px);
          line-height: 1.5;
        }

        .strong {
          color: $red;
        }
      }
    }
  }
