$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.options {
  .item {
    display: flex;
    padding-left: vwMobile(30px);
    margin-bottom: vwMobile(25px);
    line-height: 1.5;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding-left: vw(79px);
      margin-bottom: vw(25px);
    }

    .item-radio {
      position: relative;
      flex-shrink: 0;
      width: vwMobile(16px);
      height: vwMobile(16px);
      margin-top: vwMobile(2px);
      margin-right: vwMobile(10px);
      border: 1px solid #eee;
      border-radius: 50%;
      transition: border-color .3s;

      @include media-breakpoint-up(md) {
        width: vw(16px);
        height: vw(16px);
        margin-top: vw(2px);
        margin-right: vw(14px);
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: vwMobile(8px);
        height: vwMobile(8px);
        border-radius: 50%;
        background-color: #eee;
        transform: translate(-50%, -50%);
        transition: background-color .3s;
        content: '';

        @include media-breakpoint-up(md) {
          width: vw(8px);
          height: vw(8px);
        }
      }
    }

    &.active {
      .item-radio {
        border-color: #b01614;

        &::before {
          background-color: #b01614;
        }
      }
    }
  }
}
