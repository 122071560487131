.box-search {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 67px auto 74px;
}

.box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px;

  .section-name {
    font-weight: bold;
    font-size: 32px;
    color: $primary-darker;
  }

  .more {
    font-size: 20px;
    text-decoration: none;
    color: $gray;
  }
}

.program {
  margin-bottom: 54px;
}
