$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../../vars.scss';

  .page-faq {
    padding: vwMobile(54px) vwMobile(17px) vwMobile(40px);

    @include media-breakpoint-up(md) {
      padding: vw(116px) 0 vw(146px);
      margin: 0 auto;
      background-color: #f2f2f2;
    }
  }

  .page-faq-container {
    @include media-breakpoint-up(md) {
      width: vw(1194px);
      margin: 0 auto;
    }
  }

  .section-title {
    margin-bottom: vwMobile(15px);
    font-weight: 400;
    font-size: vwMobile(27px);
    font-family: SB AggroOTF;
    line-height: 1.1851;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(66px);
      font-size: vw(60px);
    }
  }

  .faq-tabs {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      width: 100%;
      filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
    }

    :deep(.box-tab-items) {
      display: flex;
      justify-content: center;
      margin-bottom: vwMobile(32px);

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      .tab-item {
        flex: unset;

        &:not(:last-child) {
          margin-right: vwMobile(18px);

          @include media-breakpoint-up(md) {
            margin-right: 0;
          }
        }

        @include media-breakpoint-up(md) {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          height: vw(80px);
          margin-right: 0;
          border-top-right-radius: vw(16px);
          border-top-left-radius: vw(16px);
          overflow: hidden;
          font-weight: 400;
          font-size: vw(26px);
          font-family: SB AggroOTF;
          color: #fff;
          background-color: $primary-color;

          &:nth-child(1),
          &:nth-child(2) {
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
          }

          &:nth-child(3) {
            &.active {
              box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
            }
          }

          &.active {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    :deep(.app-tab-content) {
      @include media-breakpoint-up(md) {
        padding: vw(43px) vw(47px) vw(68px);
        border-bottom-right-radius: vw(16px);
        border-bottom-left-radius: vw(16px);
        overflow-y: auto;
        background-color: #fff;
      }
    }

    .faq-tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(100px);
      height: vwMobile(35px);
      border: 1px solid $primary-color;
      border-radius: vwMobile(18px);
      font-weight: 400;
      font-size: vwMobile(16px);
      font-family: SB AggroOTF;
      color: $primary-color;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        font-size: inherit;
        color: inherit;
        box-shadow: none;
      }

      .name {
        line-height: normal;
      }

      &.active {
        color: white;
        background-color: $primary-color;
        cursor: default;

        @include media-breakpoint-up(md) {
          color: inherit;
          background-color: inherit;
        }
      }
    }
  }

  .faq-collapse-trigger {
    display: flex;
    align-items: center;
    padding: vwMobile(11px) 0;
    margin-top: vwMobile(16px);
    border-bottom: 1px solid #d2d2d2;
    font-weight: 400;
    font-size: vwMobile(16px);
    font-family: SB AggroOTF;
    color: $black;

    @include media-breakpoint-up(md) {
      padding: vw(10px) 0;
      margin-top: vw(16px);
      font-size: vw(26px);
    }

    .indicator {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: vwMobile(30px);
      height: vwMobile(30px);
      margin-left: vwMobile(10px);

      img {
        width: vwMobile(11px);
        transition: transform .3s;

        @include media-breakpoint-up(md) {
          width: vw(20px);
        }
      }

      @include media-breakpoint-up(md) {
        width: vw(48px);
        height: vw(48px);
        margin-right: vw(20px);
        margin-left: vw(15px);
        border-radius: 50%;
        background: #f5f5f5;
      }
    }

    .collapse-title {
      flex: 1;
      white-space: pre-wrap;
    }

    .collapse-symbol {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      width: vwMobile(30px);
      height: vwMobile(30px);
      padding-right: vwMobile(2px);
      margin-right: vwMobile(15px);
      border-radius: 50%;
      font-size: vwMobile(16px);
      font-family: SB AggroOTF;
      color: #fff;
      background: #fdb515;

      @include media-breakpoint-up(md) {
        justify-content: center;
        width: vw(62px);
        height: vw(62px);
        padding-right: 0;
        margin-right: vw(33px);
        font-size: vw(24px);
        background-color: $primary-color;
      }
    }

    &.active {
      .indicator {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .faq-content {
    padding-top: vwMobile(9px);
    padding-bottom: vwMobile(20px);
    font-size: vwMobile(13px);
    line-height: 1.2;
    color: #515151;

    @include media-breakpoint-up(md) {
      padding-top: vw(18px);
      padding-bottom: vw(40px);
      font-size: vw(24px);
      line-height: 1.6666;
      color: #030303;
    }

   :deep(.text-important) {
      color: $primary-color;
    }

    :deep(.text-bold) {
      font-weight: bold;
    }
  }
