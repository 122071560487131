$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  @import '../vars.scss';

  .about-text-wrapper {
    position: relative;
    padding: vw(155px) vw(20px) vw(118px);

    @include media-breakpoint-down(md) {
      padding: vwMobile(49px) 0 vwMobile(88px);
    }

    .title {
      margin-bottom: vw(29px);
      font-size: vw(58px);
      font-family: SCDream8;
      line-height: 1.18;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        margin-bottom: vwMobile(13px);
        font-size: vwMobile(24px);
      }
    }

    .sub-text {
      width: vw(896px);
      margin-bottom: vw(75px);

      @include media-breakpoint-down(md) {
        width: vwMobile(285px);
        margin-bottom: vwMobile(40px);
      }
    }

    .cover {
      width: vw(78px);
      margin: 0 vw(9px) vw(75px);

      @include media-breakpoint-down(md) {
        width: vwMobile(34px);
        margin: 0 vwMobile(7px) vwMobile(40px);
      }
    }

    .ripple-bg {
      position: absolute;
      top: vw(370px);
      left: vw(109px);
      width: vw(310px);
    }

    .ripple-bg-2 {
      top: unset;
      bottom: vw(100px);
      left: unset;
      right: vw(107px);
    }

    .text-wrapper {
      font-size: vw(24px);
      font-family: 'Noto Sans KR';
      text-align: center;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        font-size: vwMobile(13px);
      }

      strong {
        font-weight: 700;
      }

      .blue {
        color: #006db7;
      }
    }
  }

  .company-wrapper {
    position: relative;
    padding: vw(78px) 0 vw(88px);
    overflow: hidden;
    text-align: center;
    background: #ffc521;

    @include media-breakpoint-down(md) {
      padding: vwMobile(45px) 0 vwMobile(36px);
    }

    .title {
      margin-bottom: vw(90px);
      font-size: vw(58px);
      font-family: SCDream8;
      color: #3b393a;


      @include media-breakpoint-down(md) {
        margin-bottom: vwMobile(45px);
        font-size: vwMobile(24px);
      }
    }

    .companies {
      position: relative;
      z-index: 1;

      .item {
        width: vw(126px);
        height: vw(126px);
        margin: vw(18px);

        @include media-breakpoint-down(md) {
          width: vwMobile(68px);
          height: vwMobile(68px);
          margin: vwMobile(3px);
        }
      }
    }

    .company-bg {
      position: absolute;
      bottom: vw(68px);
      left: vw(74px);
      width: vw(263px);
    }
  }

  .timeline-wrapper {
    padding: vw(154px) vw(20px) vw(187px);
    text-align: center;

    .title {
      margin-bottom: vw(130px);
      font-size: vw(58px);
      font-family: SCDream8;
      color: #3b393a;

      @include media-breakpoint-down(md) {
        margin-top: vwMobile(45px);
        margin-bottom: vwMobile(45px);
        font-size: vwMobile(24px);
      }
    }

    .timeline-content-wrapper {
      position: relative;
      width: vw(1358px);
      margin: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .timeline-image {
        position: relative;
        width: 100%;
      }
    }

    .ripple-down,
    .ripple-up {
      position: absolute;
      width: vw(310px);
    }

    .ripple-up {
      top: vw(403px);
      right: 0;
      transform: translateX(50%);
    }

    .ripple-down {
      bottom: vw(597px);
      left: 0;
      transform: translateX(-50%);
    }
  }
