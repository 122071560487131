$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .member-profile-wrapper {
    .group {
      margin-bottom: 40px;

      &.not-bottom {
        margin-bottom: 0;
      }
    }

    .title {
      margin-bottom: 45px;
      font-weight: bold;
      font-size: 24px;
      color: $primary-darker;
    }

    .box-list-group {
      display: flex;
      flex-wrap: wrap;

      .list {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 50%;

        .item {
          width: 100%;
        }

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      width: 50%;
      margin-bottom: 21px;
      font-size: 18px;
      line-height: 28px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .label {
        width: 200px;
        color: #999;
      }

      .value {
        flex: 1;
        padding-right: 20px;
        word-wrap: break-word;
        word-break: break-all;
        color: #333;
      }
    }

    .connect-item {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 29px 0 19px;
      margin-bottom: 20px;
      border: 2px solid $primary;
      border-radius: 30px;
      font-size: 18px;
      color: $primary;

      &:not(:last-child) {
        margin-right: 40px;
      }

      @include media-breakpoint-down(md) {
        width: 205px;
      }

      &.connected {
        border: 2px solid #ccc;
        color: #ccc;
        background-color: #f2f2f2;
      }

      .icon {
        width: 43px;
        height: 43px;
        margin-right: 14px;
        object-fit: cover;
      }
    }
  }
