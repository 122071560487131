@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: AppleSDGothicNeo;
  font-display: auto;
  src: local(AppleSDGothicNeo), url('~@/templates/assets/fonts/AppleSDGothicNeoR.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: AppleSDGothicNeoM;
  font-display: auto;
  src: local(AppleSDGothicNeoM), url('~@/templates/assets/fonts/AppleSDGothicNeoM.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: AppleSDGothicNeoB;
  font-display: auto;
  src: local(AppleSDGothicNeoB), url('~@/templates/assets/fonts/AppleSDGothicNeoB.ttf') format('truetype');
}
