@font-face {
  font-weight: 400;
  font-family: 'SB AggroOTF';
  src: local('SB AggroOTF'), url('../campaign-cocacola-season-4/fonts/SB_thin.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'SB AggroOTF';
  src: local('SB AggroOTF'), url('../campaign-cocacola-season-4/fonts/SB_bold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'SB AggroOTF';
  src: local('SB AggroOTF'), url('../campaign-cocacola-season-4/fonts/SB_heavey.ttf') format('truetype');
}
