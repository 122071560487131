$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .program-participation-detail {
    .header {
      margin-bottom: 70px;

      .banner {
        width: 120px;
        height: 120px;
        margin-right: 40px;
        border-radius: 20px;
        object-fit: contain;
      }

      .title {
        font-weight: bold;
        font-size: 26px;
        color: #000;
      }
    }

    .group {
      padding-bottom: 56px;
      margin-bottom: 40px;

      .title {
        margin-bottom: 45px;
        font-weight: bold;
        font-size: 24px;
        color: $primary-darker;
      }
    }

    .detail-table {
      border: 1px solid #ddd;

      .item {
        border-bottom: 1px solid #ddd;

        &.active {
          background-color: #f2f2f2;

          .item-content {
            border-bottom: 1px solid #ddd;
            background: #e0f1ea;
          }

          .arrow {
            transform: rotate(180deg);
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .table-header {
        color: #999;
        background-color: #f2f2f2;
      }

      .item-content {
        display: flex;

        .td {
          display: flex;
          justify-content: space-between;
          width: 16%;
          padding: 18px 10px 18px 0;
          font-size: 18px;
          word-wrap: break-word;
          word-break: break-all;

          .arrow {
            width: 13px;
            margin-top: 8px;
            transform-origin: center;
            transition: all 0.3s;
          }

          &:first-child {
            width: 20%;
            padding-left: 20px;
          }

          &:last-child {
            width: 16%;
            padding-right: 20px;
          }
        }
      }

      .progress-box {
        display: flex;
        width: 100%;
        padding: 24px 30px 28px;
        overflow-x: auto;
      }
    }

    .mobile-detail {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }

      .item {
        padding-top: 19px;
        margin-bottom: 12px;
        border: 1px solid #ddd;
        border-radius: 10px;
      }

      .item-title {
        padding: 0 16px;
        margin-bottom: 23px;
        font-weight: bold;
        font-size: 24px;
        color: #000;
      }

      .item-content {
        display: flex;
        padding: 0 16px;
        margin-bottom: 15px;

        .label {
          width: 200px;
          color: #999;
        }

        .value {
          flex: 1;
          text-align: right;
          color: #333;
        }
      }

      .collapse-wrapper {
        background-color: #f8f8f8;

        .tirgger-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;

          &.active {
            .arrow {
              transform: rotate(180deg);
            }
          }

          .arrow {
            width: 14px;
            transform-origin: center;
            transition: all 0.3s;
          }
        }

        .progress-box {
          display: flex;
          padding: 30px;
          overflow-x: scroll;

          &::after {
            display: block;
            width: 30px;
            height: 30px;
            content: '';
          }
        }
      }
    }
  }
