$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .section-1 {
    padding: 47px 0 77px;
    background: #fff;
  }

  .section-breadcurmb {
    margin-bottom: 47px;
  }

  .box-indicator {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 36px;
    overflow: hidden;
    white-space: nowrap;

    .box-imgs {
      // 需要position: relative让js算offsetleft
      position: relative;
      display: flex;
      margin: 0 30px;
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
        cursor: pointer;
        object-fit: contain;

        &:not(:first-child) {
          margin-left: 24px;
        }

        &.active {
          border: 1px solid $primary;
          cursor: default;
        }
      }
    }

    .btn-ctrl {
      flex-shrink: 0;
      width: 40px;
      height: 40px;

      .active-img {
        cursor: pointer;
      }

      &.active {
        img {
          display: none;

          &.active-img {
            display: block;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;

        &.active-img {
          display: none;
        }
      }
    }
  }

  .product-name,
  .section-title {
    font-weight: bold;
    color: #000;
  }

  .product-name {
    margin-bottom: 19px;
    font-size: 32px;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }
  }


  .section-title {
    margin-bottom: 36px;
    font-size: 22px;
    line-height: 1.4;
  }

  .box-price {
    margin-bottom: 43px;

    .price {
      font-weight: bold;
      font-size: 32px;
      color: $primary-darker;
    }

    .price-line-through {
      margin-left: 11px;
      font-weight: bold;
      text-decoration: line-through;
      color: #999;
    }
  }

  .sku-select {
    margin-bottom: 56px;

    select {
      width: auto;
      min-width: 430px;
      height: 48px;
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid $primary;
      border-radius: 24px;
    }
  }

  .box-purchase-option {
    margin-bottom: 68px;
  }

  .box-action {
    display: flex;
    margin-bottom: 46px;

    .btn-add-cart {
      flex: 1;
      margin-left: 43px;
    }
  }

  .box-description {
    p {
      margin: 0;
      font-size: 18px;
      line-height: 1.67;
      white-space: pre-line;
    }
  }

