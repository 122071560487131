$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.page-2 {
  scroll-margin-top: var(--tc-header-height);
  position: relative;
  padding: vwMobile(22.6px) 0 vwMobile(37px);
  background-color: $secondary-bg-color;

  @include media-breakpoint-up(md) {
    padding: vw(83px) 0 vw(97px);
  }

  .box {
    .title {
      margin: 0;
      font-weight: 900;
      font-size: vwMobile(30px);
      line-height: 1.4333;

      @include media-breakpoint-up(md) {
        font-size: vw(48px);
        line-height: 1.4479;
      }
    }

    strong {
      font-weight: 700;
    }
  }

  .box-1 {
    text-align: center;

    .title {
      margin-bottom: vwMobile(5px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(5px);
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: vwMobile(16px);
      line-height: 1.4375;

      @include media-breakpoint-up(md) {
        font-size: vw(24px);
        line-height: 1.4479;
      }

      &:not(:last-of-type) {
        margin-bottom: vwMobile(27.38px);

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .steps {
      display: flex;
      padding-right: vwMobile(89px);
      padding-left: vwMobile(89px);
      margin-top: vwMobile(27.38px);
      overflow-x: auto;

      @include media-breakpoint-up(md) {
        justify-content: center;
        padding-right: 0;
        padding-left: 0;
        margin-top: vw(55px);
      }

      .step {
        flex-shrink: 0;
        width: vwMobile(203px);

        @include media-breakpoint-up(md) {
          width: vw(251px);
        }

        &:not(:last-child) {
          margin-right: vwMobile(15px);

          @include media-breakpoint-up(md) {
            margin-right: vw(15px);
          }
        }
      }
    }
  }

  .box-2 {
    margin-top: vwMobile(56px);
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: vw(63px);
    }

    .title {
      margin-bottom: vwMobile(5px);

      @include media-breakpoint-up(md) {
        margin-bottom: vw(10px);
      }
    }

    p {
      margin: 0;
      font-size: vwMobile(16px);
      line-height: 1.4481;

      @include media-breakpoint-up(md) {
        font-size: vw(20px);
      }
    }

    .image-message {
      width: vwMobile(375px);
      margin-top: vwMobile(-6px);

      @include media-breakpoint-up(md) {
        width: vw(1238.77px);
        margin-top: vw(-115px);
        margin-left: vw(-270px);
      }
    }
  }

  .box-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: vwMobile(79px);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-top: vw(39px);
    }

    .item {
      text-align: center;

      .title {
        font-weight: 900;
        font-size: vwMobile(25px);
        line-height: 1.44;
        letter-spacing: -0.05em;
        color: #fff;

        @include media-breakpoint-up(md) {
          margin-bottom: vw(23px);
          font-size: vw(36px);
        }

        strong {
          font-weight: inherit;
          color: #fff924;
        }
      }

      img {
        margin-bottom: vwMobile(15px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(23px);
        }
      }

      p {
        margin: 0;
        font-size: vwMobile(13px);
        line-height: 1.4461;
        color: #fff;

        @include media-breakpoint-up(md) {
          font-size: vw(20px);
          line-height: 1.45;
        }
      }

      &.item-1 {
        @include media-breakpoint-up(md) {
          margin-right: vw(7px);
        }

        @include media-breakpoint-down(md) {
          .title {
            margin-bottom: vwMobile(29px);
          }
        }

        img {
          width: vwMobile(356px);

          @include media-breakpoint-up(md) {
            width: vw(582px);
          }
        }
      }

      &.item-2 {
        align-self: flex-start;
        width: 100%;
        margin-top: vwMobile(36px);
        text-align: left;

        @include media-breakpoint-up(md) {
          width: unset;
          margin-top: 0;
          text-align: center;
        }

        img {
          width: vwMobile(357px);
          margin-left: vwMobile(7px);

          @include media-breakpoint-up(md) {
            width: vw(605px);
            margin-left: 0;
          }
        }

        .title {
          text-align: center;

          @include media-breakpoint-up(md) {
            margin-bottom: vw(26px);
          }
        }
      }
    }
  }

  .box-4 {
    margin-top: vwMobile(62px);
    text-align: center;

    .title {
      margin-bottom: vwMobile(5px);

      strong {
        color: #fff;
      }
    }

    .subtitle {
      margin-bottom: vwMobile(22px);
      font-size: vwMobile(16px);
      line-height: 1.4481;
    }

    .message {
      display: inline-block;
      box-sizing: border-box;
      width: vwMobile(339px);
      padding-top: vwMobile(26px);
      padding-bottom: vwMobile(25px);
      border: 2px solid #000000;
      border-radius: vwMobile(18px);
      font-size: vwMobile(14px);
      line-height: 1.4285;
      text-align: center;
      background-color: #fff;

      p {
        margin: 0;
      }
    }

    .box-img {
      padding-left: vwMobile(7px);
      margin-top: vwMobile(-25px);
      text-align: left;

      .image {
        width: vwMobile(273.5px);
      }
    }

    .desc {
      margin-top: vwMobile(31px);
      font-size: vwMobile(12px);
      line-height: 1.4166;

      strong {
        font-weight: 700;
      }

      p {
        margin: 0;
      }
    }
  }

  .box-4-pc {
    position: relative;
    box-sizing: border-box;
    width: vw(1194px);
    padding-top: vw(72px);
    padding-bottom: vw(66px);
    padding-left: vw(43px);
    margin: vw(68px) auto 0;
    border: 3px solid #000000;
    border-radius: vw(12px);
    background-color: #fff;

    .image {
      position: absolute;
      z-index: 1;
      top: vw(72px);
      right: vw(-84px);
      width: vw(540px);
    }

    .subtitle {
      font-size: vw(26px);
      line-height: 1.4615;
    }

    .title {
      margin-bottom: vw(21px);
      font-size: vw(40px);
    }

    .desc {
      p {
        margin: 0;
        font-size: vw(22px);
        line-height: 1.4545;

        &.margin-bottom {
          margin-bottom: vw(32px);
        }
      }
    }
  }

  .box-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: vwMobile(37px);

    @include media-breakpoint-up(md) {
      margin-top: vw(72px);
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: vwMobile(299px);
      height: vwMobile(67px);
      border: 3px solid #000000;
      border-radius: vwMobile(12px);
      font-weight: 700;
      font-size: vwMobile(20px);
      line-height: 1.45;
      background: #fff924;
      box-shadow: vwMobile(2px) vwMobile(2px) vwMobile(2px) rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: vw(471px);
        height: vw(90px);
        border-radius: vw(12px);
        font-size: vw(36px);
        box-shadow: vw(2px) vw(2px) vw(2px) rgba(0, 0, 0, 0.25);
      }

      &.button-1 {
        margin-bottom: vwMobile(10px);

        @include media-breakpoint-up(md) {
          margin-bottom: vw(15px);
        }
      }

      &.button-2 {
        background-color: #fff;
      }
    }
  }
}
