$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

@import '../../vars.scss';

.page-5 {
  position: relative;
  padding-top: vwMobile(34px);
  padding-bottom: vwMobile(67px);
  text-align: center;
  color: #fff;
  background: #fdb515;
  background-image: url('../../kraft.png');
  background-repeat: repeat;

  @include media-breakpoint-up(md) {
    padding-top: vw(80px);
    padding-bottom: vw(93px);
  }

  .img-recycle {
    position: absolute;
    z-index: 1;
    top: vw(50px);
    left: vw(27px);
    width: vw(504px);
  }
}

.title {
  margin-bottom: vwMobile(41px);
  font-weight: 400;
  font-size: vwMobile(27px);
  font-family: 'SB AggroOTF';
  line-height: 1.1851;

  @include media-breakpoint-up(md) {
    margin-bottom: vw(107px);
    font-size: vw(60px);
  }
}

.box-desc {
  margin-top: vwMobile(43px);

  @include media-breakpoint-up(md) {
    margin-top: vw(50px);
  }

  .desc-title {
    margin-bottom: vwMobile(10px);
    font-weight: 400;
    font-size: vwMobile(16px);
    font-family: 'SB AggroOTF';
    line-height: 1.1875;

    @include media-breakpoint-up(md) {
      margin-bottom: vw(5px);
      font-size: vw(40px);
    }
  }

  .desc-content {
    font-size: vwMobile(13px);
    line-height: 1.2;
    white-space: pre-wrap;

    @include media-breakpoint-up(md) {
      font-weight: 300;
      font-size: vw(22px);
      line-height: 1.45;
    }
  }
}

.btn-submit-container {
  display: inline-flex;
  margin-top: vwMobile(24px);

  @include media-breakpoint-up(md) {
    margin-top: vw(75px);
  }
}

.btn-submit {
  width: vwMobile(331px);
  height: vwMobile(46px);
  border-color: $primary-color;
  border-radius: vwMobile(56px);
  font-weight: 700;
  font-size: vwMobile(22px);
  color: #fff;
  background: $primary-color;

  @include media-breakpoint-up(md) {
    width: vw(220px);
    height: vw(61px);
    border-radius: vw(32px);
    font-size: vw(24px);
  }
}

.rounds {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .round {
    width: vwMobile(377px);
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: vw(378px);
    }

    &:not(:last-child) {
      margin-bottom: vwMobile(30px);

      @include media-breakpoint-up(md) {
        margin-right: vw(30px);
        margin-bottom: 0;
      }
    }

    .round-title {
      margin-bottom: vwMobile(7px);
      font-weight: 400;
      font-size: vwMobile(27px);
      font-family: 'SB AggroOTF';
      line-height: 1.1851;

      @include media-breakpoint-up(md) {
        margin-bottom: vw(11px);
        font-size: vw(40px);
      }
    }

    .round-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: vwMobile(222px);
      padding: vwMobile(20px) vwMobile(28px) vwMobile(24px);
      border-radius: vwMobile(12px);
      overflow: hidden;
      background-color: #fff;

      @include media-breakpoint-up(md) {
        height: vw(472px);
        padding: vw(40px) vw(50px) vw(70px);
        border-radius: vw(30px);
      }

      &.expired {
        position: relative;

        &:before {
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(3, 3, 3, 0.8);
          content: '';
        }

        &:after {
          position: absolute;
          z-index: 4;
          top: 50%;
          left: 0;
          width: 100%;
          font-size: vwMobile(27px);
          font-family: 'SB AggroOTF';
          line-height: 1.2;
          text-align: center;
          color: #fff;
          transform: translateY(-50%);
          content: '종료';

          @include media-breakpoint-up(md) {
            font-size: vw(60px);
          }
        }
      }

      .round-content-row {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          flex-direction: column;
        }

        .label {
          min-width: vwMobile(101px);
          margin-right: vwMobile(23px);
          font-weight: 400;
          font-size: vwMobile(16px);
          font-family: 'SB AggroOTF';
          line-height: 1.1875;
          text-align: left;
          color: $primary-color;

          @include media-breakpoint-up(md) {
            min-width: unset;
            margin-right: 0;
            font-size: vw(26px);
          }
        }

        .value {
          margin-top: vwMobile(-6px);
          font-size: vwMobile(16px);
          line-height: 1.4375;
          color: #030303;

          @include media-breakpoint-up(md) {
            margin-top: vw(5px);
            font-size: vw(22px);
          }
        }
      }
    }
  }
}
