$font-color-primary: #000;
$primary-darker: #055f30;
$primary: #1b8158;
$gray: #999999;
$gray-light: #dddddd;
$green: #60bf44;
$red: #ff3348;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}

// 之前的转rem是按照之前的pc设计转的，现在的设计是移动端优先，之前的转rem会看起来很小，所以要放大点
@function ocZoom($target) {
  @return $target * 1.5;
}

  .shopping-cart-table {
    margin-top: 52px;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }

    .header {
      display: flex;
      padding-bottom: 29px;
      border-bottom: 1px solid #ddd;
      font-size: 18px;
      color: #999;

      .product {
        padding-left: 85px;
      }
    }

    .product {
      display: flex;
      align-items: center;
      width: 60%;

      @include media-breakpoint-down(md) {
        width: 100%;

        .check-box {
          margin-top: 51px;
        }
      }

      .banner {
        width: 120px;
        height: 120px;
        margin-right: 40px;
        margin-left: 34px;

        @include media-breakpoint-down(md) {
          margin-right: 20px;
          margin-left: 14px;
        }
      }

      .info {
        flex: 1;
      }

      .offline {
        color: $green;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #000;
      }

      .desc {
        margin-top: 43px;
        font-size: 16px;
        line-height: 30px;
        color: #999;

        &.type {
          margin-top: 6px;
        }

        @include media-breakpoint-down(md) {
          margin-top: 0 !important;
        }
      }

      .mobile-price-quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobile-price {
          font-weight: bold;
          font-size: 22px;
          color: $primary;
        }

        :deep(.stepper) {
          width: 120px;
          height: 40px;
        }
      }
    }

    .other-box {
      display: flex;
      flex: 1;

      .price {
        width: 30%;
      }

      .quantity {
        width: 40%;

        :deep(.stepper) {
          width: 160px;
          height: 45px;
        }
      }

      .total {
        width: 30%;
      }
    }

    .product-list {
      .item {
        position: relative;
        padding: 29px 0 30px;
        border-bottom: 1px solid #ddd;
      }

      .price,
      .total {
        font-weight: bold;
        font-size: 22px;
        color: $primary;
      }

      .product,
      .price,
      .quantity,
      .total {
        display: flex;

        @include media-breakpoint-up(md) {
          align-items: center;
        }
      }
    }
  }

  .total-box {
    font-size: 18px;
    color: #000;

    .total-money {
      margin-left: 39px;
      font-weight: bold;
      font-size: 22px;
      color: $primary;

      @include media-breakpoint-down(md) {
        margin-left: 12px;
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 29px;

    @include media-breakpoint-up(md) {
      align-items: center;
    }

    .code-box {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .btn {
        height: 48px;
      }
    }

    .code-input {
      width: 277px;
      height: 60px;
      margin-right: 31px;
      border: 1px solid #ddd;
      border-radius: 3px;
    }
  }

  .oprtate-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 63px;

    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-top: 20px;
    }

    .select-total-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .select-all {
      display: flex;
      align-items: center;
      font-size: 18px;

      .checkall {
        margin-right: 27px;
      }
    }

    .btn {
      width: 300px;
      height: 60px;

      @include media-breakpoint-down(md) {
        width: 400px;
        margin-top: 20px;
      }
    }
  }
