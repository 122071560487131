@import '~@/templates/fonts-apple-sd-gothic-neo.scss';

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: AndongKaturiOTF;
  font-display: auto;
  src: local(AndongKaturiOTF), url('./fonts/AndongKaturiOTF.otf') format('opentype');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: HaenamFont;
  font-display: auto;
  src: local(HaenamFont), url('./fonts/Haenam.ttf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: CircularXXBold;
  font-display: auto;
  src: local(CircularXXBold), url('./fonts/CircularXXBold.otf') format('truetype');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: CircularXXRegular;
  font-display: auto;
  src: local(CircularXXRegular), url('./fonts/CircularXXRegular.otf') format('truetype');
}
